import { tss } from "tss-react/mui";

export const useStyles = tss
  .withParams<{ navigationWidth: string }>()
  .create(({ theme, navigationWidth }) => ({
    verticalLayoutContainer: {
      display: "flex",
      flex: "1 1 auto",
      flexDirection: "column",
      padding: theme.spacing(2),
      paddingBottom: 0,
      width: "100%",

      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingBottom: 0,
      },

      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(6),
        paddingBottom: 0,
      },
    },

    verticalLayoutRoot: {
      display: "flex",
      flex: "1 1 auto",
      maxWidth: "100%",
      minHeight: "calc(100vh - 64px)", // 64 is the header height. Check in Header Component.

      [theme.breakpoints.up("md")]: {
        minHeight: "100vh",
        paddingLeft: navigationWidth,
      },
    },

    wrapper: {
      backgroundImage: theme.palette.gradients.light,
      minHeight: "100vh",
    },
  }));
