import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  Box,
  BoxProps,
  CircularProgress,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { ContractCard } from "src/pages/Customer.Contracts/Customer.ContractCard/Customer.ContractCard";
import { paths } from "src/paths";
import { Contract } from "src/types/contract";
import { useContractListForCustomerQuery } from "src/services/serverState/contract/useContractListForCustomerQuery";
import CustomerProfileSelection from "src/components/CustomerProfileSelection/CustomerProfileSelection.tsx";
import LayoutContentHeader from "src/components/LayoutContentHeader/LayoutContentHeader";
import { Seo } from "src/components/Seo";

import { useUser } from "../../services/contexts/userContext.tsx";

interface CustomerContractsPageProps extends BoxProps {}

export const CustomerContractsPage: FC<CustomerContractsPageProps> = ({
  ...props
}) => {
  const { userState, userGetters } = useUser();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const {
    data: contracts,
    isLoading,
    isError,
  } = useContractListForCustomerQuery({
    customerNumber: userState.selectedCustomerProfileNumber,
  });

  const handleContractClick = (contractNumber: string) => {
    navigate(`${paths.contracts}/${contractNumber}`);
  };

  return (
    <>
      <Seo title={t("contracts.navigationTitleCustomers")} />
      <Box {...props}>
        <LayoutContentHeader
          headerTitle={t("contracts.navigationTitleCustomers")}
          headerSubTitle={t("contracts.contract", {
            count: contracts ? contracts.length : 0,
          })}
          headerWidget={
            userGetters.customerProfilesCount &&
            userGetters.customerProfilesCount > 1 && (
              <CustomerProfileSelection sx={{ marginBottom: md ? 0 : 3 }} />
            )
          }
        />

        {isLoading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <CircularProgress style={{ color: theme.palette.primary.main }} />
          </Box>
        )}

        {isError && (
          <Box display="flex" justifyContent="center">
            <Typography color="error">
              {t("common.somethingWentWrong")}
            </Typography>
          </Box>
        )}

        {contracts &&
          !isError &&
          contracts.map((contract: Contract) => (
            <ContractCard
              key={contract.contractNumber}
              contract={contract}
              sx={{ mb: 1 }}
              onClick={() => handleContractClick(contract.contractNumber)}
            />
          ))}
      </Box>
    </>
  );
};

export default CustomerContractsPage;
