import { type FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Theme, useMediaQuery } from "@mui/material";

import { paths } from "src/paths";
import { BookingType, ContractStatus } from "src/types/contract";
import { formatDistributorLabel } from "src/utils/formatTexts";
import { useUser } from "src/services/contexts/userContext";
import { useCommodityListForCustomerQuery } from "src/services/serverState/commodity/useCommodityListForCustomerQuery";
import { useContractListForCustomerQuery } from "src/services/serverState/contract/useContractListForCustomerQuery";
import { useInfiniteContractsBookingListForCustomerQuery } from "src/services/serverState/contractBooking/useContractsBookingListForCustomerQuery";
import { CommodityCard } from "src/components/CommodityCard/CommodityCard";
import { ContactCard } from "src/components/ContactCard/ContactCard";
import ContractBookingList from "src/components/ContractBookingList/ContractBookingList";
import ContractSummary from "src/components/ContractSummary/ContractSummary";
import CustomerProfileSelection from "src/components/CustomerProfileSelection/CustomerProfileSelection";
import LayoutContentHeader from "src/components/LayoutContentHeader/LayoutContentHeader";
import { Seo } from "src/components/Seo";
import WidgetWrapper from "src/components/WidgetWrapper/WidgetWrapper";

interface CustomerDashboardPageProps {}

export const CustomerDashboardPage: FC<CustomerDashboardPageProps> = ({
  ...props
}) => {
  const { userState, userGetters } = useUser();
  const { t } = useTranslation();
  const [filterContractBookings, setFilterContractBookings] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");

  const md = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const distributorContact = userGetters.customerDistributorContact;

  // Get Contracts
  const { data: contracts, isLoading: contractsAreLoading } =
    useContractListForCustomerQuery({
      customerNumber: userState.selectedCustomerProfileNumber,
    });

  // Get all currencies from the contracts.
  const currenciesFromContracts = [
    ...new Set(
      contracts
        ?.filter((contract) => contract.status !== ContractStatus.Ended)
        .map((contract) => contract.currency),
    ),
  ].sort();

  // Get contract bookings data from all contracts (only the first fifteen).
  const { data: contractBookings, isLoading: isContractBookingLoading } =
    useInfiniteContractsBookingListForCustomerQuery({
      customerNumber: userState.selectedCustomerProfileNumber,
      filterContractBookingsBy: filterContractBookings,
    });

  const contractListBookingPages = contractBookings?.pages;

  // Get Commodity data
  const { data: commodities, isLoading: isCommodityListLoading } =
    useCommodityListForCustomerQuery({
      customerNumber: userState.selectedCustomerProfileNumber,
    });

  const filteredContracts = useMemo(() => {
    if (contracts) {
      return contracts.filter(
        (contract) =>
          contract.currency ===
            (selectedCurrency === ""
              ? currenciesFromContracts[0]
              : selectedCurrency) && contract.status !== ContractStatus.Ended,
      );
    }
  }, [selectedCurrency, contracts]);

  const filteredCommodities = useMemo(() => {
    if (commodities) {
      return commodities.filter(
        (commodity) =>
          commodity.currency ===
          (selectedCurrency === ""
            ? currenciesFromContracts[0]
            : selectedCurrency),
      );
    }
  }, [selectedCurrency, commodities, contracts]);

  return (
    <>
      <Seo title={t("common.dashboardTitle")} />
      <LayoutContentHeader
        headerTitle={
          userState.user?.firstName
            ? t("dashboard.welcomeHeaderWithName", {
                firstName: userState.user?.firstName,
                lastName: userState.user?.lastName,
              })
            : t("dashboard.welcomeHeader")
        }
        headerSubTitleSmall={new Intl.DateTimeFormat("de-DE").format(
          new Date(),
        )}
        headerWidget={
          userGetters.customerProfilesCount &&
          userGetters.customerProfilesCount > 1 && (
            <CustomerProfileSelection sx={{ marginBottom: md ? 0 : 3 }} />
          )
        }
      />

      <Grid container {...props} spacing={2}>
        <Grid item xs={12} md={7} lg={8}>
          <WidgetWrapper
            onActionSwitch={(tabValue) => {
              setSelectedCurrency(tabValue);
            }}
            actionTabsChoices={
              // If a customer has only one contract the currency tab should not be displayed.
              currenciesFromContracts.length > 1
                ? currenciesFromContracts.map((currency) => ({
                    label: currency,
                    value: currency,
                  }))
                : undefined
            }
            headerText={t("dashboard.widgets.contractSummary.label")}
            footerText={t("navigation.contracts")}
            redirectPath={paths.contracts}
            sx={{ mb: 4 }}
          >
            <ContractSummary
              sx={{ p: 2 }}
              contracts={filteredContracts}
              commodities={filteredCommodities}
              isLoading={contractsAreLoading}
            />
          </WidgetWrapper>

          <WidgetWrapper
            headerText={t("contracts.commodities")}
            footerText={t("navigation.contracts")}
            redirectPath={paths.contracts}
            sx={{ mb: 4 }}
          >
            <CommodityCard
              isLoading={isCommodityListLoading}
              commodities={commodities}
              showPrices={false}
            />
          </WidgetWrapper>

          <WidgetWrapper
            onActionSwitch={(tabValue) => setFilterContractBookings(tabValue)}
            actionTabsChoices={[
              { label: t("contracts.bookings.filter.all"), value: "" },
              {
                label: t("contracts.bookings.filter.deposits"),
                value: BookingType.EZ,
              },
              {
                label: t("contracts.bookings.filter.purchases"),
                value: BookingType.RK,
              },
              {
                label: t("contracts.bookings.filter.storageFees"),
                value: BookingType.LG,
              },
            ]}
            headerText={t("contracts.contractDetails.turnoverLabel")}
            footerText={t("navigation.contracts")}
            redirectPath={paths.contracts}
            sx={{ mb: 4 }}
          >
            <ContractBookingList
              contractBookingPages={contractListBookingPages}
              contracts={contracts || []}
              isLoading={isContractBookingLoading}
              renderAllContracts
            />
          </WidgetWrapper>
        </Grid>

        <Grid item xs={12} md={5} lg={4}>
          <ContactCard
            email={distributorContact?.email}
            phone={distributorContact?.mobilePhone || distributorContact?.phone}
            title={t("dashboard.widgets.distributorContact.contactPerson")}
            name={formatDistributorLabel(
              distributorContact?.distributorLabel || "",
            )}
          />
        </Grid>

        <Grid item xs={12} md={4} />
      </Grid>
    </>
  );
};

export default CustomerDashboardPage;
