import { keyframes } from "tss-react";
import { tss } from "tss-react/mui";

export const useStyles = tss
  .withParams<{ rowIsClickable: boolean }>()
  .create(({ theme, rowIsClickable }) => ({
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },

    overflow: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

    skeleton: {
      animation: `${keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100;
    }
    `} 0.5s`,
    },

    tableRow: {
      "&:hover": {
        backgroundColor: rowIsClickable
          ? theme.palette.secondary.light
          : "none",
        cursor: rowIsClickable ? "pointer" : "auto",
      },
    },

    trendArrow: {
      backgroundColor: theme.palette.neutral[50],
      borderRadius: 4,
      display: "flex",
      justifyContent: "center",
      marginRight: 16,
      padding: 8,
    },

    trendArrowGray: {
      backgroundColor: theme.palette.neutral[50],
      color: theme.palette.neutral[300],
    },

    trendArrowGreen: {
      backgroundColor: theme.palette.green[50],
      color: theme.palette.success.main,
    },

    trendArrowRed: {
      backgroundColor: theme.palette.red[50],
      color: theme.palette.error.main,
    },
  }));
