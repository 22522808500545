import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.quartaer.main,
    fontSize: "1rem",
    fontWeight: "bold",
    height: theme.spacing(6),
    width: theme.spacing(6),

    [theme.breakpoints.up("lg")]: {
      height: theme.spacing(8),
      width: theme.spacing(8),
    },
  },
  bottom: {
    alignItems: "center",
    display: "flex",
    marginTop: theme.spacing(2),
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: "1.4rem",
    marginRight: theme.spacing(1),
  },
  name: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4rem",
    },
  },
  root: {},
  top: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));
