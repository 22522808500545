export const BACKEND_BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL;
export const API_URL = `${BACKEND_BASE_URL}api/v1`;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT;
export const SENTRY_RELEASE = import.meta.env.VITE_REACT_APP_SENTRY_RELEASE;
export const SENTRY_TRACES_SAMPLE_RATE = import.meta.env
  .VITE_SENTRY_TRACES_SAMPLE_RATE;
export const SENTRY_AUTH_TOKEN = import.meta.env.VITE_SENTRY_AUTH_TOKEN;
export const GOOGLE_TAG_MANAGER_ID = import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID;
export const IS_DEVELOPMENT_MODE = import.meta.env.DEV;
export const COOKIE_BOT_ID = import.meta.env.VITE_COOKIE_BOT_ID;
