import { useQuery } from "@tanstack/react-query";

import { contractsForDistributorRequest } from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";
import { TableOrder } from "src/components/DataTable/DataTable.types";

import { contractKeys } from "./key";

interface QueryProps extends DefaultQueryProps {
  distributorId?: number;
  includeNested: boolean;
  order?: TableOrder;
  page?: number;
  searchValue?: string;
}

export const useContractListForDistributorQuery = ({
  distributorId,
  includeNested,
  searchValue,
  page,
  order,
  ...props
}: QueryProps) =>
  useQuery({
    queryFn: () =>
      contractsForDistributorRequest({
        distributorId,
        includeNested,
        order,
        page,
        searchValue,
      }),
    queryKey: [
      contractKeys.contractListForDistributor,
      includeNested,
      searchValue,
      order,
      page,
    ],
    refetchIntervalInBackground: true,
    ...props,
  });
