import { useQuery } from "@tanstack/react-query";

import type { DistributorContract } from "src/types/contract";
import { contractForDistributorRequest } from "src/services/api/api";
import {
  DefaultQueryProps,
  queryClient,
} from "src/services/serverState/queryClient";

import { contractKeys } from "./key";

interface QueryProps extends DefaultQueryProps {
  contractNumber: string;
}

export const useContractForDistributorQuery = ({
  contractNumber,
  ...props
}: QueryProps) => {
  const contracts: DistributorContract[] | undefined = queryClient.getQueryData(
    [contractKeys.contractForDistributor],
  );

  return useQuery({
    initialData: () =>
      contracts?.find((contract) => contract.contractNumber === contractNumber),
    queryFn: () => contractForDistributorRequest(contractNumber),
    queryKey: [contractKeys.contractForDistributor, contractNumber],
    refetchIntervalInBackground: true,
    ...props,
  });
};
