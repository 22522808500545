/* eslint-disable sort-keys-fix/sort-keys-fix */
import { alpha } from "@mui/material";
import { common } from "@mui/material/colors";
import type { PaletteOptions } from "@mui/material/styles/createPalette";

import {
  blue,
  darkBlue,
  error,
  gradients,
  green,
  info,
  lightBlue,
  neutral,
  orange,
  primary,
  quartaer,
  red,
  secondary,
  success,
  ternary,
  textColorDark,
  textColorLight,
  warning,
  yellow,
} from "./distributorColors";

export const createDistributorPalette = (): PaletteOptions => ({
  // Colors
  blue,
  darkBlue,
  lightBlue,
  orange,
  neutral,
  green,
  red,
  textColorDark,
  textColorLight,
  yellow,
  // Palettes
  primary: primary,
  secondary: secondary,
  ternary: ternary,
  quartaer: quartaer,
  error: error,
  info: info,
  warning: warning,
  success: success,

  // Common Colors
  common: {
    white: "white",
    black: "black",
  },

  // Background
  background: {
    default: common.white,
    paper: common.white,
  },

  // Gradients
  gradients: gradients,

  // Typography
  text: {
    primary: textColorLight,
    secondary: textColorLight,
  },

  // Action
  action: {
    active: neutral[500],
    disabled: alpha(neutral[900]!, 0.38),
    disabledBackground: alpha(neutral[900]!, 0.12),
    focus: alpha(neutral[900]!, 0.16),
    hover: alpha(neutral[900]!, 0.04),
    selected: alpha(neutral[900]!, 0.12),
  },
});

export default createDistributorPalette;
