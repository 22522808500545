import type { FC } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Container, Grid, useTheme } from "@mui/material";
import Box, { type BoxProps } from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import GgLogo from "src/components/Icons/GgLogo";

import { useStyles } from "./MobilePageHeader.styles";

interface HeaderProps extends BoxProps {
  openNavigation?: () => void;
}

export const Header: FC<HeaderProps> = ({ openNavigation, ...props }) => {
  const { classes } = useStyles();
  const theme = useTheme();

  return (
    <Box component="header" className={classes.root} {...props}>
      <Container>
        <Grid container sx={{ height: 64 }}>
          <Grid item xs alignItems="center" display="flex">
            <IconButton onClick={openNavigation}>
              <MenuIcon fontSize="large" color="secondary" />
            </IconButton>
          </Grid>

          <Grid
            item
            xs={8}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <Box display="flex" justifyContent="center">
              <GgLogo width={160} fillColor={theme.palette.secondary.main} />
            </Box>
          </Grid>

          <Grid item xs />
        </Grid>
      </Container>
    </Box>
  );
};
