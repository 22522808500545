export const reverseName = (fullName: string | undefined) => {
  // Split the input string into parts for last name and first name.
  const parts = fullName?.split(", ");

  // Check if both parts are present
  if (parts?.length === 2) {
    // Reverse the parts and concatenate them with a space.
    return `${parts[1]} ${parts[0]}`;
  }

  // If the input format is not correct, return the original string.
  return fullName;
};

export default reverseName;
