export const paths = {
  contractDetails: "/contracts/:contractNumber",
  contracts: "/contracts",
  customerDetail: "/customers/:customerNumber",
  customers: "/customers",
  distributorDetail: "/distributors/:distributorId",
  distributors: "/distributors",
  downloads: "/downloads",
  downloadsWithSubFolder: "/downloads/*",
  forgotPassword: "/forgot-password",
  index: "/",
  login: "/login",
  setPassword: "/set-password",
  updatePassword: "/update-password",
  welcome: "/welcome",
};
