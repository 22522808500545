import axios from "axios";

import { API_URL } from "src/env";

export const AXI = axios.create({
  baseURL: API_URL,
  withCredentials: true, // this flag is important to send server set cookies
});

// Is used for all POST, PUT and PATH requests.
AXI.interceptors.request.use((request) => {
  // We need to manually store and send the csrf token as an x-csrftoken
  // normal csrf session cookie does not work for our requests to our backend
  request.headers["X-CSRFToken"] = `${localStorage.getItem("csrftoken")}`;
  return request;
});
