import { useQuery } from "@tanstack/react-query";

import { distributorDownloadsRequest } from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";

import { downloadKeys } from "./key";

interface QueryProps extends DefaultQueryProps {}

export const useDistributorDownloadQuery = ({ ...props }: QueryProps) =>
  useQuery({
    queryFn: () => distributorDownloadsRequest(),
    queryKey: [downloadKeys.distributorDownload],
    refetchIntervalInBackground: true,
    ...props,
  });
