import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Grid,
  Icon,
  Typography,
  useTheme,
} from "@mui/material";

import { File, Folder } from "src/types/downloads";
import { useDistributorDownloadQuery } from "src/services/serverState/downloads/useDownloadQuery";
import LayoutContentHeader from "src/components/LayoutContentHeader/LayoutContentHeader";
import { Seo } from "src/components/Seo";

import DownloadCard from "./DownloadCard/DownloadCard";

import { useStyles } from "./Downloads.styles";

const DownloadsPage = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const [currentFolder, setCurrentFolder] = useState<null | Folder>(null);
  const [currentUrl, setCurrentUrl] = useState("");

  const { data, isLoading } = useDistributorDownloadQuery({});

  // Function to handle click event on a folder item
  const handleFolderClick = (folder: Folder) => {
    let folderPath = folder.slug;

    // If current URL is not the root (downloads) page, prepend "/" to folder path
    if (location.pathname !== "/downloads") {
      folderPath = "/" + folder.slug.replace(/ /g, "-");
    }

    setCurrentFolder(folder); // Set the current folder to the clicked folder
    setCurrentUrl((currentUrl) => currentUrl + folderPath); // Update the current URL with the folder path
    navigate(currentUrl + folderPath); // Navigate to the updated URL
  };

  const handleFileClick = (file: File) => {
    window.open(file.file, "_blank");
  };

  // Function to render items (subfolders and files) within a folder
  const renderItems = (items: Folder) => (
    <>
      {items.subfolders && // Check if there are subfolders
        items.subfolders.map((subfolder) => (
          <Grid item key={subfolder.id} xs={12} sm={6} md={4} xl={3}>
            <DownloadCard
              date={subfolder.uploadedAt}
              title={subfolder.name}
              itemType="folder"
              onClick={() => handleFolderClick(subfolder)} // Handle click event to open subfolder
            />
          </Grid>
        ))}
      {items.files && // Check if there are files
        items.files.map((file) => (
          <Grid item key={file.id} xs={12} sm={6} md={4} xl={3}>
            <DownloadCard
              date={file.uploadedAt}
              title={file.name || file.originalFilename}
              itemType="file"
              fileType={file.mimeType}
              onClick={() => handleFileClick(file)} // Handle click event to open file
            />
          </Grid>
        ))}
    </>
  );

  // Function to render the content of a folder based on the provided data from the Backend
  const renderFolderContent = (currentFolder: Folder | null) => {
    // If the current URL is empty or no folder is provided, render data (root folders)
    if (currentUrl === "" || !currentFolder) {
      return (
        data &&
        data.map((folder) => (
          <Grid item key={folder.id} xs={12} sm={6} md={4} xl={3}>
            <DownloadCard
              date={folder.uploadedAt}
              title={folder.name}
              itemType="folder"
              onClick={() => handleFolderClick(folder)}
            />
          </Grid>
        ))
      );
    }

    // If a specific folder is provided, render its items
    return renderItems(currentFolder);
  };

  /**
   * Finds a folder by its path in the folder structure.
   * @param folders The array of folders to search within.
   * @returns The found folder or null if not found.
   */
  const findFolderByPath = ({
    folders,
  }: {
    folders: Folder[];
  }): Folder | null => {
    // Get all parts of the path from the URL and store them in an array
    const pathParts = location.pathname.split("/");

    // Remove any empty or unnecessary parts from the path array
    const filteredPathParts = pathParts.filter(
      (value) => value !== "" && value !== "downloads", // Filtering out empty and "downloads" (root) parts
    );

    // Initialize variable to keep track of the current folders being iterated through
    let currentFolders: Folder[] | Folder = [];

    // Iterate through each part of the filtered path
    for (const filteredPathPart of filteredPathParts) {
      // Check if there are any folders currently being iterated through
      if (currentFolders?.length === 0) {
        // If not, set the current folders to the root folders
        currentFolders = folders;

        // Filter the current folders based on the name matching the current path part
        currentFolders = currentFolders.filter(
          (currentFolder) => currentFolder.slug === filteredPathPart,
        );
      } else {
        // If there are current folders being iterated through, narrow down the search to subfolders
        currentFolders = currentFolders[0].subfolders
          ? currentFolders[0].subfolders.filter(
              (currentFolder) => currentFolder.slug === filteredPathPart,
            )
          : []; // If no subfolders exist, set current folders to an empty array
      }
    }

    // If one folder matches the path, return it
    if (currentFolders.length === 1) {
      return currentFolders[0];
    }

    // If no folder matches the path or there are multiple matches, return null.
    // At this point there should not be multiple folders
    return null;
  };

  // useEffect hook to handle changes in the current URL
  useEffect(() => {
    // Find the current folder based on the URL path
    const currentFolderByPath = findFolderByPath({
      folders: data || [],
    });

    // If a folder is found based on the path, set it as the current folder
    currentFolderByPath && setCurrentFolder(currentFolderByPath);
  }, [currentUrl]);

  // useEffect hook to handle changes in data and location
  useEffect(() => {
    // Set the current URL based on the location pathname, empty string if it's "/downloads"
    setCurrentUrl(location.pathname === "/downloads" ? "" : location.pathname);

    // Find the current folder based on the URL path
    const currentFolderByPath = findFolderByPath({
      folders: data || [],
    });

    // If a folder is found based on the path, set it as the current folder
    currentFolderByPath && setCurrentFolder(currentFolderByPath);

    // If data is available, no folder is found based on the path, and the location is not "/downloads", navigate to root
    if (
      data &&
      currentFolderByPath === null &&
      location.pathname !== "/downloads"
    ) {
      navigate("");
    }
  }, [data, location]);

  return (
    <>
      <Seo title={t("downloads.navigationNavTitle")} />
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <LayoutContentHeader
          breadcrumbIcon={
            <Icon sx={{ mr: 0.5 }} fontSize="inherit">
              download
            </Icon>
          }
          showBreadcrumb
          headerTitle={t("downloads.navigationTitle")}
        />
        {data && !isLoading && (
          <Grid container spacing={3}>
            {renderFolderContent(currentFolder)}
          </Grid>
        )}

        {isLoading && (
          <Box className={classes.loadingAndError}>
            <CircularProgress sx={{ color: theme.palette.primary.main }} />
          </Box>
        )}

        {!data && !isLoading && (
          <Box className={classes.loadingAndError}>
            <Typography>{t("common.somethingWentWrong")}</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default DownloadsPage;
