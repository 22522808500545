import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
  root: {
    "&:hover": {
      "& h3": {
        color: theme.palette.ternary.main,
      },
      borderColor: theme.palette.secondary.light,
    },
    border: "2px solid transparent",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(3),
    position: "relative",

    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },

  statusChip: {
    marginTop: theme.spacing(1),

    [theme.breakpoints.down("lg")]: {
      margin: 0,
      position: "absolute",
      right: theme.spacing(2),
      top: theme.spacing(2),
    },
  },
}));
