import { useQuery } from "@tanstack/react-query";

import { distributorTopTenRequest, TopTenOrdering } from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";

import { distributorKeys } from "./key";

interface DistributorTopTenQueryProps extends DefaultQueryProps {
  currency: string;
  distributorId: number | undefined;
  ordering: TopTenOrdering;
  period: "month" | "year";
}
export const useDistributorTopTenQuery = ({
  currency,
  distributorId,
  ordering,
  period,
  ...props
}: DistributorTopTenQueryProps) =>
  useQuery({
    queryFn: () =>
      distributorTopTenRequest({ currency, distributorId, ordering, period }),
    queryKey: [
      currency,
      distributorKeys.distributorTopTen,
      distributorId,
      ordering,
      period,
    ],
    refetchIntervalInBackground: true,
    ...props,
  });
