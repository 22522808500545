import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { TopTenOrdering } from "src/services/api/api";
import { useUser } from "src/services/contexts/userContext";
import DataTable from "src/components/DataTable/DataTable";
import {
  CellType,
  Row,
  TableHeader,
} from "src/components/DataTable/DataTable.types";

type FormattedTopTenArray = Array<{
  distributorLabel: string;
  totalContractFees: number;
  totalOrderTotals: number;
  distributorId: number;
}>;

interface DistributorTopTenProps {
  topTenPartners: FormattedTopTenArray;
  isLoading: boolean;
  selectedKey: TopTenOrdering;
}

export const DistributorTopTen: FC<DistributorTopTenProps> = ({
  topTenPartners,
  isLoading,
  selectedKey,
  ...props
}) => {
  const { t } = useTranslation();
  const { userState } = useUser();

  const tableHeaders: TableHeader[] = [
    {
      id: "name",
      label: "Name",
      width: 200,
    },
    {
      id: "totalSum",
      label: t(`dashboard.widgets.${selectedKey}`),
      width: 200,
    },
  ];

  const rows: Row[] =
    topTenPartners?.map((partner, index) => ({
      cells: [
        {
          cellType: CellType.TITLE,
          title: `${index + 1}. ${partner.distributorLabel}`,
        },
        {
          amount:
            selectedKey === "sumContractFees"
              ? partner.totalContractFees
              : partner.totalOrderTotals,
          cellType: CellType.TRANSACTION,
        },
      ],
      id: partner.distributorLabel,
    })) || [];

  return (
    <DataTable
      currencyCode={userState.selectedCurrency}
      dataTableHeight={255}
      headers={tableHeaders}
      loading={isLoading}
      rows={rows}
      sx={{ marginBottom: "0!important" }} // Have to use important
      {...props}
    />
  );
};
