import type { FC } from "react";

import { PublicGuard } from "./PublicGuard";

export const withPublicGuard = <P extends object>(Component: FC<P>): FC<P> =>
  function WithPublicGuard(props: P) {
    return (
      <PublicGuard>
        <Component {...props} />
      </PublicGuard>
    );
  };
