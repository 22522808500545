import { tss } from "tss-react/mui";

export const useStyles = tss.create(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
}));
