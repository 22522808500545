import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
  title: {
    color: theme.palette.textColorDark,
    display: "-webkit-box",
    height: 40,
    marginBottom: 2,
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  },
}));
