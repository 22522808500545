import { useState } from "react";

import {
  CommodityBookingData,
  CommodityUnitCombination,
} from "src/types/contract";
import { commodityBookingsForContractRequest } from "src/services/api/api";

// Fetch commodity bookings for each commodity (No reactQuery used).
const useCommodityBookings = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // The key is the combination of commodity and unit
  const [bookingsPerCommodity, setBookingsPerCommodity] = useState<
    Record<string, CommodityBookingData>
  >({});

  const resetBookingsPerCommodity = () => setBookingsPerCommodity({});

  // Get ContractBookings of each Commodity
  const fetchCommodityBookings = async ({
    commodity,
    contractNumber,
    unit,
    fetchMore,
  }: CommodityUnitCombination & {
    contractNumber: string | undefined;
    fetchMore: boolean;
  }) => {
    setIsLoading(true);
    const existingData = bookingsPerCommodity[`${commodity}/${unit}`];
    const existingCursor = existingData?.next;

    await commodityBookingsForContractRequest({
      contractCommodity: commodity,
      contractCommodityUnit: unit,
      contractNumber: contractNumber || "",
      pageParam: existingCursor,
    })
      .then((result) => {
        setBookingsPerCommodity({
          ...(fetchMore ? bookingsPerCommodity : {}),
          [`${commodity}/${unit}`]: {
            bookings: [
              ...(existingData && fetchMore ? existingData.bookings : []),
              ...result.results,
            ],
            next: result.next,
          },
        });

        setIsLoading(false);
      })
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false));
  };

  return {
    bookingsPerCommodity,
    fetchCommodityBookings,
    isError,
    isLoading,
    resetBookingsPerCommodity,
  };
};

export default useCommodityBookings;
