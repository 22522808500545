import { useQuery } from "@tanstack/react-query";

import { contractsForDistributorFromCustomerRequest } from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";
import { TableOrder } from "src/components/DataTable/DataTable.types";

import { contractKeys } from "./key";

interface QueryProps extends DefaultQueryProps {
  customerNumber: string | number;
  distributorId: string | number;
  includeNested: boolean;
  order?: TableOrder;
  page?: number;
}

export const useContractListForDistributorFromCustomerQuery = ({
  distributorId,
  customerNumber,
  order,
  includeNested,
  page,
  ...props
}: QueryProps) =>
  useQuery({
    queryFn: () =>
      contractsForDistributorFromCustomerRequest({
        customerNumber,
        distributorId,
        includeNested,
        order,
        page,
      }),
    queryKey: [
      contractKeys.contractListForDistributorFromCustomer,
      customerNumber,
      includeNested,
      order,
      page,
    ],
    refetchIntervalInBackground: true,
    ...props,
  });
