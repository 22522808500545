import { type FC, type ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import {
  Breadcrumbs,
  Grid,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import Box, { type BoxProps } from "@mui/material/Box";

interface LayoutContentHeaderProps extends BoxProps {
  breadcrumbIcon?: ReactNode;
  headerSubTitle?: string;
  headerSubTitleSmall?: string;
  headerTitle: string;
  headerWidget?: ReactNode;
  isLoading?: boolean;
  showBreadcrumb?: boolean;
}

export const LayoutContentHeader: FC<LayoutContentHeaderProps> = ({
  breadcrumbIcon,
  headerSubTitle,
  headerSubTitleSmall,
  headerTitle,
  headerWidget,
  isLoading,
  showBreadcrumb = false,
  ...props
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const pathnames = pathname.split("/").filter(Boolean);

  return (
    <Box component="div" {...props}>
      <Grid container spacing={2}>
        <Grid item xs={8} md={8} lg={7}>
          {showBreadcrumb && (
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
              {pathnames.map((name, index) => {
                const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                const isLast = index === pathnames.length - 1;
                const isFirst = index === 0;

                if (pathnames.length === 1) {
                  return (
                    <Typography
                      style={{
                        alignItems: "center",
                        color: theme.palette.textColorDark,
                        display: "flex",
                      }}
                      key={name}
                    >
                      {breadcrumbIcon} {name}
                    </Typography>
                  );
                }

                if (isLast) {
                  return <Typography key={name}>{name}</Typography>;
                }

                return (
                  <Link
                    style={{
                      alignItems: "center",
                      color: theme.palette.textColorDark,
                      display: "flex",
                      textDecoration: "none",
                    }}
                    key={name}
                    to={routeTo}
                  >
                    {isFirst && breadcrumbIcon && breadcrumbIcon}
                    {i18n.exists(`navigation.${name}`)
                      ? t(`navigation.${name}`)
                      : name}
                  </Link>
                );
              })}
            </Breadcrumbs>
          )}

          <Typography variant="h1" color="textColorDark">
            {!isLoading ? headerTitle : <Skeleton variant="text" />}
          </Typography>

          <Typography>{headerSubTitleSmall}</Typography>

          <Typography variant="h2" color="textColorDark" sx={{ my: 3 }}>
            {headerSubTitle}
          </Typography>
        </Grid>

        {headerWidget && (
          <Grid display="flex" justifyContent="end" item xs={12} md={4} lg={5}>
            {headerWidget}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default LayoutContentHeader;
