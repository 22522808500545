/* eslint-disable sort-keys-fix/sort-keys-fix */
import { alpha } from "@mui/material";
import { common } from "@mui/material/colors";
import type {
  ColorRange,
  PaletteColor,
} from "@mui/material/styles/createPalette";

const withAlphas = (color: PaletteColor): PaletteColor => ({
  ...color,
  alpha12: alpha(color.main, 0.12),
  alpha30: alpha(color.main, 0.3),
  alpha4: alpha(color.main, 0.04),
  alpha50: alpha(color.main, 0.5),
  alpha8: alpha(color.main, 0.08),
});

export const textColorLight = "#6e6d6d";
export const textColorDark = "#333436";

// GoldenGates Primary
export const darkBlue: ColorRange = {
  50: "#E6E8ED",
  100: "#C0C6D2",
  200: "#97A0B4",
  300: "#6D7A95",
  400: "#4D5E7F",
  500: "#2E4168",
  600: "#293B60",
  700: "#233255",
  800: "#1D2A4B",
  900: "#121C3A",
};

// GoldenGates Secondary
export const lightBlue: ColorRange = {
  50: "#FBFCFE",
  100: "#F4F8FC",
  200: "#EDF4FA",
  300: "#E6EFF7",
  400: "#E0EBF6",
  500: "#DBE8F4",
  600: "#D7E5F3",
  700: "#D2E2F1",
  800: "#CDDEEF",
  900: "#C4D8EC",
};

// GoldenGates Ternary
export const blue: ColorRange = {
  50: "#F3F7F9",
  100: "#E1EBF1",
  200: "#CDDEE8",
  300: "#B9D1DF",
  400: "#AAC7D8",
  500: "#9BBDD1",
  600: "#93B7CC",
  700: "#89AEC6",
  800: "#7FA6C0",
  900: "#6D98B5",
};

// GoldenGates Quartaer
export const orange: ColorRange = {
  50: "#FBEDE6",
  100: "#F4D2C0",
  200: "#EDB596",
  300: "#E6976B",
  400: "#E0804C",
  500: "#DB6A2C",
  600: "#D76227",
  700: "#D25721",
  800: "#CD4D1B",
  900: "#C43C10",
};

export const neutral: ColorRange = {
  50: "#E7E7E7",
  100: "#C2C2C3",
  200: "#999A9B",
  300: "#707172",
  400: "#525254",
  500: "#333436",
  600: "#2E2F30",
  700: "#272729",
  800: "#202122",
  900: "#141516",
};

export const red: ColorRange = {
  50: "#FCEDEB",
  100: "#F8D1CE",
  200: "#F3B2AE",
  300: "#EE938D",
  400: "#EA7C74",
  500: "#E6655C",
  600: "#E35D54",
  700: "#DF534A",
  800: "#DB4941",
  900: "#D53730",
};

export const green: ColorRange = {
  50: "#ECF3EB",
  100: "#CEE1CD",
  200: "#AECEAC",
  300: "#8EBA8B",
  400: "#75AB72",
  500: "#5D9C59",
  600: "#559451",
  700: "#4B8A48",
  800: "#41803E",
  900: "#306E2E",
};

export const yellow: ColorRange = {
  50: "#FDF7E0",
  100: "#FBEBB3",
  200: "#F8DE80",
  300: "#F5D04D",
  400: "#F3C626",
  500: "#F1BC00",
  600: "#EFB600",
  700: "#EDAD00",
  800: "#EBA500",
  900: "#EBA500",
};

export const primary = withAlphas({
  light: darkBlue[300],
  lightest: darkBlue[400],
  main: darkBlue[500],
  dark: darkBlue[600],
  darkest: darkBlue[700],
  contrastText: common.white,
});

export const secondary = withAlphas({
  light: lightBlue[300],
  lightest: lightBlue[400],
  main: lightBlue[500],
  dark: lightBlue[600],
  darkest: lightBlue[700],
  contrastText: darkBlue[500],
});

export const ternary = withAlphas({
  light: blue[300],
  lightest: blue[400],
  main: blue[500],
  dark: blue[600],
  darkest: blue[700],
  contrastText: darkBlue[500],
});

export const quartaer = withAlphas({
  light: darkBlue[300],
  lightest: darkBlue[400],
  main: darkBlue[500],
  dark: darkBlue[600],
  darkest: darkBlue[700],
  contrastText: common.white,
});

export const success = withAlphas({
  light: green[300],
  lightest: green[400],
  main: green[500],
  dark: green[600],
  darkest: green[700],
  contrastText: textColorDark,
});

export const error = withAlphas({
  light: red[300],
  lightest: red[400],
  main: red[500],
  dark: red[600],
  darkest: red[700],
  contrastText: common.white,
});

export const warning = withAlphas({
  light: yellow[300],
  lightest: yellow[400],
  main: yellow[500],
  dark: yellow[600],
  darkest: yellow[700],
  contrastText: textColorDark,
});

export const info = withAlphas({
  light: blue[300],
  lightest: blue[400],
  main: blue[500],
  dark: blue[600],
  darkest: blue[700],
  contrastText: textColorDark,
});

export const gradients = {
  light: "linear-gradient(294deg, #FBFCFE 0%, #F4F8FC 100%)",
  dark: "linear-gradient(294deg, #2E4168 0%, #233255 100%)",
  panel: "linear-gradient(294deg, #CDDEE8 0%, #B9D1DF 100%)",
};
