/* eslint-disable sort-keys-fix/sort-keys-fix */
import { alpha } from "@mui/material";
import { backdropClasses } from "@mui/material/Backdrop";
import { common } from "@mui/material/colors";
import { filledInputClasses } from "@mui/material/FilledInput";
import { paperClasses } from "@mui/material/Paper";
import type { Components } from "@mui/material/styles/components";
import type {
  PaletteColor,
  PaletteOptions,
} from "@mui/material/styles/createPalette";
import { tableCellClasses } from "@mui/material/TableCell";

interface Config {
  palette: PaletteOptions;
}

export const createComponents = ({ palette }: Config): Components => ({
  MuiAvatar: {
    styleOverrides: {
      root: {
        backgroundColor: palette.neutral![200],
        color: common.black,
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      root: {
        [`&.${filledInputClasses.disabled}`]: {
          backgroundColor: alpha((palette.primary as PaletteColor).main, 0.5),
        },
      },
    },
  },

  MuiCircularProgress: {
    styleOverrides: {
      root: {
        color: (palette.secondary as PaletteColor).main,
      },
    },
  },

  MuiBackdrop: {
    styleOverrides: {
      root: {
        [`&:not(.${backdropClasses.invisible})`]: {
          backgroundColor: alpha(palette.neutral![900]!, 0.75),
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        [`&.${paperClasses.elevation1}`]: {
          boxShadow:
            "0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      icon: {
        color: palette.action!.active,
      },
      root: {
        borderColor: palette.neutral![200],
        fontSize: "0.7em",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
  },

  MuiCssBaseline: {
    styleOverrides: {
      ".slick-dots li button": {
        "&:before": {
          color: (palette.primary as PaletteColor).main,
          fontSize: 10,
        },
      },
      ".slick-dots li.slick-active button": {
        "&:before": {
          color: (palette.primary as PaletteColor).main,
        },
      },
    },
  },

  MuiInputBase: {
    styleOverrides: {
      input: {
        "&::placeholder": {
          color: palette.text!.secondary,
        },

        // check comment "Mui browser auto fill bug" in the LoginForm component.
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          WebkitTransitionDelay: "9999999s",
          transitionProperty: "background-color, color",
        },
      },
    },
  },

  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: palette.common?.white,

        [`&.${filledInputClasses.focused}`]: {
          color: palette.common?.white,
        },

        [`&.${filledInputClasses.error}`]: {
          color: palette.common?.white,
        },
      },
    },
  },

  MuiFormHelperText: {
    styleOverrides: {
      root: {
        color: palette.common?.white,
        marginLeft: "2px",
        marginTop: "8px",

        [`&.${filledInputClasses.error}`]: {
          color: palette.common?.white,
        },
      },
    },
  },

  MuiFilledInput: {
    styleOverrides: {
      input: {
        // check comment "Mui browser auto fill bug" in the LoginForm component.
        "&:-webkit-autofill": {
          WebkitBoxShadow: "inherit",
          WebkitTextFillColor: "inherit",
          caretColor: "inherit",
        },

        "&::placeholder": {
          color: palette.common?.white,
        },
        color: palette.common?.white,
      },

      root: {
        backgroundColor: (palette.quartaer as PaletteColor).main,
        borderRadius: "4px",
        boxShadow: "none",
        border: "2px solid " + (palette.quartaer as PaletteColor).main,

        transition: "border-color 0.2s ease",

        "&:hover": {
          backgroundColor: (palette.quartaer as PaletteColor).main,
          borderBottomColor: "none",
          border: "2px solid " + palette.ternary.main,
        },

        "&::before, &::after": {
          borderBottomColor: "none",
          display: "none",
          boxShadow: "none",
        },

        [`&.${filledInputClasses.disabled}`]: {
          backgroundColor: (palette.quartaer as PaletteColor).main,
          border: "2px solid " + (palette.quartaer as PaletteColor).main,
        },

        [`&.${filledInputClasses.focused}`]: {
          backgroundColor: (palette.quartaer as PaletteColor).main,
          border: "2px solid " + (palette.primary as PaletteColor).main,
          boxShadow: "none",
        },

        [`&.${filledInputClasses.error}`]: {
          backgroundColor: (palette.quartaer as PaletteColor).main,
          border: "2px solid " + (palette.error as PaletteColor).main,
          boxShadow: "none",

          "&::before, &::after": {
            border: "none",
            borderBottomColor: "none",
            boxShadow: "none",
          },
        },
      },
    },
  },

  MuiSwitch: {
    styleOverrides: {
      switchBase: {
        color: palette.neutral![500],
      },
      track: {
        backgroundColor: palette.neutral![400],
        opacity: 1,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottomColor: palette.divider,
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        [`& .${tableCellClasses.root}`]: {
          backgroundColor: palette.neutral![50],
          color: palette.neutral![700],
        },
      },
    },
  },

  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backdropFilter: "blur(6px)",
        background: alpha(palette.neutral![900]!, 0.8),
      },
    },
  },
});
