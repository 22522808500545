import { type FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  alpha,
  Box,
  Button,
  CardActions,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListProps,
  Typography,
  useTheme,
} from "@mui/material";

import { paths } from "src/paths";
import {
  Contract,
  ContractBooking,
  DistributorContract,
} from "src/types/contract";
import {
  formatCommodityAmount,
  formatCurrencyNumber,
} from "src/utils/formatTexts";
import { getCurrency } from "src/utils/getCurrency";
import { CursorPaginatedResponse } from "src/services/api/api";
import NoData from "src/components/NoData/NoData";

import ContractBookingListSkeleton from "./ContractBookingList.skeleton";
import {
  ColorVariant,
  getContractBookingIconAndColor,
} from "./getContractBookingIconAndColor";

import { useStyles } from "./ContractBookingList.styles";

interface ContractBookingListProps extends ListProps {
  contractBookingPages: CursorPaginatedResponse<ContractBooking>[] | undefined;
  contracts: Contract[] | DistributorContract[] | [];
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  isLoading: boolean;
  renderAllContracts?: boolean;
}

export const ContractBookingList: FC<ContractBookingListProps> = ({
  contractBookingPages,
  contracts,
  fetchNextPage,
  hasNextPage,
  isLoading,
  renderAllContracts = false,
  ...props
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const { classes, cx } = useStyles();

  if (isLoading) {
    return (
      <List
        disablePadding
        className={cx(renderAllContracts && classes.scrollable)}
        sx={{ padding: 0 }}
        {...props}
      >
        <ContractBookingListSkeleton renderAllContracts={renderAllContracts} />
      </List>
    );
  }

  // Return nothing if no contract bookings exist.
  if (contractBookingPages && !contractBookingPages![0].results.length) {
    return <NoData />;
  }

  return (
    <List
      disablePadding
      className={cx(renderAllContracts && classes.scrollable)}
      sx={{ padding: 0 }}
      {...props}
    >
      {!!contractBookingPages?.length &&
        contractBookingPages?.map((group, groupIndex) => (
          <Fragment key={groupIndex}>
            {group.results.map((contractBooking, contractIndex) => {
              const { colorVariant, contractBookingIcon } =
                getContractBookingIconAndColor({
                  bookingType: contractBooking.bookingType,
                });

              return (
                <ListItem
                  key={contractBooking.backofficeId}
                  sx={{
                    backgroundColor:
                      contractIndex % 2 === 0
                        ? alpha(theme.palette.neutral[50], 0.2)
                        : "",
                  }}
                >
                  <Box
                    className={cx(
                      classes.bookingArrow,
                      colorVariant === ColorVariant.Buy &&
                        classes.bookingArrowGreen,
                      colorVariant === ColorVariant.Sell &&
                        classes.bookingArrowRed,
                    )}
                  >
                    {contractBookingIcon}
                  </Box>

                  <ListItemText>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {contractBooking.bookingText}
                      {Number(contractBooking.commodityAmount) !== 0 &&
                        `: ${t(
                          `common.elementSymbols.${contractBooking.commodity}`,
                        )}`}
                    </Typography>

                    <Typography
                      color="text.secondary"
                      variant="body2"
                      component="span"
                    >
                      {contractBooking.bookingDate}
                    </Typography>

                    {renderAllContracts && (
                      <>
                        {" - "}
                        <Link
                          style={{
                            color: theme.palette.text.secondary,
                          }}
                          to={`${paths.contracts}/${contractBooking.contractNumber}`}
                        >
                          <Typography
                            color="text.secondary"
                            variant="body2"
                            component="span"
                          >
                            {contractBooking.contractNumber}
                          </Typography>
                        </Link>
                      </>
                    )}
                  </ListItemText>

                  <ListItemSecondaryAction>
                    <Typography
                      variant="subtitle2"
                      textAlign="right"
                      className={cx(
                        colorVariant === ColorVariant.Buy &&
                          classes.bookingTextGreen,
                        colorVariant === ColorVariant.Sell &&
                          classes.bookingTextRed,
                      )}
                    >
                      {formatCurrencyNumber(
                        contractBooking.moneyAmount,
                        getCurrency(contractBooking.contractNumber, contracts),
                      )}{" "}
                    </Typography>

                    <Typography
                      color="text.secondary"
                      variant="body2"
                      textAlign="right"
                    >
                      {/* In this List the CommodityAmount should not be rendered, if the amount is "0" (Unlike in other lists or cases). */}
                      {Number(contractBooking.commodityAmount) !== 0 &&
                        `${formatCommodityAmount(
                          Number(contractBooking.commodityAmount),
                        )} ${contractBooking.unit}`}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}

            {hasNextPage && fetchNextPage && (
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="primary"
                  onClick={() => fetchNextPage()}
                  size="small"
                  sx={{ m: 0.5, textTransform: "none" }}
                  variant="text"
                >
                  {t("contracts.contractDetails.loadMoreButton")}
                  <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                </Button>
              </CardActions>
            )}
          </Fragment>
        ))}
    </List>
  );
};

export default ContractBookingList;
