import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, BoxProps, Typography } from "@mui/material";

export interface NoDataProps extends BoxProps {}

export const NoData: FC<NoDataProps> = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="center" sx={{ p: 3 }} {...props}>
      <Typography color="info" variant="h3">
        {t("common.noData")}
      </Typography>
    </Box>
  );
};

export default NoData;
