enum ContractInterval {
  MONTHLY = 1,
  QUATERNARY = 3,
  HALF_YEARLY = 6,
  ANNUAL = 12,
  ONCE = 999,
}

export interface Contract {
  acquisitionDate: string;
  contractFees: number;
  contractNumber: string;
  contributionAmount: number;
  currency: string;
  customerLabel: string;
  customerNumber: number;
  customerProfile?: Customer;
  endDate?: string;
  interval?: ContractInterval;
  lastDepositMoneyAmount: number; // value of contract booking
  lastDepositValueDate: string; // value of contract booking
  openPositionsAmount: number;
  orderTotal: number;
  phase: ContractPhase;
  productLabel: string;
  startDate: string;
  status: ContractStatus;
  totalDeposits: number; // calculated (Gesamtbetrag getätigter Einzahlungen)
  totalOtherFees: number; // calculated (Gesamtbetrag sonstiger Gebühren)
  totalPaidFees: number; // calculated (Gesamtbetrag gezahlte Gebühren)
  totalPayInTarget: number; // calculated (Zielbetrag Einzahlungen nach Ende)
  totalPurchaseAmount: number; // calculated (Gesamtbetrag bisherige Käufe)
}

export interface DistributorContract extends Contract {
  depositRate: number; // calculated (Einzahlungsquote)
}

export interface Customer {
  distributorContracts: string[];
  country: string;
  customerLabel: string;
  customerNumber: number;
  depositRate?: number;
  email: string;
  lastDistributor?: DistributorContact;
  persons: number[];
  phone: string;
  street: string;
  streetAddition: string;
  town: string;
  zipCode: string;
}

export enum BookingType {
  AB = "AB", // "Betrag" -> "Auszahlung"  || "Menge" -> "Bestandsveränderung"
  AG = "AG", // sonstige Gebühren
  AL = "AL", // Bestandsveränderung
  AZ = "AZ", // Auszahlung
  BG = "BG", // Sonstige Gebühren
  DG = "DG", // Gebühr" sonstige Gebühren
  EB = "EB", // "Betrag" -> "Einzahlung"  || "Menge" -> "Bestandsveränderung"
  EG = "EG", // Kaufgebühr
  EL = "EL", // Bestandszugang
  EZ = "EZ", // Einzahlung
  ID = "ID", // sonstige Gebühren
  LG = "LG", // Lagergebühren
  MG = "MG", // sonstige Gebühren
  RK = "RK", // Kauf
  RV = "RV", // Verkauf
  SP = "SP", // sonstige Gebühren
  UB = "UB", // "Betrag" -> "Einzahlung oder Auszahlung"  || "Menge" -> "Bestandsveränderung"
  VE = "VE", // Zz. keine Relevanz
  VG = "VG", // Zz. keine Relevanz
  VH = "VH", // Zz. keine Relevanz
  VZ = "VZ", // Sonstige Gebühren
}

export enum ContractStatus {
  Active = 11, // aktiv
  Inactive = 12, // inaktiv
  Ended = 19, // beendet
}

export enum ContractPhase {
  New = 10, // neu
  Ongoing = 11, // laufend
  FreeOfPayment = 12, // zahlfrei
  PaidInFull = 13, // vollständig bezahlt
  RegularlyEnded = 14, // regulär beendet
  Terminated = 15, // gekündigt
  Canceled = 16, // storniert
}

export enum ContractBookingUnit {
  GRAM = "g",
  OUNCE = "oz",
}

export interface ContractBooking {
  backofficeId: number;
  bookingDate: string;
  bookingText: string;
  bookingType: BookingType;
  commodity: string;
  commodityAmount: string;
  commodityPrice: number;
  contractNumber: string;
  moneyAmount: number;
  unit: string;
  valueDate: number;
}
export interface Commodity {
  averageBuyingPrice?: number;
  currency?: string;
  name: string;
  totalAmount: number;
  unit: string;
}

export interface CommodityBookingData {
  next: string | null | undefined;
  bookings: ContractBooking[];
}

export interface CommodityUnitCombination {
  commodity: string;
  unit: string;
}

export interface DistributorContact {
  distributorLabel: string;
  email?: string;
  mobilePhone?: string;
  phone?: string;
}
