import { useTranslation } from "react-i18next";

import { useUser } from "src/services/contexts/userContext";
import PublicLayout from "src/layouts/PublicLayout/PublicLayout";
import RequestPasswordForm from "src/components/Forms/RequestPasswordForm/RequestPasswordForm";
import { Seo } from "src/components/Seo";

export const WelcomePage = () => {
  const { t } = useTranslation();
  const { userActions } = useUser();

  return (
    <>
      <Seo title={t("auth.welcome")} />
      <PublicLayout title={t("auth.welcome")}>
        <RequestPasswordForm
          buttonText={t("auth.welcomeButtonText")}
          description={t("auth.welcomeDescription")}
          submitHandler={(email) => userActions.inviteUser({ email })}
        />
      </PublicLayout>
    </>
  );
};

export default WelcomePage;
