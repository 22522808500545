import type { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  Card,
  CardContent,
  CardProps,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";

import WidgetActionTabs from "src/components/ActionTabs/ActionTabs";

interface WidgetWrapperProps extends CardProps {
  actionTabsChoices?: { value: string; label: string }[];
  children: ReactNode;
  footerText?: string;
  headerText: string;
  redirectPath?: string;
  showDivider?: boolean;
  onActionSwitch?: (tabValue: string) => void;
}

export const WidgetWrapper: FC<WidgetWrapperProps> = ({
  actionTabsChoices,
  children,
  footerText,
  headerText,
  redirectPath,
  showDivider = true,
  onActionSwitch,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Card {...props}>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid item>
          <Typography
            sx={{ p: 2, py: 3, whiteSpace: "nowrap" }}
            variant="h2"
            color={theme.palette.common.black}
          >
            {headerText}
          </Typography>
        </Grid>

        {actionTabsChoices && onActionSwitch && (
          <Grid mr={2} item sx={{ height: "100%", overflow: "hidden" }}>
            <WidgetActionTabs
              onTabSwitch={(tabValue) => onActionSwitch(tabValue)}
              tabs={actionTabsChoices}
            />
          </Grid>
        )}
      </Grid>

      {showDivider && <Divider />}

      <CardContent style={{ padding: 0 }}>{children}</CardContent>

      {footerText && redirectPath && (
        <Box
          minHeight={50}
          sx={{ backgroundImage: theme.palette.gradients.panel, px: 4 }}
          display="flex"
          justifyContent="end"
          alignItems="center"
        >
          <Link
            style={{
              color: theme.palette.ternary.dark,
              fontWeight: "bold",
              textDecoration: "none",
            }}
            to={redirectPath}
          >
            {footerText}

            <IconButton size="small" disabled color="primary">
              <ArrowForwardIcon sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Link>
        </Box>
      )}
    </Card>
  );
};

export default WidgetWrapper;
