import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
  top: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  value: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4rem",
    },
  },
}));
