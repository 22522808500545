import { useQuery } from "@tanstack/react-query";

import { distributorProvisionRequest } from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";

import { distributorKeys } from "./key";

interface useDistributorProvisionQueryProps extends DefaultQueryProps {
  currency: string;
  distributorId: number | undefined;
}
export const useDistributorProvisionQuery = ({
  currency,
  distributorId,
  ...props
}: useDistributorProvisionQueryProps) =>
  useQuery({
    queryFn: () => distributorProvisionRequest({ currency, distributorId }),
    queryKey: [currency, distributorKeys.distributorProvision, distributorId],
    refetchIntervalInBackground: true,
    ...props,
  });
