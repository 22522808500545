import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Box, BoxProps, Tab, Tabs, Typography } from "@mui/material";

import DistributorHierarchy from "src/pages/Distributor.Distributors/DistributorHierarchy/DistributorHierarchy";
import { paths } from "src/paths";
import { formatCurrencyNumber } from "src/utils/formatTexts";
import { useUser } from "src/services/contexts/userContext";
import { useDistributorPartnerListQuery } from "src/services/serverState/distrubutorPartner/useDistributorPartnerListQuery";
import CurrencySelection from "src/components/CurrencySelection/CurrencySelection";
import DataTable from "src/components/DataTable/DataTable";
import {
  CellType,
  Row,
  TableHeader,
} from "src/components/DataTable/DataTable.types";
import { useTableState } from "src/components/DataTable/useTableState";
import LayoutContentHeader from "src/components/LayoutContentHeader/LayoutContentHeader";
import { Seo } from "src/components/Seo";

import { useStyles } from "./Distributor.Distributors.styles";

enum DistributorsTabChoice {
  OVERVIEW = "overview",
  NESTED_VIEW = "nested_view",
}

export const DistributorDistributorsPage: FC<BoxProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const {
    currentTablePage,
    debouncedSearchValue,
    handleOrderChange,
    order,
    searchValue,
    setCurrentTablePage,
    setSearchValue,
  } = useTableState();

  const { userState } = useUser();
  const distributorId = userState.user?.activeDistributorProfile?.distributorId;
  const selectedCurrency = userState.selectedCurrency;
  const [selectedTab, setSelectedTab] = useState(
    DistributorsTabChoice.OVERVIEW,
  );

  const {
    data: partnersData,
    isLoading,
    isError,
  } = useDistributorPartnerListQuery({
    currency: selectedCurrency,
    distributorId,
    order,
    page: currentTablePage,
    searchValue: debouncedSearchValue,
  });
  const { totalCount, results: partners } = partnersData || {};

  const tableHeaders: TableHeader[] = [
    {
      id: "distributorLabel",
      label: t("distributors.partners.name"),
      width: 200,
    },
    {
      id: "zipCode",
      label: t("distributors.partners.plz"),
      width: 140,
    },
    {
      id: "town",
      label: t("distributors.partners.location"),
      width: 100,
    },
    {
      id: "sumContractFees",
      label: t("distributors.partners.fees"),
      width: 180,
    },
    {
      id: "sumOrderTotals",
      label: t("distributors.partners.purchasePrice"),
      width: 220,
    },
    {
      id: "sumContributionAmount",
      label: t("distributors.partners.monthlyPaid"),
      width: 220,
    },
  ];

  const rows: Row[] =
    partners?.map((partner) => {
      const distributionNumbersString = partner.distributionNumbers.join(", ");

      return {
        cells: [
          {
            cellType: CellType.TITLE,
            subtitle: distributionNumbersString,
            title: partner.distributorLabel,
          },
          {
            cellType: CellType.TEXT,
            text: partner.zipCode,
          },
          {
            cellType: CellType.TEXT,
            text: partner.town,
          },
          {
            cellType: CellType.TEXT,
            text: formatCurrencyNumber(
              partner.sumContractFees,
              selectedCurrency,
            ),
          },
          {
            cellType: CellType.TEXT,
            text: formatCurrencyNumber(
              partner.sumOrderTotals,
              selectedCurrency,
            ),
          },
          {
            cellType: CellType.TEXT,
            text: formatCurrencyNumber(
              partner.sumContributionAmount,
              selectedCurrency,
            ),
          },
        ],
        id: partner.distributorProfile,
      };
    }) || [];

  const renderSelectedTab = () => {
    switch (selectedTab) {
      case DistributorsTabChoice.OVERVIEW:
        return (
          <>
            {isError && (
              <Box display="flex" justifyContent="center">
                <Typography color="error">
                  {t("common.somethingWentWrong")}
                </Typography>
              </Box>
            )}

            {!isError && (
              <DataTable
                currencyCode={selectedCurrency}
                currentPage={currentTablePage}
                headers={tableHeaders}
                loading={isLoading}
                OnTableRowClick={(distributorId) =>
                  navigate(`${paths.distributors}/${distributorId}`)
                }
                onOrderChange={handleOrderChange}
                onPageChange={setCurrentTablePage}
                onSearchValueChange={setSearchValue}
                order={order}
                rows={rows}
                searchLabel={t("distributors.partners.searchPlaceholder")}
                searchValue={searchValue}
                totalResults={totalCount}
              />
            )}
          </>
        );
      case DistributorsTabChoice.NESTED_VIEW:
        return <DistributorHierarchy />;
      default:
        return null;
    }
  };

  return (
    <>
      <Seo title={t("distributors.navigationTitle")} />
      <Box display="flex" flexDirection="column" flexGrow={1} {...props}>
        <LayoutContentHeader
          headerTitle={t("distributors.navigationTitle")}
          headerWidget={<CurrencySelection />}
        />
        <Tabs
          value={selectedTab}
          onChange={(_event, newValue) => setSelectedTab(newValue)}
        >
          <Tab value="overview" label={t("distributors.overview")} />

          <Tab
            value="nested_view"
            label={t("distributors.distributionStructure")}
          />
        </Tabs>

        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          className={classes.content}
        >
          {renderSelectedTab()}
        </Box>
      </Box>
    </>
  );
};
