import { Box, Card, Skeleton, useTheme } from "@mui/material";

export const KeyFiguresCardSkeleton = ({ ...props }) => {
  const theme = useTheme();

  return (
    <Card {...props} sx={{ padding: theme.spacing(2) }}>
      <Box>
        <Box>
          <Box>
            <Skeleton variant="text" sx={{ fontSize: "1.25rem" }} width="25%" />
          </Box>

          <Box>
            <Skeleton variant="text" sx={{ fontSize: "2rem" }} width="50%" />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default KeyFiguresCardSkeleton;
