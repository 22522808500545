import type { FC, ReactNode } from "react";
import { Fragment } from "react";
import {
  Card,
  CardContent,
  CardProps,
  Divider,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useStyles } from "./TwoSectionsCard.styles";

type SectionDetails = {
  label: string;
  value: string | number | undefined | ReactNode;
};

interface TwoSectionsCardProps extends CardProps {
  title: string;
  headerSectionDetails?: SectionDetails[];
  leftSectionDetails?: SectionDetails[];
  rightSectionDetails?: SectionDetails[];
}

export const TwoSectionsCard: FC<TwoSectionsCardProps> = ({
  title,
  headerSectionDetails,
  leftSectionDetails,
  rightSectionDetails,
  ...props
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  return (
    <Card className={classes.root} {...props}>
      <Typography variant="h1" color="textColorDark" sx={{ pl: 2, py: 3 }}>
        {title}
      </Typography>

      <CardContent>
        {headerSectionDetails && (
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{
              borderTop: `1px solid ${theme.palette.secondary.main}`,
              pt: theme.spacing(2),
            }}
          >
            {headerSectionDetails?.map((detail) => (
              <Grid item xs={12} lg={5} key={`first-${detail.label}`}>
                <Grid container spacing={2}>
                  <Grid item xs={7} md={7}>
                    <Typography variant="subtitle2">
                      {detail.label}
                      {":"}
                    </Typography>
                  </Grid>

                  <Grid item xs={5} md={5}>
                    <Typography
                      color="textColorDark"
                      data-testid={`${detail.label}-value`}
                    >
                      {detail.value || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}

        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          sx={{
            borderTop: `1px solid ${theme.palette.secondary.main}`,
            mt: headerSectionDetails && theme.spacing(2),
          }}
        >
          <Grid item xs={12} lg={5}>
            <Grid container spacing={2}>
              {leftSectionDetails?.map((detail) => (
                <Fragment key={`first-${detail.label}`}>
                  <Grid item xs={7} md={7}>
                    <Typography variant="subtitle2">
                      {detail.label}
                      {":"}
                    </Typography>
                  </Grid>

                  <Grid item xs={5} md={5}>
                    <Typography
                      color="textColorDark"
                      data-testid={`${detail.label}-value`}
                    >
                      {detail.value}
                    </Typography>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} lg={1} justifyContent="center" display="flex">
            <Divider
              orientation={lgUp ? "vertical" : "horizontal"}
              sx={{
                borderColor: theme.palette.secondary.main,
                width: !lgUp ? "100%" : "auto",
              }}
            />
          </Grid>

          <Grid item xs={12} lg={5}>
            <Grid container spacing={2}>
              {rightSectionDetails?.map((detail) => (
                <Fragment key={`second-${detail.label}`}>
                  <Grid item xs={7} md={7}>
                    <Typography variant="subtitle2">
                      {detail.label}
                      {":"}
                    </Typography>
                  </Grid>

                  <Grid item xs={5} md={5}>
                    <Typography
                      color="textColorDark"
                      data-testid={`${detail.label}-value`}
                    >
                      {detail.value}
                    </Typography>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TwoSectionsCard;
