/* eslint-disable sort-keys-fix/sort-keys-fix */
import { alpha } from "@mui/material";
import { common } from "@mui/material/colors";
import type {
  ColorRange,
  PaletteColor,
} from "@mui/material/styles/createPalette";

const withAlphas = (color: PaletteColor): PaletteColor => ({
  ...color,
  alpha12: alpha(color.main, 0.12),
  alpha30: alpha(color.main, 0.3),
  alpha4: alpha(color.main, 0.04),
  alpha50: alpha(color.main, 0.5),
  alpha8: alpha(color.main, 0.08),
});

export const textColorLight = "#6e6d6d";
export const textColorDark = "#333436";

// GoldenGates Primary
export const gold: ColorRange = {
  50: "#F7F3EC",
  100: "#ECE1CE",
  200: "#E0CDAE",
  300: "#D3B88E",
  400: "#C9A975",
  500: "#C09A5D",
  600: "#BA9255",
  700: "#B2884B",
  800: "#AA7E41",
  900: "#9C6C30",
};

// GoldenGates Secondary
export const beige: ColorRange = {
  50: "#FDFBF9",
  100: "#F9F6F1",
  200: "#F6F0E7",
  300: "#F2EADD",
  400: "#EFE6D6",
  500: "#ECE1CF",
  600: "#EADDCA",
  700: "#E7D9C3",
  800: "#E4D5BD",
  900: "#DFCDB2",
};

export const brown: ColorRange = {
  50: "#F1EEEB",
  100: "#DBD5CE",
  200: "#C3B9AD",
  300: "#AB9C8C",
  400: "#998774",
  500: "#87725B",
  600: "#7F6A53",
  700: "#745F49",
  800: "#6A5540",
  900: "#57422F",
};

export const darkBrown: ColorRange = {
  50: "#EDEBEA",
  100: "#D1CDCA",
  200: "#B2ABA7",
  300: "#938983",
  400: "#7C7069",
  500: "#66574e",
  600: "#5D4F47",
  700: "#53463D",
  800: "#53463D",
  900: "#372C25",
};

export const neutral: ColorRange = {
  50: "#E7E7E7",
  100: "#C2C2C3",
  200: "#999A9B",
  300: "#707172",
  400: "#525254",
  500: "#333436",
  600: "#2E2F30",
  700: "#272729",
  800: "#202122",
  900: "#141516",
};

export const red: ColorRange = {
  50: "#FCEDEB",
  100: "#F8D1CE",
  200: "#F3B2AE",
  300: "#EE938D",
  400: "#EA7C74",
  500: "#E6655C",
  600: "#E35D54",
  700: "#DF534A",
  800: "#DB4941",
  900: "#D53730",
};

export const green: ColorRange = {
  50: "#ECF3EB",
  100: "#CEE1CD",
  200: "#AECEAC",
  300: "#8EBA8B",
  400: "#75AB72",
  500: "#5D9C59",
  600: "#559451",
  700: "#4B8A48",
  800: "#41803E",
  900: "#306E2E",
};

export const yellow: ColorRange = {
  50: "#FDF7E0",
  100: "#FBEBB3",
  200: "#F8DE80",
  300: "#F5D04D",
  400: "#F3C626",
  500: "#F1BC00",
  600: "#EFB600",
  700: "#EDAD00",
  800: "#EBA500",
  900: "#EBA500",
};

export const blue: ColorRange = {
  50: "#FBFCFE",
  100: "#F4F8FC",
  200: "#EDF4FA",
  300: "#E6EFF7",
  400: "#E0EBF6",
  500: "#DBE8F4",
  600: "#D7E5F3",
  700: "#D2E2F1",
  800: "#CDDEEF",
  900: "#C4D8EC",
};

export const primary = withAlphas({
  light: gold[300],
  lightest: gold[400],
  main: gold[500],
  dark: gold[600],
  darkest: gold[700],
  contrastText: common.white,
});

export const secondary = withAlphas({
  light: beige[300],
  lightest: beige[400],
  main: beige[500],
  dark: beige[600],
  darkest: beige[700],
  contrastText: textColorDark,
});

export const ternary = withAlphas({
  light: brown[300],
  lightest: brown[400],
  main: brown[500],
  dark: brown[600],
  darkest: brown[700],
  contrastText: common.white,
});

export const quartaer = withAlphas({
  light: darkBrown[300],
  lightest: darkBrown[400],
  main: darkBrown[500],
  dark: darkBrown[600],
  darkest: darkBrown[700],
  contrastText: common.white,
});

export const success = withAlphas({
  light: green[300],
  lightest: green[400],
  main: green[500],
  dark: green[600],
  darkest: green[700],
  contrastText: textColorDark,
});

export const error = withAlphas({
  light: red[300],
  lightest: red[400],
  main: red[500],
  dark: red[600],
  darkest: red[700],
  contrastText: common.white,
});

export const warning = withAlphas({
  light: yellow[300],
  lightest: yellow[400],
  main: yellow[500],
  dark: yellow[600],
  darkest: yellow[700],
  contrastText: textColorDark,
});

export const info = withAlphas({
  light: blue[300],
  lightest: blue[400],
  main: blue[500],
  dark: blue[600],
  darkest: blue[700],
  contrastText: textColorDark,
});

export const gradients = {
  light: "linear-gradient(294deg, #FDFBF9 0%, #F6F0E7 100%)",
  dark: "linear-gradient(294deg, #4F4741 0%, #6A5F53 100%)",
  panel: "linear-gradient(294deg, #ECE1CF 0%, #DFCDB2 100%)",
};
