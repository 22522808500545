import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import deTranslation from "./de/translation.json";
import enTranslation from "./en/translation.json";
import huTranslation from "./hu/translation.json";
import ltTranslation from "./lt/translation.json";
import plTranslation from "./pl/translation.json";
import skTranslation from "./sk/translation.json";

i18n.use(initReactI18next).init({
  fallbackLng: "de",

  interpolation: {
    escapeValue: false,
  },

  resources: {
    de: {
      translation: deTranslation,
    },
    en: {
      translation: enTranslation,
    },
    hu: {
      translation: huTranslation,
    },
    lt: {
      translation: ltTranslation,
    },
    pl: {
      translation: plTranslation,
    },
    sk: {
      translation: skTranslation,
    },
  },

  returnEmptyString: false,
});

export default i18n;
