import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
  formControl: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    m: 1,
  },

  select: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[10],
    color: theme.palette.textColorDark,
    fieldset: {
      border: "none",
    },
    fontWeight: "bold",
    svg: {
      color: theme.palette.primary.main,
    },
  },
}));
