import {
  Box,
  BoxProps,
  Card,
  CardContent,
  Container,
  Typography,
  useTheme,
} from "@mui/material";

import GgLogo from "src/components/Icons/GgLogo";

import { useStyles } from "./PublicLayout.styles";

interface PublicLayoutProps extends BoxProps {
  title: string;
}

/*
  A layout component that is used for public pages (e.g. login form).
  It displays a centered card on a background image.
*/

export const PublicLayout = ({
  title,
  children,
  ...restProps
}: PublicLayoutProps) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();

  return (
    <Box className={cx(classes.wrapper)} {...restProps}>
      <Container maxWidth="sm" className={cx(classes.box)}>
        <GgLogo
          height={100}
          width={200}
          fillColor={theme.palette.quartaer.main}
        />

        <Card elevation={24} className={cx(classes.card)}>
          <Typography
            align="center"
            color={theme.palette.common.white}
            fontFamily="Lora"
            py={2}
            variant="h1"
          >
            {title}
          </Typography>

          <CardContent>{children}</CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default PublicLayout;
