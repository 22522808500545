import type { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Card,
  CardProps,
  Chip,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { Contract, ContractPhase } from "src/types/contract";
import { getContractStatusColor } from "src/utils/contract";

import { useStyles } from "./Customer.ContractCard.styles";

interface ContractCardProps extends CardProps {
  contract: Contract;
  onClick: () => void;
}

export const ContractCard: FC<ContractCardProps> = ({
  contract,
  onClick,
  ...props
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const lgUp = useMediaQuery(() => theme.breakpoints.up("lg"));

  const {
    phase,
    contractNumber,
    currency,
    productLabel,
    status,
    totalDeposits,
    totalPurchaseAmount,
    lastDepositMoneyAmount,
    lastDepositValueDate,
    totalPaidFees,
  } = contract;

  const phaseLabel =
    phase in ContractPhase && t(`contracts.contractPhase.${phase}`);
  const chipColor = getContractStatusColor(status, theme);

  return (
    <Card elevation={4} className={classes.root} onClick={onClick} {...props}>
      <Box>
        <Typography color={theme.palette.ternary.main} fontWeight="bold">
          {contractNumber}
        </Typography>

        <Typography
          color="textColorDark"
          fontWeight="bold"
          variant="h3"
          sx={{ mb: lgUp ? 0 : 2 }}
        >
          {productLabel}
        </Typography>

        {phaseLabel && (
          <Chip
            label={phaseLabel}
            size="small"
            sx={{ backgroundColor: chipColor[50], color: chipColor[500] }}
            className={classes.statusChip}
          />
        )}
      </Box>

      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        alignContent="center"
        maxWidth={lgUp ? "70%" : "100%"}
      >
        <Grid item xs={6} md={6} lg={3}>
          <Typography sx={{ mb: 0.5 }}>
            {t("contracts.totalPurchaseAmountLabel")}
          </Typography>

          <Typography color="textColorDark">
            {t("common.currency", {
              formatParams: { value: { currency: currency } },
              value: totalPurchaseAmount,
            })}
          </Typography>
        </Grid>

        <Grid item xs={6} md={6} lg={3}>
          <Typography sx={{ mb: 0.5 }}>
            {t("contracts.lastDepositLabel")}
          </Typography>

          {lastDepositValueDate && (
            <Typography color="textColorDark">
              {t("common.currency", {
                formatParams: { value: { currency: currency } },
                value: lastDepositMoneyAmount,
              })}{" "}
              <Typography component="span" color="textColorLight">
                ({lastDepositValueDate})
              </Typography>
            </Typography>
          )}

          {!lastDepositValueDate && "-"}
        </Grid>

        <Grid item xs={6} md={6} lg={3}>
          <Typography sx={{ mb: 0.5 }}>
            {t("contracts.totalPaidInLabel")}
          </Typography>

          <Typography color="textColorDark">
            {t("common.currency", {
              formatParams: { value: { currency: currency } },
              value: totalDeposits,
            })}
          </Typography>
        </Grid>

        <Grid item xs={6} md={6} lg={3}>
          <Typography sx={{ mb: 0.5 }}>
            {t("contracts.totalPaidFeesLabel")}
          </Typography>

          <Typography color="textColorDark">
            {t("common.currency", {
              formatParams: { value: { currency: currency } },
              value: totalPaidFees,
            })}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};
