import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
  root: {
    backgroundImage: theme.palette.gradients.dark,
    height: 64,
    position: "sticky",
    top: 0,
    zIndex: 9999,
  },
}));
