import { ColorRange } from "@mui/material/styles/createPalette";

export enum CellType {
  TITLE,
  TEXT,
  MONEY_RATIO,
  TRANSACTION,
  CHIP,
  PROGRESS,
  TREND,
}

export type TextCell = {
  cellType: CellType.TEXT;
  text: string;
};

export type TitleCell = {
  cellType: CellType.TITLE;
  title: string;
  subtitle?: string;
};

export type MoneyRatioCell = {
  cellType: CellType.MONEY_RATIO;
  currency: string;
  currentAmount: number;
  targetAmount: number;
};

export type TransactionCell = {
  cellType: CellType.TRANSACTION;
  currency?: string;
  amount: number | null | undefined;
  date?: string;
};

export type ChipCell = {
  cellType: CellType.CHIP;
  color: ColorRange;
  text: string | false;
};

export type ProgressCell = {
  cellType: CellType.PROGRESS;
  progress: number;
};

export type TrendCell = {
  cellType: CellType.TREND;
  trendIndicator: "up" | "down" | "equal";
};

export type Cell =
  | TitleCell
  | TextCell
  | MoneyRatioCell
  | TransactionCell
  | ChipCell
  | ProgressCell
  | TrendCell;

export type Row = {
  id: number | string;
  cells: Cell[];
};

export type OrderDirection = "asc" | "desc";

export interface TableHeader {
  id: string;
  label: string;
  width: number;
  tooltip?: string;
}

export interface TableOrder {
  orderDirection: OrderDirection;
  orderBy: string | undefined;
}
