import { Fragment } from "react";
import { Grid, Skeleton } from "@mui/material";

export const CommodityCardSkeleton = () => (
  <Grid container spacing={3} sx={{ p: 2 }}>
    {Array(4)
      .fill(0)
      .map((_, index) => (
        <Fragment key={index}>
          <Grid item xs={6}>
            <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} width="80%" />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} width="40%" />
          </Grid>

          <Grid item xs={4} display="flex" justifyContent="end">
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} width="40%" />
          </Grid>
        </Fragment>
      ))}
  </Grid>
);

export default CommodityCardSkeleton;
