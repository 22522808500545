import { useQuery } from "@tanstack/react-query";

import { distributorCommoditiesRequest } from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";

import { commodityKeys } from "./key";

interface CommoditiesQueryProps extends DefaultQueryProps {
  customerNumber?: string;
  contractNumber?: number;
  distributorId: number | undefined;
  includeNested: boolean;
}

export const useCommodityListForDistributorQuery = ({
  customerNumber,
  contractNumber,
  distributorId,
  includeNested,
  ...props
}: CommoditiesQueryProps) =>
  useQuery({
    queryFn: () =>
      distributorCommoditiesRequest({
        contractNumber,
        customerNumber,
        distributorId,
        includeNested,
      }),
    queryKey: [
      commodityKeys.distributorsCommodities,
      contractNumber,
      customerNumber,
      distributorId,
      includeNested,
    ],
    refetchIntervalInBackground: true,
    ...props,
  });
