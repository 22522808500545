import { configuredLocales } from "src/constants";
import i18n from "src/locales/i18nConfig";

// Commodities (Bestände) should be formatted to always have 4 digits
// e.g. "44,0000 g"
export const formatCommodityAmount = (amount: number | undefined) =>
  amount !== undefined &&
  // DE formats dot to comma
  amount.toLocaleString(configuredLocales, {
    maximumFractionDigits: 4,
    minimumFractionDigits: 4,
  });

/*
  Returns a formatted currency string
  dependent on the currency code and a value as input
*/
export const formatCurrencyNumber = (
  currencyNumber: number | undefined,
  currencyCode?: string,
): string => {
  if (currencyNumber === undefined) {
    return "-";
  }

  return i18n.t("common.currency", {
    formatParams: { value: { currency: currencyCode || "EUR" } },
    value: currencyNumber,
  });
};

// The distributor contact only has a label which can be one of two:
// "GoldenGates" or "Mustermann, Max"
// It can be a name if it has a comma, which should be reversed and the comma removed
// otherwise it is a company name
// see issue #69 for details
export const formatDistributorLabel = (label: string) => {
  if (label.indexOf(",") === -1) {
    return label;
  }
  return label.split(",").reverse().join().replace(",", " ").trimStart();
};

// Get initials for a name. E.g. "Max Storch -> MS"
export const getInitials = (name: string) => {
  const names = name.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};
