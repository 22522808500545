import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import PublicLayout from "src/layouts/PublicLayout/PublicLayout";
import SetPasswordForm from "src/components/Forms/SetPasswordForm/SetPasswordForm";
import { Seo } from "src/components/Seo";

export const SetPasswordPage = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const temporaryToken = useMemo(
    () => searchParams.get("token") || "",
    [searchParams],
  );

  return (
    <>
      <Seo title={t("setPassword")} />
      <PublicLayout title={t("setPassword")}>
        <SetPasswordForm temporaryToken={temporaryToken} />
      </PublicLayout>
    </>
  );
};

export default SetPasswordPage;
