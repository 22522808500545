import i18n from "i18next";
import * as Yup from "yup";

export const ValidationRules = {
  // The Email Validation needs another RegEx. https://github.com/jquense/yup/issues/507
  email: () =>
    Yup.string()
      .email(i18n.t("auth.validation.email.invalid"))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        i18n.t("auth.validation.email.invalid"),
      )
      .max(255)
      .required(i18n.t("auth.validation.email.required")),
  password: () =>
    Yup.string().max(255).required(i18n.t("auth.validation.password.required")),
};
