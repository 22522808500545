import { QueryClient } from "@tanstack/react-query";

import { CursorPaginatedResponse } from "src/services/api/api";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 1000 * 60 * 30, // Every 30 minutes
      retry: 2,
      staleTime: 1000 * 60 * 5, // Every 5 minutes
    },
  },
});

export interface PaginatedQueryCache<T> {
  pageParams: (number | string)[];
  pages: CursorPaginatedResponse<T>[];
}

export interface DefaultQueryProps {
  refetchInterval?: number;
  staleTime?: number;
}
