import { useInfiniteQuery } from "@tanstack/react-query";

import { ContractBooking } from "src/types/contract";
import {
  contractsBookingsForContractRequest,
  CursorPaginatedResponse,
} from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";

import { contractBookingKeys } from "./key";

interface ContractsBookingListForContractQueryProps extends DefaultQueryProps {
  contractNumber: string;
  customerNumber: number | undefined;
  filterContractBookingsBy: string;
}

export const useInfiniteContractsBookingListForContractForCustomerQuery = ({
  contractNumber,
  customerNumber,
  filterContractBookingsBy,
  ...props
}: ContractsBookingListForContractQueryProps) =>
  useInfiniteQuery<CursorPaginatedResponse<ContractBooking>, Error>({
    queryFn: ({ pageParam }) =>
      contractsBookingsForContractRequest({
        contractNumber,
        customerNumber,
        filterContractBookingsBy,
        includeNested: false, // A customer is never a distribution company, so always "false".
        pageParam: String(pageParam),
      }),
    queryKey: [
      contractBookingKeys.contractsBookingList,
      contractNumber,
      filterContractBookingsBy,
    ],
    refetchIntervalInBackground: true,
    ...props,
    getNextPageParam: (lastPage) => lastPage.next ?? undefined,
    getPreviousPageParam: (firstPage) => firstPage.next ?? undefined,
    initialPageParam: 0,
  });
