import { type FC, Fragment } from "react";
import { Grid, Skeleton } from "@mui/material";

export const ContractSummarySkeleton: FC = () => (
  <Grid container spacing={3} sx={{ p: 2 }}>
    {Array(6)
      .fill(0)
      .map((_, index) => (
        <Fragment key={index}>
          <Grid item xs={7}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} width="70%" />
          </Grid>

          <Grid item xs={5}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} width="30%" />
          </Grid>
        </Fragment>
      ))}
  </Grid>
);

export default ContractSummarySkeleton;
