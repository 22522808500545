import { useQuery } from "@tanstack/react-query";

import { commoditiesForContractRequest } from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";

import { commodityKeys } from "./key";

interface CommoditiesQueryProps extends DefaultQueryProps {
  contractNumber: string;
  customerNumber: number | undefined;
}

export const useCommodityListForContractForCustomerQuery = ({
  contractNumber,
  customerNumber,
  ...props
}: CommoditiesQueryProps) =>
  useQuery({
    queryFn: () =>
      commoditiesForContractRequest({ contractNumber, customerNumber }),
    queryKey: [commodityKeys.commodities, contractNumber],
    refetchIntervalInBackground: true,
    ...props,
  });
