import { type FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Grid, GridProps, Typography } from "@mui/material";

import { euroValueCurrency } from "src/constants";
import { Commodity, Contract } from "src/types/contract";
import { calculateCurrentContractStockPriceValue } from "src/utils/calculateCommodityValue";
import { calculateContractSum } from "src/utils/contract";
import { formatCurrencyNumber } from "src/utils/formatTexts";
import { useStockPriceListQuery } from "src/services/serverState/stockPrices/useStockPriceListQuery";
import InfoTooltip from "src/components/InfoTooltip/InfoTooltip";

import ContractSummarySkeleton from "./ContractSummary.skeleton";

interface ContractSummaryProps extends GridProps {
  contracts: Contract[] | undefined;
  commodities: Commodity[] | undefined;
  isLoading: boolean;
}

export const ContractSummary: FC<ContractSummaryProps> = ({
  contracts,
  commodities,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation();
  const currency = contracts && contracts[0] ? contracts[0].currency : "";

  // Get stockPriceData
  const { data: stockPrices } = useStockPriceListQuery({});

  const currentContractStockPriceValue =
    calculateCurrentContractStockPriceValue({
      commodities: commodities || [],
      stockPriceList: stockPrices || [],
    });

  const contractSummaryData = [
    {
      // Anzahl der abgeschlossenen Verträge
      label: t("dashboard.widgets.contractSummary.contractsCount"),
      value: contracts?.length,
    },
    {
      // Auftragssumme
      label: t("dashboard.widgets.contractSummary.sum"),
      value: formatCurrencyNumber(
        calculateContractSum({
          contracts: contracts,
          value: "orderTotal",
        }),
        currency,
      ),
    },
    {
      // Monatsbeiträge
      label: t("dashboard.widgets.contractSummary.monthlyPaid"),
      value: formatCurrencyNumber(
        calculateContractSum({
          contracts: contracts,
          value: "contributionAmount",
        }),
        currency,
      ),
    },
    {
      // Einzahlungen
      label: t("dashboard.widgets.contractSummary.paidIn"),
      value: formatCurrencyNumber(
        calculateContractSum({
          contracts: contracts,
          value: "totalDeposits",
        }),
        currency,
      ),
    },
    {
      // Bisherige Kaufsumme
      label: t("dashboard.widgets.contractSummary.previousPurchasePrice"),
      value: formatCurrencyNumber(
        calculateContractSum({
          contracts: contracts,
          value: "totalPurchaseAmount",
        }),
        currency,
      ),
    },
    {
      // Kaufgebühren
      label: t("dashboard.widgets.contractSummary.PaymentFees"),
      value: formatCurrencyNumber(
        calculateContractSum({
          contracts: contracts,
          value: "contractFees",
        }),
        currency,
      ),
    },
    {
      // Eingezahlte Kaufgebühren
      label: t("dashboard.widgets.contractSummary.purchaseFeesPaid"),
      value: formatCurrencyNumber(
        calculateContractSum({
          contracts: contracts,
          value: "totalPaidFees",
        }),
        currency,
      ),
    },
    // "aktueller Wert" only in EUR
    {
      // Wert aller Bestände
      label: t("contracts.contractDetails.labels.totalCommodityValueLabel"),
      value: (
        <>
          {formatCurrencyNumber(
            currentContractStockPriceValue,
            euroValueCurrency,
          )}
          <InfoTooltip infoText={t("common.totalCommodityValueHelpText")} />
        </>
      ),
    },
  ];

  if (isLoading) {
    return <ContractSummarySkeleton />;
  }

  return (
    <Grid container spacing={3} {...props}>
      {contractSummaryData.map((data) => (
        <Fragment key={data.label}>
          <Grid item xs={8} md={7}>
            <Typography>{data.label}:</Typography>
          </Grid>

          <Grid item xs={4} md={5}>
            <Typography color="textColorDark">{data.value}</Typography>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
};

export default ContractSummary;
