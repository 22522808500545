import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
  bookingArrow: {
    backgroundColor: theme.palette.neutral[50],
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    marginRight: 16,
    padding: 8,
  },

  bookingArrowGreen: {
    backgroundColor: theme.palette.green[50],
  },

  bookingArrowRed: {
    backgroundColor: theme.palette.red[50],
  },

  bookingTextGreen: {
    color: theme.palette.success.main,
  },

  bookingTextRed: {
    color: theme.palette.error.main,
  },

  scrollable: {
    maxHeight: 400,
    overflow: "auto",
  },
}));
