import { useQuery } from "@tanstack/react-query";

import { stockPurchasePriceRequest } from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";

import { stockPricesKeys } from "./key";

interface QueryProps extends DefaultQueryProps {}

export const useStockPriceListQuery = ({ ...props }: QueryProps) =>
  useQuery({
    queryFn: () => stockPurchasePriceRequest(),
    queryKey: [stockPricesKeys.purchaseStockPriceList],
    refetchInterval: 1000 * 60 * 5, // Refetch stock prices every 5 minutes
    refetchIntervalInBackground: true,
    ...props,
  });
