import { type FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import { DistributionNumberWithProfile } from "src/types/distributor";
import { formatCurrencyNumber, getInitials } from "src/utils/formatTexts";
import {
  CursorPaginatedResponse,
  distributionNumbersChildrenRequest,
  distributionNumbersForProfileRequest,
} from "src/services/api/api";
import { useUser } from "src/services/contexts/userContext";
import DataTableSkeleton from "src/components/DataTable/DataTable.skeleton";
import LinearProgressWithLabel from "src/components/LinearProgressWithLabel/LinearProgressWithLabel";
import NoData from "src/components/NoData/NoData";

import { useStyles } from "./DistributorHierarchy.styles";

interface DistributorHierarchyProps {}

interface DistributorRow extends DistributionNumberWithProfile {
  id: string;
  depth: number;
}

/*
  ShowMoreRow can have distributorProfile (toplevel)
  or distribution numbers as source (nested)
*/
interface ShowMoreRow {
  depth: number;
  id: string;
  distributionNumberToFetch?: string;
  distributorProfileToFetch?: number;
  cursor: string;
}

type TableRow = DistributorRow | ShowMoreRow;

function isDistributorRow(row: TableRow): row is DistributorRow {
  return (row as DistributorRow).distributionNumber !== undefined;
}

function isShowMoreRow(row: TableRow): row is ShowMoreRow {
  return (row as ShowMoreRow).cursor !== undefined;
}

const DistributorHierarchy: FC<DistributorHierarchyProps> = () => {
  const theme = useTheme();
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const { userState } = useUser();
  const selectedCurrency = userState.selectedCurrency;
  const [isLoading, setIsLoading] = useState(true);
  const distributorId = userState.user?.activeDistributorProfile?.distributorId;

  const headers = [
    {
      id: "name",
      label: t("distributors.tableHeaders.name"),
    },
    {
      id: "feesTotal",
      label: t("distributors.tableHeaders.feesTotal"),
      width: 200,
    },
    {
      id: "orderTotal",
      label: t("distributors.tableHeaders.orderTotal"),
      width: 200,
    },
    {
      id: "depositRate",
      label: "Einzahlungsrate",
      width: 150,
    },
  ];

  const [rows, setRows] = useState<TableRow[]>([]);

  useEffect(() => {
    if (!distributorId) {
      return;
    }
    fetchTopLevel(distributorId, selectedCurrency);
  }, [distributorId, selectedCurrency]);

  // fetches the data for the distributionNumbers of the active distributor profile
  // to render the top level of the tree
  const fetchTopLevel = async (distributorId: number, currency: string) => {
    setIsLoading(true);
    const data = await distributionNumbersForProfileRequest({
      currency,
      distributorId,
    });
    setIsLoading(false);

    const newRows: TableRow[] = data.results.map((item) => ({
      depth: 0,
      id: item.distributionNumber,
      ...item,
    }));

    // add new showMoreRow at the end of current parent's children if needed
    if (data.next) {
      newRows.push({
        cursor: data.next,
        depth: 0,
        distributorProfileToFetch: distributorId,
        id: distributorId + "/" + data.next,
      });
    }

    setRows(newRows);
  };

  // fetches the first chunk of direct children for one distributionNumber
  const fetchChildren = async (row: DistributorRow, rows: TableRow[]) => {
    const { distributionNumber, depth } = row;
    const data = await distributionNumbersChildrenRequest({
      currency: selectedCurrency,
      distributionNumber,
    });

    // find the distribution number in the rows and append the children with +1 depth
    const newRows: DistributorRow[] = data.results.map((item) => ({
      depth: depth + 1,
      id: item.distributionNumber,
      ...item,
    }));

    const rowIndex = rows.indexOf(row);

    let updatedRows = [
      ...rows.slice(0, rowIndex + 1),
      ...newRows,
      ...rows.slice(rowIndex + 1),
    ];

    // add new showMoreRow at the end of current parent's children if needed
    if (data.next) {
      const lastNewRow = newRows[newRows.length - 1];
      const indexToInsert = updatedRows.indexOf(lastNewRow);

      updatedRows = [
        ...updatedRows.slice(0, indexToInsert + 1),
        {
          cursor: data.next,
          depth: depth + 1,
          distributionNumberToFetch: row.distributionNumber,
          id: row.distributionNumber + "/" + data.next,
        },
        ...updatedRows.slice(indexToInsert + 1),
      ];
    }

    setRows(updatedRows);
  };

  const addSiblings = async ({
    row,
    rows,
    data,
  }: {
    row: ShowMoreRow;
    rows: TableRow[];
    data: CursorPaginatedResponse<DistributionNumberWithProfile>;
  }) => {
    const { depth, distributionNumberToFetch, distributorProfileToFetch } = row;
    const newRows = data.results.map((item) => ({
      ...item,
      depth: row.depth,
      id: item.distributionNumber,
    }));

    const rowIndex = rows.indexOf(row);

    // append the results
    let updatedRows = [
      ...rows.slice(0, rowIndex),
      ...newRows,
      ...rows.slice(rowIndex + 1),
    ];

    // add new showMoreRow at the end of current parent's children if needed
    if (data.next) {
      const lastNewRow = newRows[newRows.length - 1];
      const indexToInsert = updatedRows.indexOf(lastNewRow);

      updatedRows = [
        ...updatedRows.slice(0, indexToInsert + 1),
        {
          cursor: data.next,
          depth: depth,
          distributionNumberToFetch,
          distributorProfileToFetch,
          id:
            distributionNumberToFetch ||
            distributorProfileToFetch + "/" + data.next,
        },
        ...updatedRows.slice(indexToInsert + 1),
      ];
    }

    setRows(updatedRows);
  };

  const fetchMoreSiblingsNested = async (
    row: ShowMoreRow,
    rows: TableRow[],
  ) => {
    const { distributionNumberToFetch, cursor } = row;
    const data = await distributionNumbersChildrenRequest({
      currency: selectedCurrency,
      cursor,
      distributionNumber: distributionNumberToFetch || "",
    });

    addSiblings({
      data,
      row,
      rows,
    });
  };

  const fetchMoreSiblingsTopLevel = async (
    row: ShowMoreRow,
    rows: TableRow[],
  ) => {
    const { distributorProfileToFetch, cursor } = row;
    const data = await distributionNumbersForProfileRequest({
      currency: selectedCurrency,
      cursor,
      distributorId: distributorProfileToFetch || 0,
    });

    addSiblings({
      data,
      row,
      rows,
    });
  };

  const removeChildren = (row: DistributorRow) => {
    // If row is currently expanded (following row has higher depth) remove all nested rows (collapse)
    // until next element with same depth (sibling) or lower depth is found
    const rowIndex = rows.indexOf(row);
    const nextSibling = rows
      .slice(rowIndex + 1)
      .find(({ depth }) => depth <= row.depth);
    const nextSiblingIndex = nextSibling
      ? rows.indexOf(nextSibling)
      : rows.length;
    setRows([...rows.slice(0, rowIndex + 1), ...rows.slice(nextSiblingIndex)]);
  };

  const handleRowClick = (row: DistributorRow) => {
    // --- Case 1: collapsing ---
    const rowIndex = rows.indexOf(row);
    // If row is currently expanded (following row has higher depth) remove all nested rows (collapse)
    // until next element with same depth (sibling) or lower depth is found
    if (rows[rowIndex + 1] && rows[rowIndex + 1].depth > row.depth) {
      removeChildren(row);
      return;
    }

    // --- Case 2: fetching children ---
    // If row is currently not expanded -> fetch direct children and append
    // collapse all siblings -> remove elements with higher depth from the list

    const filteredRows = rows.filter(({ depth }) => depth <= row.depth);
    fetchChildren(row, filteredRows);
  };

  const handleLoadMore = (row: ShowMoreRow) => {
    if (row.distributionNumberToFetch) {
      fetchMoreSiblingsNested(row, rows);
    }
    if (row.distributorProfileToFetch) {
      fetchMoreSiblingsTopLevel(row, rows);
    }
  };

  const childrenExist = (rows: TableRow[], index: number) =>
    rows.length > index + 1 && rows[index + 1].depth > rows[index].depth;

  const renderRow = (row: TableRow, index: number) => {
    if (isDistributorRow(row)) {
      return (
        <TableRow
          key={row.id + index}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
            background: childrenExist(rows, index)
              ? theme.palette.secondary.light
              : undefined,
            boxShadow: childrenExist(rows, index) ? 4 : 0,
          }}
          className={classes.tableRow}
          onClick={() =>
            row.hasChildren && handleRowClick && handleRowClick(row)
          }
        >
          <TableCell
            sx={{
              paddingLeft: 2 + 2 * row.depth,
              position: "relative",
              whiteSpace: "nowrap",
            }}
            className={cx([classes.overflow, classes.nameCell])}
            align="left"
          >
            {row.depth > 0 && (
              <Divider
                orientation="vertical"
                sx={{
                  left: 16 * row.depth,
                  position: "absolute",
                  width: 0,
                }}
              />
            )}

            <Icon sx={{ opacity: row.hasChildren ? 1 : 0 }}>
              {childrenExist(rows, index)
                ? "keyboard_arrow_down"
                : "keyboard_arrow_right"}
            </Icon>

            <Avatar
              variant="rounded"
              className={classes.avatar}
              sx={{
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
              }}
            >
              {getInitials(row.distributorProfile?.distributorLabel || "")}
            </Avatar>

            <Box sx={{ flexDirection: "column" }}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                mb={0.5}
              >
                {row.distributorProfile?.distributorLabel}
              </Typography>

              <Typography variant="body2">{row.distributionNumber}</Typography>
            </Box>
          </TableCell>

          <TableCell align="right" sx={{ textAlign: "left" }}>
            <Typography>
              {formatCurrencyNumber(row.sumContractFees, selectedCurrency)}
            </Typography>
          </TableCell>

          <TableCell align="right" sx={{ textAlign: "left" }}>
            <Typography>
              {formatCurrencyNumber(row.sumOrderTotal, selectedCurrency)}
            </Typography>
          </TableCell>

          <TableCell align="right" sx={{ alignItems: "right" }}>
            <LinearProgressWithLabel value={row.depositRate} />
          </TableCell>
        </TableRow>
      );
    }
    if (isShowMoreRow(row)) {
      return (
        <TableRow key={row.id}>
          <TableCell
            sx={{
              paddingLeft: 2 + 2 * row.depth,
              position: "relative",
            }}
          >
            {row.depth > 0 && (
              <Divider
                orientation="vertical"
                sx={{
                  left: 16 * row.depth,
                }}
                className={classes.divider}
              />
            )}
            <Button
              sx={{ margin: 0, padding: 2 }}
              variant="outlined"
              fullWidth
              onClick={() => handleLoadMore(row)}
            >
              {t("common.showMore")}
            </Button>
          </TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      );
    }
  };

  return (
    <Card>
      <TableContainer style={{ minHeight: 500 }}>
        <Table stickyHeader>
          <TableHead
            sx={{
              backgroundColor: theme.palette.grey[50],
              textTransform: "capitalize",
            }}
          >
            <TableRow sx={{ textTransform: "uppercase" }}>
              {headers.map(({ id, label, width }, index) => (
                <TableCell
                  size="small"
                  key={id}
                  width={index === 0 ? undefined : width}
                  sx={{
                    fontWeight: "bold",
                    maxWidth: index === 0 ? undefined : `${width}px`,
                    minWidth: index === 0 ? undefined : `${width}px`,
                    textAlign: "left",
                  }}
                  align={index === 0 ? "left" : "right"}
                >
                  <Typography fontWeight="bold" variant="body2">
                    {label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ backgroundColor: theme.palette.common.white }}>
            {isLoading && (
              <DataTableSkeleton
                rows={rows.length || 10}
                cells={headers.length}
                className={classes.skeleton}
              />
            )}

            {!isLoading && rows.length < 1 && <NoData />}

            {!isLoading && rows?.map((row, index) => renderRow(row, index))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default DistributorHierarchy;
