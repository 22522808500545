import { type FC, Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardProps,
  Collapse,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import { euroValueCurrency } from "src/constants";
import {
  Commodity,
  CommodityBookingData,
  CommodityUnitCombination,
  ContractBookingUnit,
} from "src/types/contract";
import { calculateCurrentCommodityStockPriceValue } from "src/utils/calculateCommodityValue";
import { formatElementSymbol } from "src/utils/elementSymbols";
import {
  formatCommodityAmount,
  formatCurrencyNumber,
} from "src/utils/formatTexts";
import { useStockPriceListQuery } from "src/services/serverState/stockPrices/useStockPriceListQuery";
import InfoTooltip from "src/components/InfoTooltip/InfoTooltip";
import NoData from "src/components/NoData/NoData";

import CommodityCardSkeleton from "./CommodityCard.skeleton";

import { useStyles } from "./CommodityCard.styles";

interface CommodityCardProps extends CardProps {
  commodities?: Commodity[];
  commodityBookings?: Record<string, CommodityBookingData>;
  isLoading?: boolean;
  loadMoreTransactions?: (args: CommodityUnitCombination) => void;
  selectedCommodity?: CommodityUnitCombination | undefined;
  setSelectedCommodity?: (args: CommodityUnitCombination | undefined) => void;
  showHeader?: boolean;
  showPrices?: boolean;
}

const CommodityOrder = [
  "AU",
  "AG",
  "PT",
  "PD",
  "GA",
  "IN",
  "GE",
  "HF",
  "IR",
  "RE",
  "RU",
  "ND",
  "PR",
  "DY",
  "TB",
];

export const CommodityCard: FC<CommodityCardProps> = ({
  commodities = [],
  commodityBookings,
  isLoading,
  loadMoreTransactions,
  selectedCommodity,
  setSelectedCommodity,
  showHeader,
  showPrices = true,
  ...props
}) => {
  const canSelect = !!setSelectedCommodity;
  const { classes } = useStyles({ canSelect });
  const theme = useTheme();
  const { t } = useTranslation();

  // Get stockPriceData
  const { data: stockPrices } = useStockPriceListQuery({});

  // Create the order of the commodity rows
  // filter out commodities that do not exist for this contract
  const filteredOrder = CommodityOrder.filter((item) =>
    commodities?.some(({ name }) => item === name),
  );

  // Create the actual data to display
  // We need to create entries in the order created above
  // One commodity can have multiple entries by having multiple units (g / oz)
  const commodityRows = useMemo(
    () =>
      filteredOrder.flatMap((symbol) =>
        (commodities || [])
          .filter(({ name }) => name === symbol)
          .map(({ totalAmount, averageBuyingPrice, name, unit, currency }) => {
            const currentCommodityStockPriceValue =
              calculateCurrentCommodityStockPriceValue({
                amount: totalAmount,
                commoditySymbol: symbol,
                stockPriceList: stockPrices || [],
                unit: unit as "g" | "oz",
              });

            return {
              amount: `${formatCommodityAmount(totalAmount)} ${unit}`,
              // "aktueller Wert" only in EUR
              currentPrice: formatCurrencyNumber(
                currentCommodityStockPriceValue,
                euroValueCurrency,
              ),

              name,
              price: averageBuyingPrice
                ? `${formatCurrencyNumber(Number(averageBuyingPrice), currency)}`
                : "-",
              unit,
            };
          }),
      ),
    [commodities, stockPrices],
  );

  const selectedCommodityData =
    commodityBookings &&
    selectedCommodity &&
    commodityBookings[
      `${selectedCommodity.commodity}/${selectedCommodity.unit}`
    ];

  const selectedCommodityBookings = selectedCommodityData?.bookings;

  const selectedCommodityCurrency = commodities.find(
    (commodity) =>
      commodity.name === selectedCommodity?.commodity &&
      commodity.unit === selectedCommodity?.unit,
  )?.currency;

  if (isLoading) {
    return <CommodityCardSkeleton />;
  }

  return (
    <Card className={classes.root} {...props}>
      {showHeader && (
        <Typography variant="h1" color="textColorDark" sx={{ pl: 2, py: 3 }}>
          {t("contracts.commodities")}
        </Typography>
      )}

      <Table aria-label="commodity table" size="small">
        <TableHead>
          <TableRow sx={{ textTransform: "uppercase" }}>
            <TableCell
              style={{ minWidth: showPrices ? "auto" : "25vw" }}
              sx={{ fontWeight: "bold" }}
            >
              {t("common.name")}
            </TableCell>

            <TableCell sx={{ fontWeight: "bold" }}>
              {t("common.amount")}
            </TableCell>

            {showPrices && (
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("common.purchasePrice")} Ø
              </TableCell>
            )}
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("common.currentPrice")}
              <InfoTooltip infoText={t("common.totalCommodityValueHelpText")} />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody sx={{ backgroundColor: theme.palette.common.white }}>
          {commodityRows?.map((row) => {
            const isOpened =
              row.name === selectedCommodity?.commodity &&
              row.unit === selectedCommodity.unit &&
              !!selectedCommodityBookings;

            return (
              <Fragment key={row?.name + row?.unit}>
                <TableRow
                  className={classes.tableRow}
                  onClick={() => {
                    canSelect &&
                      setSelectedCommodity(
                        selectedCommodity?.commodity === row.name &&
                          selectedCommodity?.unit === row.unit
                          ? undefined
                          : {
                              commodity: row.name,
                              unit: row.unit,
                            },
                      );
                  }}
                >
                  <TableCell
                    className={classes.nameCell}
                    sx={{
                      borderBottom: isOpened
                        ? "none"
                        : "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    {canSelect && (
                      <Icon>
                        {isOpened
                          ? "keyboard_arrow_down"
                          : "keyboard_arrow_right"}
                      </Icon>
                    )}
                    <Avatar
                      sx={{
                        bgcolor: theme.palette.secondary.light,
                        fontSize: "1em",
                        mr: 2,
                      }}
                      variant="rounded"
                    >
                      {formatElementSymbol(row?.name || "")}
                    </Avatar>
                    {t(`common.elementSymbols.${row?.name}`)}{" "}
                    {row?.unit === ContractBookingUnit.OUNCE && (
                      <Typography
                        sx={{ ml: 0.5 }}
                        color={theme.palette.textColorLight}
                      >
                        ({t("common.coins")})
                      </Typography>
                    )}
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: isOpened
                        ? "none"
                        : "1px solid rgba(224, 224, 224, 1)",
                      fontSize: "1em",
                    }}
                  >
                    {row?.amount}
                  </TableCell>

                  {showPrices && (
                    <TableCell
                      sx={{
                        borderBottom: isOpened
                          ? "none"
                          : "1px solid rgba(224, 224, 224, 1)",
                        fontSize: "1em",
                      }}
                    >
                      {row?.price}
                    </TableCell>
                  )}
                  <TableCell
                    sx={{
                      borderBottom: isOpened
                        ? "none"
                        : "1px solid rgba(224, 224, 224, 1)",
                      fontSize: "1em",
                    }}
                  >
                    {row?.currentPrice}
                  </TableCell>
                </TableRow>

                {canSelect && (
                  <TableRow>
                    <TableCell className={classes.tableCell} colSpan={6}>
                      <Collapse in={isOpened} timeout={350} unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <Typography
                            variant="h3"
                            color={theme.palette.common.black}
                            gutterBottom
                            mb={3}
                          >
                            {t("contracts.transactionHistory")}
                          </Typography>

                          <Table>
                            <TableHead>
                              <TableRow className={classes.collapsibleHeader}>
                                <TableCell
                                  size="small"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {t("common.date")}
                                </TableCell>

                                <TableCell
                                  size="small"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {t("common.moneyAmount")}
                                </TableCell>

                                <TableCell
                                  size="small"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {t("common.amount")}
                                </TableCell>

                                <TableCell
                                  size="small"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {t("common.price")}
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {selectedCommodityBookings &&
                                selectedCommodityBookings.map((booking) => (
                                  <TableRow
                                    key={booking.backofficeId}
                                    className={classes.collapsibleRows}
                                  >
                                    <TableCell component="th" scope="row">
                                      {booking.bookingDate}
                                    </TableCell>

                                    <TableCell>
                                      {formatCurrencyNumber(
                                        booking.moneyAmount,
                                        selectedCommodityCurrency,
                                      )}
                                    </TableCell>

                                    <TableCell>
                                      {formatCommodityAmount(
                                        Number(booking.commodityAmount),
                                      )}
                                      {" " + booking.unit}
                                    </TableCell>

                                    <TableCell>
                                      {formatCurrencyNumber(
                                        booking.commodityPrice,
                                        selectedCommodityCurrency,
                                      ) +
                                        " / " +
                                        booking.unit}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>

                          {selectedCommodityData?.next && (
                            <Button
                              fullWidth
                              variant="outlined"
                              onClick={() =>
                                loadMoreTransactions &&
                                selectedCommodity &&
                                loadMoreTransactions(selectedCommodity)
                              }
                              className={classes.loadMoreButton}
                              endIcon={<ArrowDownwardIcon fontSize="small" />}
                            >
                              {t("contracts.moreTransactions")}
                            </Button>
                          )}

                          {selectedCommodityBookings?.length === 0 && (
                            <NoData />
                          )}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
};
