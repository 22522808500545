import { useQuery } from "@tanstack/react-query";

import { contractsForCustomerRequest } from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";

import { contractKeys } from "./key";

interface ContractsForCustomerListQueryProps extends DefaultQueryProps {
  customerNumber?: number;
}
export const useContractListForCustomerQuery = ({
  customerNumber,
  ...props
}: ContractsForCustomerListQueryProps) =>
  useQuery({
    queryFn: () => contractsForCustomerRequest(customerNumber),
    queryKey: [contractKeys.contractListForCustomer, customerNumber],
    refetchIntervalInBackground: true,
    ...props,
  });
