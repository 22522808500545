import type { FC, ReactNode } from "react";
import type { ButtonProps } from "@mui/material";
import { Button, CircularProgress } from "@mui/material";

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
  children: ReactNode;
}

export const LoadingButton: FC<LoadingButtonProps> = ({
  children,
  loading,
  ...props
}) => (
  <Button disabled={loading} {...props}>
    {loading ? <CircularProgress color="inherit" size={18} /> : children}
  </Button>
);

export default LoadingButton;
