import { type FC, type ReactNode, useState } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Header } from "src/components/MobilePageHeader/MobilePageHeader";
import Navigation from "src/components/Navigation/Navigation";
import { NAVIGATION_DEFAULT_WIDTH } from "src/components/Navigation/Navigation.config";

import { useStyles } from "./SiteNavigationLayout.styles";

interface SiteNavigationLayoutProps {
  children?: ReactNode;
}

export const SiteNavigationLayout: FC<SiteNavigationLayoutProps> = ({
  children,
}) => {
  const theme = useTheme();
  const navigationWidth = NAVIGATION_DEFAULT_WIDTH;
  const { classes } = useStyles({ navigationWidth });
  const mdUp = useMediaQuery(() => theme.breakpoints.up("md"));
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  return (
    <Box className={classes.wrapper}>
      {!mdUp && <Header openNavigation={() => setIsNavigationOpen(true)} />}

      <Navigation
        isOpen={isNavigationOpen}
        navigationWidth={navigationWidth}
        onClose={() => setIsNavigationOpen(false)}
        variant={mdUp ? "permanent" : "temporary"}
      />

      <Box className={classes.verticalLayoutRoot}>
        <Box className={classes.verticalLayoutContainer}>{children}</Box>
      </Box>
    </Box>
  );
};

export default SiteNavigationLayout;
