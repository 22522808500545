import { useQuery } from "@tanstack/react-query";

import type { Contract } from "src/types/contract";
import { contractForCustomerRequest } from "src/services/api/api";
import {
  DefaultQueryProps,
  queryClient,
} from "src/services/serverState/queryClient";

import { contractKeys } from "./key";

interface QueryProps extends DefaultQueryProps {
  contractNumber: string;
}

export const useContractForCustomerQuery = ({
  contractNumber,
  ...props
}: QueryProps) => {
  const contracts: Contract[] | undefined = queryClient.getQueryData([
    contractKeys.contractListForCustomer,
  ]);

  return useQuery({
    initialData: () =>
      contracts?.find((contract) => contract.contractNumber === contractNumber),
    queryFn: () => contractForCustomerRequest(contractNumber),
    queryKey: [contractKeys.contractForCustomer, contractNumber],
    refetchIntervalInBackground: true,
    ...props,
  });
};
