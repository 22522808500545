/* eslint-disable sort-keys-fix/sort-keys-fix */
import type { TypographyOptions } from "@mui/material/styles/createTypography";

export const createTypography = (): TypographyOptions => ({
  fontFamily: "'Roboto', 'Lora', sans-serif",
  body1: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1.2,
  },
  body2: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: 1.2,
  },
  button: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: 1.2,
  },
  caption: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: 1.2,
  },
  subtitle1: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: 1.2,
  },
  subtitle2: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: 1.2,
  },
  overline: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 700,
    fontSize: "11px",
    lineHeight: 1.2,
    textTransform: "uppercase",
  },
  h1: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: 1.4,
  },
  h2: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: 1,
  },
  h3: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: 1.2,
  },
  h4: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: 1.2,
  },
  h5: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: 1.2,
  },
  h6: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: 1.2,
  },
});
