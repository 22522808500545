import { Theme } from "@mui/material";

import { Contract } from "src/types/contract";
import { ContractStatus } from "src/types/contract";

export const getContractStatusColor = (
  status: ContractStatus,
  theme: Theme,
) => {
  const StatusMap = {
    11: theme.palette.green,
    12: theme.palette.grey,
    19: theme.palette.red,
  };

  return StatusMap[status];
};

/*
 * Returns the summed value of a contract value.
 * All values in a contract that have a number can be summarized and summed.
 */
export const calculateContractSum = ({
  contracts,
  value,
}: {
  contracts: Contract[] | undefined;
  value: keyof Contract;
}): number | undefined => {
  const contractSum = contracts?.reduce((sum, contract) => {
    const contractValue = contract[value];

    return sum + Number(contractValue);
  }, 0);

  if (isNaN(Number(contractSum))) {
    return undefined;
  }

  return contractSum;
};
