import NorthIcon from "@mui/icons-material/North";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import SouthIcon from "@mui/icons-material/South";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";
import { SvgIcon } from "@mui/material";

import { BookingType } from "src/types/contract";
import { error, success } from "src/theme/customerConfig/customerColors";
export enum ColorVariant {
  Buy = "buy",
  Default = "default",
  Sell = "sell",
}

export const getContractBookingIconAndColor = ({
  bookingType,
}: {
  bookingType: BookingType;
}) => {
  let contractBookingIcon;
  let colorVariant = ColorVariant.Default;

  switch (bookingType) {
    // NorthIcon
    case BookingType.AZ:
    case BookingType.BG:
    case BookingType.DG:
    case BookingType.EG:
    case BookingType.LG:
    case BookingType.SP:
    case BookingType.VG:
    case BookingType.ID:
    case BookingType.AG:
    case BookingType.VZ:
    case BookingType.MG:
      contractBookingIcon = <NorthIcon />;
      break;

    // SouthEastIcon
    case BookingType.RK:
    case BookingType.EL:
      contractBookingIcon = <SouthEastIcon style={{ color: success.main }} />;
      colorVariant = ColorVariant.Buy;
      break;

    // SouthIcon
    case BookingType.EZ:
      contractBookingIcon = <SouthIcon />;
      break;

    // NorthWestIcon
    case BookingType.RV:
    case BookingType.VE:
    case BookingType.VH:
    case BookingType.AL:
      contractBookingIcon = <NorthWestIcon style={{ color: error.main }} />;
      colorVariant = ColorVariant.Sell;
      break;

    case BookingType.UB:
      contractBookingIcon = (
        <SvgIcon sx={{ transform: "rotate(90deg)" }}>
          <UTurnLeftIcon />
        </SvgIcon>
      );
      break;

    case BookingType.AB:
      contractBookingIcon = (
        <SvgIcon sx={{ transform: "rotate(180deg)" }}>
          <UTurnLeftIcon />
        </SvgIcon>
      );
      break;

    case BookingType.EB:
      contractBookingIcon = (
        <SvgIcon>
          <UTurnLeftIcon />
        </SvgIcon>
      );
      break;
  }

  return { colorVariant, contractBookingIcon };
};
