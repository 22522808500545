import { useQuery } from "@tanstack/react-query";

import { distributorCurrenciesRequest } from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";

import { currenciesKeys } from "./key";

interface QueryProps extends DefaultQueryProps {
  distributorId: number | undefined;
}

export const useDistributorCurrenciesQuery = ({
  distributorId,
  ...props
}: QueryProps) =>
  useQuery({
    queryFn: () => distributorCurrenciesRequest(distributorId),
    queryKey: [currenciesKeys.currencies, distributorId],
    refetchIntervalInBackground: true,
    ...props,
  });
