import type { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormControlProps,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { useUser } from "src/services/contexts/userContext";
import { useDistributorCurrenciesQuery } from "src/services/serverState/currencies/useDistributorCurrenciesQuery";

import { useStyles } from "./CurrencySelection.styles";

interface CurrencySelectionProps extends FormControlProps {}

export const CurrencySelection: FC<CurrencySelectionProps> = ({ ...props }) => {
  const { classes } = useStyles();
  const { userActions, userState } = useUser();
  const { t } = useTranslation();
  const distributorId = userState.user?.activeDistributorProfile?.distributorId;

  const handleCurrencyChange = (newValue: string) => {
    userActions.setCurrency(newValue);
  };

  const { data, isLoading, isError } = useDistributorCurrenciesQuery({
    distributorId,
  });

  return (
    <FormControl className={classes.formControl} size="small" {...props}>
      {!isLoading && !isError && (
        <>
          <Typography sx={{ marginRight: 1 }}>
            {t("common.currencyLabel")}:
          </Typography>

          <Select
            className={classes.select}
            value={userState.selectedCurrency}
            onChange={(event) => handleCurrencyChange(event.target.value)}
          >
            {data?.map((currencyCode) => (
              <MenuItem value={currencyCode} key={currencyCode}>
                {currencyCode}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </FormControl>
  );
};

export default CurrencySelection;
