import { type FC, Fragment } from "react";
import { Grid, Skeleton } from "@mui/material";

import { useStyles } from "./ContractBookingList.styles";

interface ContractBookingListSkeletonProps {
  renderAllContracts?: boolean;
}

export const ContractBookingListSkeleton: FC<
  ContractBookingListSkeletonProps
> = ({ renderAllContracts = false }) => {
  const { classes, cx } = useStyles();

  return (
    <Grid
      container
      spacing={3}
      sx={{ p: 2 }}
      className={cx(renderAllContracts && classes.scrollable)}
    >
      {Array(10)
        .fill(0)
        .map((_, index) => (
          <Fragment key={index}>
            <Grid item>
              <Skeleton variant="rounded" width={50} height={50} />
            </Grid>

            <Grid item xs={6}>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1.5rem" }}
                width="80%"
              />
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} width="40%" />
            </Grid>

            <Grid item xs={4} display="flex" justifyContent="end">
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} width="40%" />
            </Grid>
          </Fragment>
        ))}
    </Grid>
  );
};

export default ContractBookingListSkeleton;
