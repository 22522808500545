import { type FC } from "react";
import { Box, Card, CardProps, Typography, useTheme } from "@mui/material";

import { FileIcon, FileTypes } from "src/components/FileIcon/FileIcon";

import { useStyles } from "./DownloadCard.styles";

import IconFolder from "/assets/icons/icon-folder.svg";

interface DownloadCardProps extends CardProps {
  date?: string;
  fileType?: FileTypes;
  itemType?: "file" | "folder";
  title: string;
  onClick: () => void;
}

export const DownloadCard: FC<DownloadCardProps> = ({
  date,
  fileType,
  itemType,
  title,
  onClick,
  ...props
}) => {
  const theme = useTheme();
  const { classes } = useStyles();

  const uploadedAt =
    date && new Intl.DateTimeFormat("de-DE").format(new Date(date));

  return (
    <Card
      sx={{ cursor: "pointer", padding: theme.spacing(2) }}
      onClick={onClick}
      {...props}
    >
      <Box mb={1}>
        {itemType === "folder" ? (
          <img src={IconFolder} />
        ) : (
          <FileIcon fileType={fileType} />
        )}
      </Box>

      <Box>
        <Typography className={classes.title} variant="h3">
          {title}
        </Typography>
      </Box>

      <Box>
        <Typography mb={1} variant="caption">
          {uploadedAt}
        </Typography>
      </Box>
    </Card>
  );
};

export default DownloadCard;
