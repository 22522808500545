import { Skeleton, TableCell, TableRow } from "@mui/material";

export const DataTableSkeleton = ({
  className,
  rows,
  cells,
}: {
  className?: string;
  rows: number;
  cells: number;
}) => (
  <>
    {Array(rows)
      .fill(0)
      .map((_, rowIndex) => (
        <TableRow key={rowIndex} className={className}>
          {Array(cells)
            .fill(0)
            .map((_, cellIndex) => (
              <TableCell key={cellIndex}>
                <Skeleton variant="rounded" height={30} />
              </TableCell>
            ))}
        </TableRow>
      ))}
  </>
);

export default DataTableSkeleton;
