import {
  createTheme as createMuiTheme,
  PaletteOptions,
  responsiveFontSizes,
} from "@mui/material/styles";
import type { Theme } from "@mui/material/styles/createTheme";

import { createOptions } from "./config/createOptions";

interface BorderRadius {
  sm: number;
  md: number;
  lg: number;
  round: number;
}

declare module "@mui/material/styles/createTheme" {
  interface ThemeOptions {
    borderRadius: BorderRadius;
  }

  interface Theme {
    borderRadius: BorderRadius;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface ColorRange {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  }

  interface Palette {
    beige?: ColorRange;
    blue?: ColorRange;
    brown?: ColorRange;
    darkBrown?: ColorRange;
    gold?: ColorRange;
    green: ColorRange;
    neutral: ColorRange;
    red: ColorRange;
    ternary: SimplePaletteColorOptions;
    quartaer: SimplePaletteColorOptions;
    textColorDark: string;
    textColorLight: string;
    yellow: ColorRange;
    gradients: {
      light: string;
      dark: string;
      panel: string;
    };
  }

  interface SimplePaletteColorOptions {
    lightest?: string;
    darkest?: string;
  }

  interface PaletteColor {
    lightest?: string;
    darkest?: string;
    alpha4?: string;
    alpha8?: string;
    alpha12?: string;
    alpha30?: string;
    alpha50?: string;
  }

  interface PaletteOptions {
    beige?: ColorRange;
    lightBlue?: ColorRange;
    darkBlue?: ColorRange;
    orange?: ColorRange;
    blue?: ColorRange;
    brown?: ColorRange;
    darkBrown?: ColorRange;
    gold?: ColorRange;
    green: ColorRange;
    neutral: ColorRange;
    red: ColorRange;
    ternary: SimplePaletteColorOptions;
    quartaer: SimplePaletteColorOptions;
    textColorDark: string;
    textColorLight: string;
    yellow: ColorRange;
    gradients: {
      light: string;
      dark: string;
      panel: string;
    };
  }
}

/**
 * In the GoldenGates context, PaletteMode is used for white labelling.
 * However, it can also be used to create a light or dark theme.
 * The PaletteMode decides on the color palette to be used.
 */
export enum PaletteMode {
  customerBaseTheme,
  distributorBaseTheme,
  custom,
}
export interface ThemeConfig {
  paletteMode: PaletteMode;
  palette?: PaletteOptions;
  responsiveFontSizes?: boolean;
}

export const createTheme = (config: ThemeConfig): Theme => {
  let theme = createMuiTheme(
    // Options based on selected palette mode, color preset and contrast
    createOptions({
      appTheme: config.paletteMode,
      customPalette: config.palette,
    }),
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
