import { tss } from "tss-react/mui";

export const useStyles = tss
  .withParams<{ navigationWidth: string; navItemActiveColor: string }>()
  .create(({ theme, navigationWidth, navItemActiveColor }) => ({
    externalLinks: {
      "& > *": {
        color: theme.palette.secondary.main,
        paddingBottom: theme.spacing(1),
      },

      "&:hover": {
        textDecoration: "underline",
        textDecorationColor: theme.palette.secondary.main,
      },

      textDecoration: "none",
    },

    navigationPaper: {
      backgroundImage: theme.palette.gradients.dark,
      boxShadow: theme.shadows[10],
      justifyContent: "space-between",
      width: navigationWidth,

      [theme.breakpoints.up("md")]: {
        borderRadius: 5,
        height: `calc(100% - ${theme.spacing(4)})`,
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
      },
    },

    navItem: {
      padding: theme.spacing(2),
    },

    navItemActive: {
      backgroundColor: navItemActiveColor,
      borderRadius: theme.borderRadius.sm,
    },

    navSection: {
      listStyle: "none",
      margin: 0,
      padding: 0,
    },

    switchButton: {
      "&:hover": {
        backgroundColor: theme.palette.quartaer.lightest,
        color: theme.palette.secondary.main,
      },
      backgroundColor: theme.palette.quartaer.lightest,
      color: theme.palette.secondary.main,
      marginBottom: theme.spacing(3),
      padding: theme.spacing(1),
    },
  }));
