import { useQuery } from "@tanstack/react-query";

import { distributorKeyFiguresRequest } from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";

import { distributorKeys } from "./key";

interface DistributorKeyFiguresQueryProps extends DefaultQueryProps {
  currency: string;
  distributorId: number | undefined;
  period: "month" | "year";
}
export const useDistributorKeyFiguresQuery = ({
  currency,
  distributorId,
  period,
  ...props
}: DistributorKeyFiguresQueryProps) =>
  useQuery({
    queryFn: () =>
      distributorKeyFiguresRequest({ currency, distributorId, period }),
    queryKey: [
      currency,
      distributorKeys.distributorKeyFigures,
      distributorId,
      period,
    ],
    refetchIntervalInBackground: true,
    ...props,
  });
