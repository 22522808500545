import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";

import { paths } from "src/paths";
import { ValidationRules } from "src/utils/formValidation";
import { safejwtDecode } from "src/utils/jwt";
import { useUser } from "src/services/contexts/userContext";
import { LoadingButton } from "src/components/LoadingButton/LoadingButton";

interface UpdatePasswordFormState {
  password: string;
  passwordConfirm: string;
}

const initialValues: UpdatePasswordFormState = {
  password: "",
  passwordConfirm: "",
};

const validationSchema = Yup.object({
  password: ValidationRules.password(),
  passwordConfirm: ValidationRules.password(),
});

interface UpdatePasswordFormProps {
  temporaryToken: string;
  isUpdateForm?: boolean;
}

export const UpdatePasswordForm = ({
  temporaryToken,
}: UpdatePasswordFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userActions, userState } = useUser();
  const { updatePassword } = userActions;
  const { setPasswordErrors, setPasswordSuccess } = userState;
  const tokenData = safejwtDecode(temporaryToken);

  const now = new Date().getTime();
  // We have to multiply the expiry by 1000 because the backend works works with seconds not milliseconds
  const tokenExpiresAt = tokenData?.exp
    ? new Date(tokenData.exp * 1000).getTime()
    : now;
  const tokenExpired = now >= tokenExpiresAt;

  const theme = useTheme();

  const handleSubmit = async (
    values: UpdatePasswordFormState,
    actions: FormikHelpers<UpdatePasswordFormState>,
  ) => {
    const { password } = values;
    // Reset the form dirty state to display server errors
    formik.resetForm({ values });
    await updatePassword({
      password,
      token: temporaryToken,
    });

    actions.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validate: ({ password, passwordConfirm }) => {
      if (password !== passwordConfirm) {
        return {
          passwordConfirm: t("auth.passwordConfirmError"),
        };
      }
    },
    validateOnChange: true,
    validationSchema,
  });

  const passwordErrors = setPasswordErrors?.password;
  const invalidTokenErrors = setPasswordErrors?.token;
  const isError = !!(passwordErrors || invalidTokenErrors) && !formik.dirty;

  if (tokenExpired) {
    return (
      <Box>
        <Typography color={theme.palette.common.white}>
          {t("auth.tokenExpired")}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {setPasswordSuccess ? (
        <Box>
          <Typography variant="h3" color={theme.palette.common.white}>
            {t("auth.passwordSetSuccess")}
          </Typography>

          <Button
            size="large"
            sx={{ mt: 4 }}
            variant="contained"
            onClick={() => navigate(paths.login)}
          >
            {t("auth.toLogin")}
          </Button>
        </Box>
      ) : (
        <form noValidate onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <TextField
              error={
                !!(
                  formik.touched.password &&
                  formik.errors.password &&
                  formik.values.password
                )
              }
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label={t("auth.password")}
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
              variant="filled"
            />

            <TextField
              error={
                !!(
                  formik.touched.passwordConfirm &&
                  formik.errors.passwordConfirm &&
                  formik.values.passwordConfirm
                )
              }
              fullWidth
              helperText={
                formik.touched.passwordConfirm && formik.errors.passwordConfirm
              }
              label={t("auth.confirmPassword")}
              name="passwordConfirm"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.passwordConfirm}
              variant="filled"
            />
          </Stack>

          {isError && (
            <Box>
              {invalidTokenErrors?.length && (
                <Typography
                  color={theme.palette.common.white}
                  marginTop={2}
                  align="left"
                >
                  {invalidTokenErrors[0]}
                </Typography>
              )}
              {passwordErrors?.map((error) => (
                <Typography
                  color={theme.palette.common.white}
                  key={error}
                  marginTop={2}
                  align="left"
                >
                  {error}
                </Typography>
              ))}
            </Box>
          )}

          <LoadingButton
            loading={formik.isSubmitting}
            fullWidth
            size="large"
            sx={{ mt: 2, py: 2 }}
            type="submit"
            variant="contained"
            disabled={!formik.dirty || !formik.isValid}
          >
            {t("auth.continue")}
          </LoadingButton>
        </form>
      )}
    </>
  );
};

export default UpdatePasswordForm;
