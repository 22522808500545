import { tss } from "tss-react/mui";

export const useStyles = tss
  .withParams<{ canSelect: boolean }>()
  .create(({ theme, canSelect }) => ({
    collapsibleHeader: {
      "& .MuiTableCell-head": {
        backgroundColor: theme.palette.secondary.darkest,
        textTransform: "uppercase",
      },
    },

    collapsibleRows: {
      "& .MuiTableCell-root": {
        border: "none",
        padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
      },
    },

    loadMoreButton: {
      fontSize: "1em",
      marginTop: 1,
      textTransform: "none",
    },

    nameCell: {
      alignItems: "center",
      color: theme.palette.textColorDark,
      display: "flex",
      fontSize: "1em",
      fontWeight: "bold",
    },

    root: {
      backgroundImage: theme.palette.gradients.panel,
    },

    tableCell: {
      border: 0,
      paddingBottom: 0,
      paddingTop: 0,
    },

    tableRow: {
      "&:hover": {
        backgroundColor: canSelect ? theme.palette.secondary.light : undefined,
      },
      "&:last-child td, &:last-child th": { border: 0 },
      cursor: canSelect ? "pointer" : "default",
    },
  }));
