import React from "react";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

import SentryErrorPage from "src/pages/Error/SentryErrorPage/SentryErrorPage";
import App from "src/components/App/App";

import {
  GOOGLE_TAG_MANAGER_ID,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  SENTRY_RELEASE,
  SENTRY_TRACES_SAMPLE_RATE,
} from "./env";

// Import Fonts
import "@fontsource/lora/400.css"; // Specify weight
import "@fontsource/lora/400-italic.css"; // Specify weight and style
import "@fontsource/roboto/700.css"; // Specify weight
import "@fontsource/roboto/700-italic.css"; // Specify weight and style
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style
import "@fontsource/roboto/300.css"; // Specify weight
import "@fontsource/roboto/300-italic.css"; // Specify weight and style
// Import Mui Icon Font
import "material-icons/iconfont/material-icons.css";

import "@fontsource/lora"; // Defaults to weight 400
import "@fontsource/roboto"; // Defaults to weight 400

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    release: SENTRY_RELEASE,
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
  });
}

if (GOOGLE_TAG_MANAGER_ID) {
  const tagManagerArgs = {
    gtmId: GOOGLE_TAG_MANAGER_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

root.render(
  <Sentry.ErrorBoundary fallback={<SentryErrorPage />}>
    <React.StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <Suspense>
            <App />
          </Suspense>
        </BrowserRouter>
      </HelmetProvider>
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
);
