import type { FC, ReactNode } from "react";
import { Fragment } from "react";
import {
  Box,
  CardProps,
  Grid,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";

import WidgetWrapper from "src/components/WidgetWrapper/WidgetWrapper";

interface InfoCardProps extends CardProps {
  actionTabsChoices?: { value: string; label: string }[];
  alternativeBackground?: boolean;
  displayVariant?: "vertical" | "horizontal";
  fields: { value: string | ReactNode; label: string }[];
  footerText?: string;
  isLoading?: boolean;
  onActionTabSwitch?: (tabValue: string) => void;
  redirectPath?: string;
  title: string;
}

/*
  A general purpose InfoCard component to render structured data in a card.
  Can be displayed vertical (label above value).
  Or horizontal (label left, value right).
*/

export const InfoCard: FC<InfoCardProps> = ({
  actionTabsChoices,
  alternativeBackground,
  displayVariant = "vertical",
  fields,
  footerText,
  isLoading,
  onActionTabSwitch,
  redirectPath,
  title,
  ...props
}) => {
  const theme = useTheme();

  const renderVerticalLayout = () => (
    <Box p={2} borderRadius={1} sx={{ height: "100%" }}>
      {!isLoading &&
        fields.map((field) => (
          <Typography
            color={theme.palette.textColorDark}
            key={field.label}
            mb={3}
            variant="subtitle1"
          >
            {field.label}
            <Typography
              gutterBottom
              color={theme.palette.textColorDark}
              variant="body1"
            >
              {field.value}
            </Typography>
          </Typography>
        ))}
      {isLoading &&
        fields.map((field) => (
          <Skeleton key={field.label} sx={{ height: 40 }} />
        ))}
    </Box>
  );

  const renderHorizontalLayout = () => (
    <Grid container spacing={3} p={2}>
      {fields.map((data) => (
        <Fragment key={data.label}>
          <Grid item xs={8} md={7}>
            {isLoading ? (
              <Skeleton sx={{ height: 40 }} />
            ) : (
              <Typography>{data.label}:</Typography>
            )}
          </Grid>

          <Grid item xs={4} md={5}>
            {isLoading ? (
              <Skeleton sx={{ height: 40 }} />
            ) : (
              <Typography color="textColorDark">{data.value}</Typography>
            )}
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );

  return (
    <WidgetWrapper
      sx={{
        backgroundColor: alternativeBackground
          ? theme.palette.secondary.main
          : undefined,
        height: "100%",
      }}
      showDivider={false}
      onActionSwitch={onActionTabSwitch}
      actionTabsChoices={actionTabsChoices}
      headerText={title}
      footerText={footerText}
      redirectPath={redirectPath}
      {...props}
    >
      {displayVariant === "vertical" && renderVerticalLayout()}
      {displayVariant === "horizontal" && renderHorizontalLayout()}
    </WidgetWrapper>
  );
};

export default InfoCard;
