import type { FC } from "react";
import {
  Avatar,
  Box,
  Card,
  CardProps,
  Icon,
  Link,
  Typography,
  useTheme,
} from "@mui/material";

import { getInitials } from "src/utils/formatTexts";

import { useStyles } from "./ContactCard.styles";

interface ContactCardProps extends CardProps {
  email?: string;
  name: string;
  phone?: string;
  title: string;
}

export const ContactCard: FC<ContactCardProps> = ({
  email,
  name,
  phone,
  title,
  ...props
}) => {
  const { classes } = useStyles();
  const theme = useTheme();

  return (
    <Card
      className={classes.root}
      {...props}
      sx={{ padding: theme.spacing(2) }}
    >
      <Box display="flex" flexDirection="row">
        <Box mr={2}>
          <Avatar variant="rounded" className={classes.avatar}>
            {getInitials(name)}
          </Avatar>
        </Box>
        <Box className={classes.top}>
          <Box>
            <Typography variant="body2">{title}</Typography>
          </Box>
          <Box>
            <Typography
              variant="h3"
              component="p"
              color="textColorDark"
              className={classes.name}
            >
              {name}
            </Typography>
          </Box>
        </Box>
      </Box>
      {phone && (
        <Box className={classes.bottom}>
          <Icon className={classes.icon}>phone</Icon>
          <Link href={`tel:${phone}`} underline="none">
            {phone}
          </Link>
        </Box>
      )}
      {email && (
        <Box className={classes.bottom}>
          <Icon className={classes.icon}>mail</Icon>
          <Link href={`mailto:${email}`} underline="none">
            {email}
          </Link>
        </Box>
      )}
    </Card>
  );
};
