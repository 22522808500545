import { useTranslation } from "react-i18next";

import PublicLayout from "src/layouts/PublicLayout/PublicLayout";
import { LoginForm } from "src/components/Forms/LoginForm/LoginForm";
import { Seo } from "src/components/Seo";

export const LoginPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo title={t("common.loginTitle")} />
      <PublicLayout title={t("common.loginTitle")}>
        <LoginForm />
      </PublicLayout>
    </>
  );
};

export default LoginPage;
