import type { FC, ReactNode } from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import { AppVariant } from "src/types/config";
import { DistributorContact } from "src/types/contract";
import type { User } from "src/types/user";

import { AXI } from "../api/axi";
import { queryClient } from "../serverState/queryClient";

const meRequest = async () => AXI.get("/me/");

export interface SignInRequestProps {
  email: string;
  password: string;
}

export interface SetPasswordRequestProps {
  password: string;
  token: string;
}

const signInRequest = ({ email, password }: SignInRequestProps) =>
  AXI.post("/login/", { email, password });

const logoutRequest = async () => AXI.post("/logout/");

interface SetPasswordErrors {
  password?: string[];
  token?: string[];
}

interface SetRequestPasswordErrors {
  email?: string[];
}

const setPasswordRequest = async (password: string, temporaryToken: string) => {
  const response = await AXI.post("/set-password/", {
    password,
    token: temporaryToken,
  });

  return response.data;
};

const setUserSettingsRequest = async ({
  selectedCurrency,
}: {
  selectedCurrency: string;
}) => {
  const response = await AXI.put("/me/", {
    selectedCurrency,
  });

  return response.data;
};

const updatePasswordReqeust = async (
  password: string,
  temporaryToken: string,
) => {
  const response = await AXI.post("/update-password/", {
    password,
    token: temporaryToken,
  });

  return response.data;
};

const forgotPasswordRequest = async (email: string) => {
  const response = await AXI.post("/forgot-password/", {
    email,
  });

  return response.data;
};

const inviteUserRequest = async (email: string) => {
  const response = await AXI.post("/invite-user/", {
    email,
  });

  return response.data;
};

export interface UserContextValue {
  userActions: {
    switchSelectedApp: () => void;
    fetchUser: () => void;
    inviteUser: ({ email }: { email: string }) => Promise<void>;
    logoutUser: () => void;
    signInUser: ({ email, password }: SignInRequestProps) => void;
    setCurrency: (currencyCode: string) => void;
    setCustomerProfileNumber: (customerProfile: number) => void;
    setPassword: ({
      password,
      token,
    }: SetPasswordRequestProps) => Promise<void>;
    updatePassword: ({
      password,
      token,
    }: SetPasswordRequestProps) => Promise<void>;
    forgotPassword: ({ email }: { email: string }) => Promise<void>;
  };

  userGetters: {
    customerProfilesCount: number | undefined;
    customerDistributorContact: DistributorContact | undefined;
    distributorId: number | undefined;
    distributorIsDistributionCompany: boolean | undefined;
    isAuthenticated: () => boolean | undefined;
  };

  userState: {
    initialFetchDone: boolean;
    loginFailed: boolean;
    selectedApp: AppVariant;
    selectedCurrency: string;
    selectedCustomerProfileNumber: number | undefined;
    setPasswordErrors: SetPasswordErrors | undefined | null;
    setPasswordSuccess: boolean;
    setRequestPasswordErrors: SetRequestPasswordErrors | undefined | null;
    setRequestPasswordSuccess: boolean;
    user: User | undefined | null;
  };
}

/**
 * @description UserContext.
 */
export const UserContext = createContext<UserContextValue>({
  userActions: {
    fetchUser: () => {},
    forgotPassword: () => new Promise(() => {}),
    inviteUser: () => new Promise(() => {}),
    logoutUser: () => {},
    setCurrency: () => {},
    setCustomerProfileNumber: () => {},
    setPassword: () => new Promise(() => {}),
    signInUser: () => {},
    switchSelectedApp: () => {},
    updatePassword: () => new Promise(() => {}),
  },

  userGetters: {
    customerDistributorContact: undefined,
    customerProfilesCount: undefined,
    distributorId: undefined,
    distributorIsDistributionCompany: false,
    isAuthenticated: () => false,
  },

  userState: {
    initialFetchDone: false,
    loginFailed: false,
    selectedApp: AppVariant.Customer, // Default is always customer, so that the login etc. is displayed with the correct theme.
    selectedCurrency: "EUR",
    selectedCustomerProfileNumber: undefined,
    setPasswordErrors: undefined,
    setPasswordSuccess: false,
    setRequestPasswordErrors: undefined,
    setRequestPasswordSuccess: false,
    user: null,
  },
});

interface UserContextProps {
  children?: ReactNode;
}

/**
 * @description Provides the UserProvider.
 */
export const UserProvider: FC<UserContextProps> = ({ children }) => {
  const { i18n, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [user, setUser] = useState<User | undefined | null>();
  const [meCallSucceded, setMeCallSucceeded] = useState(false);
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false); // to set logout even if server is not responding
  const [selectedApp, setSelectedApp] = useState(AppVariant.Customer); // show displayed application
  const [selectedCurrency, setSelectedCurrency] = useState("EUR");
  const [selectedCustomerProfileNumber, setSelectedCustomerProfileNumber] =
    useState<number | undefined>(undefined);

  const [setPasswordSuccess, setSetPasswordSuccess] = useState(false);
  const [setPasswordErrors, setSetPasswordErrors] = useState<
    SetPasswordErrors | undefined | null
  >();

  const [setRequestPasswordSuccess, setSetRequestPasswordSuccess] =
    useState(false);
  const [setRequestPasswordErrors, setSetRequestPasswordErrors] = useState<
    SetRequestPasswordErrors | undefined | null
  >();

  const selectedCustomerProfile = user?.customerProfiles.find(
    ({ customerNumber }) => customerNumber === selectedCustomerProfileNumber,
  );

  const customerDistributorContact = selectedCustomerProfile?.lastDistributor;

  /**
   * @description SignIn a user with username and password
   */
  const signInUser = async ({ email, password }: SignInRequestProps) => {
    await signInRequest({ email, password })
      .then(({ data }) => {
        localStorage.setItem("csrftoken", data.csrftoken);
        setIsLoggedOut(false);
        setLoginFailed(false);

        fetchUser();
      })
      .catch((error) => {
        console.log(error);
        setLoginFailed(true);
      });
  };

  /**
   * @description Fetch self information about logged in user
   */
  const fetchUser = useCallback(async () => {
    await meRequest()
      .then((response) => {
        const data = response.data;
        localStorage.setItem("csrftoken", data.csrftoken);
        setInitialFetchDone(true);

        // Set Language for i18next
        if (i18n.language !== data.selectedLanguage) {
          i18n.changeLanguage(data.selectedLanguage);
        }

        const isDistributor = !!data.distributorProfiles.length;
        const isCustomer = !!data.customerProfiles.length;

        // For now we only use the first distriutorProfile. We statically use the first one
        // as an active distributorProfile to work with
        const activeDistributorProfile =
          isDistributor && data.distributorProfiles[0];

        setUser((oldUser) => ({
          ...oldUser,
          ...data,
          activeDistributorProfile,
          isCustomer: isCustomer,
          isDistributor: isDistributor,
        }));

        setSelectedCurrency(data.selectedCurrency);

        if (isCustomer) {
          setSelectedCustomerProfileNumber(
            data.customerProfiles[0]?.customerNumber,
          );
        }

        // If the current user is a customer and not distributor render customer app.
        if (isCustomer && !isDistributor) {
          setSelectedApp(AppVariant.Customer);
        }

        // If the current user is a distributor render the distributor app.
        // If the current user is a distributor and customer render initially the distributor app.
        if (isDistributor) {
          setSelectedApp(AppVariant.Distributor);
        }

        setMeCallSucceeded(true);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          enqueueSnackbar(t("common.somethingWentWrong"), {
            variant: "error",
          });
        }
      });
  }, []);

  /**
   * @description Logout a user, deleting the server session
   */
  const logoutUser = async () => {
    await logoutRequest()
      .then(() => {
        setUser(null);
        setSelectedApp(AppVariant.Customer); // Render for the login screen etc. the customer theme.
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoggedOut(true);
        // Reset react query state after logout
        queryClient.removeQueries();
      });
  };

  /**
   * @description Sets the selected currency (for viewing data) in the backend
   */
  const setCurrency = async (currencyCode: string) => {
    await setUserSettingsRequest({ selectedCurrency: currencyCode });
    setSelectedCurrency(currencyCode);
  };

  /**
   * @description Sets the selected customer profile.
   */
  const setCustomerProfileNumber = async (customerProfileNumber: number) => {
    setSelectedCustomerProfileNumber(customerProfileNumber);
  };

  /**
   * @description Sets the password of an user with an invite token
   */
  const setPassword = async ({ password, token }: SetPasswordRequestProps) => {
    await setPasswordRequest(password, token)
      .then(() => {
        setSetPasswordErrors(null);
        setSetPasswordSuccess(true);
      })
      .catch((error) => {
        setSetPasswordErrors({
          password: error?.response?.data?.password,
          token: error?.response?.data?.token,
        });
        setSetPasswordSuccess(false);
      });
  };

  /**
   * @description Sets the password of an user with an resetToken if he forgot his password.
   */
  const updatePassword = async ({
    password,
    token,
  }: SetPasswordRequestProps) => {
    await updatePasswordReqeust(password, token)
      .then(() => {
        setSetPasswordErrors(null);
        setSetPasswordSuccess(true);
      })
      .catch((error) => {
        setSetPasswordErrors({
          password: error?.response?.data?.password,
          token: error?.response?.data?.token,
        });
        setSetPasswordSuccess(false);
      });
  };

  /**
   * @description Triggers a password reset mail with a resetToken
   */
  const forgotPassword = async ({ email }: { email: string }) => {
    await forgotPasswordRequest(email)
      .then(() => {
        setSetRequestPasswordErrors(null);
        setSetRequestPasswordSuccess(true);
      })
      .catch((error) => {
        setSetRequestPasswordErrors({
          email: error?.response?.data?.password,
        });
        setSetRequestPasswordSuccess(false);
      });
  };

  /**
   * @description Triggers a invite mail
   */
  const inviteUser = async ({ email }: { email: string }) => {
    await inviteUserRequest(email)
      .then(() => {
        setSetRequestPasswordErrors(null);
        setSetRequestPasswordSuccess(true);
      })
      .catch((error) => {
        setSetRequestPasswordErrors({
          email: error?.response?.data?.password,
        });
        setSetRequestPasswordSuccess(false);
      });
  };

  const switchSelectedApp = () => {
    if (selectedApp === AppVariant.Customer) {
      setSelectedApp(AppVariant.Distributor);
    }
    if (selectedApp === AppVariant.Distributor) {
      setSelectedApp(AppVariant.Customer);
    }
    navigate("/");
  };

  useEffect(() => {
    const initialFetch = async () => {
      try {
        await fetchUser();
      } finally {
        setInitialFetchDone(true);
      }
    };
    initialFetch();
  }, [fetchUser]);

  const isAuthenticated = () =>
    !!(user && meCallSucceded && initialFetchDone && !isLoggedOut);

  const distributorIsDistributionCompany =
    user?.activeDistributorProfile?.distributorIsDistributionCompany ||
    undefined;

  const distributorId =
    user?.activeDistributorProfile?.distributorId || undefined;

  const customerProfilesCount =
    (user?.customerProfiles && user.customerProfiles.length) || undefined;

  if (!initialFetchDone) {
    return null;
  }

  return (
    <UserContext.Provider
      value={{
        userActions: {
          fetchUser,
          forgotPassword,
          inviteUser,
          logoutUser,
          setCurrency,
          setCustomerProfileNumber,
          setPassword,
          signInUser,
          switchSelectedApp,
          updatePassword,
        },

        userGetters: {
          customerDistributorContact,
          customerProfilesCount,
          distributorId,
          distributorIsDistributionCompany,
          isAuthenticated,
        },

        userState: {
          initialFetchDone,
          loginFailed,
          selectedApp,
          selectedCurrency,
          selectedCustomerProfileNumber,
          setPasswordErrors,
          setPasswordSuccess,
          setRequestPasswordErrors,
          setRequestPasswordSuccess,
          user,
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

/**
 * @description Provides the UserConsumer.
 */
export const UserConsumer = UserContext.Consumer;

/**
 * @description Provides the UserContext.
 */
// eslint-disable-next-line react-refresh/only-export-components
export const useUser = (): UserContextValue => useContext(UserContext);

export default UserProvider;
