import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
  wrapper: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(15),

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(30),
    },
  },
}));
