import { useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";

import { TableOrder } from "./DataTable.types";

export const useTableState = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [currentTablePage, setCurrentTablePage] = useState(0);
  const [order, setOrder] = useState<TableOrder>({
    orderBy: undefined,
    orderDirection: "asc",
  });

  const { orderBy, orderDirection } = order;

  const handleOrderChange = (headerId: string) => {
    setOrder({
      orderBy: headerId,
      // When a new header is selected use ascending otherwise toggle
      orderDirection:
        orderBy !== headerId
          ? "asc"
          : orderDirection === "asc"
            ? "desc"
            : "asc",
    });
  };

  return {
    currentTablePage,
    debouncedSearchValue,
    handleOrderChange,
    order,
    searchValue,
    setCurrentTablePage,
    setOrder,
    setSearchValue,
  };
};
