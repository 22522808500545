import { useQuery } from "@tanstack/react-query";

import { commoditiesRequest } from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";

import { commodityKeys } from "./key";

interface CommoditiesQueryProps extends DefaultQueryProps {
  customerNumber: number | string | undefined;
}

export const useCommodityListForCustomerQuery = ({
  customerNumber,
  ...props
}: CommoditiesQueryProps) =>
  useQuery({
    queryFn: () => commoditiesRequest({ customerNumber }),
    queryKey: [commodityKeys.commodities, customerNumber],
    refetchIntervalInBackground: true,
    ...props,
  });
