import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  BoxProps,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";

import { ValidationRules } from "src/utils/formValidation";
import { useUser } from "src/services/contexts/userContext";
import { LoadingButton } from "src/components/LoadingButton/LoadingButton";

interface RequestPasswordFormState {
  email: string;
}

const validationSchema = Yup.object({
  email: ValidationRules.email(),
});

interface RequestPasswordFormProps extends BoxProps {
  buttonText: string;
  description: string;
  submitHandler: (email: string) => Promise<void>;
}

export const RequestPasswordForm: FC<RequestPasswordFormProps> = ({
  buttonText,
  description,
  submitHandler,
  ...props
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { userState } = useUser();
  const { setRequestPasswordErrors } = userState;
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [searchParams] = useSearchParams();
  const userEmail = searchParams.get("email");

  const initialValues: RequestPasswordFormState = {
    email: userEmail || "",
  };

  const handleSubmit = async (
    values: RequestPasswordFormState,
    actions: FormikHelpers<RequestPasswordFormState>,
  ) => {
    const { email } = values;
    await submitHandler(email);
    setEmailSubmitted(true);
    actions.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validateOnChange: true,
    validationSchema,
  });

  const emailErrors = setRequestPasswordErrors?.email;
  const isError = !!emailErrors && !formik.dirty;

  return (
    <Box {...props}>
      {emailSubmitted ? (
        <Typography variant="h3" color={theme.palette.common.white}>
          {t("auth.resetMailSent")}
        </Typography>
      ) : (
        <form noValidate onSubmit={formik.handleSubmit}>
          <Typography
            color={theme.palette.secondary.main}
            variant="body1"
            sx={{ mb: 4, px: 4 }}
          >
            {description}
          </Typography>

          <Stack spacing={2}>
            <TextField
              error={
                !!(
                  formik.touched.email &&
                  formik.errors.email &&
                  formik.values.email
                )
              }
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label={t("auth.email")}
              name="email"
              onBlur={() => {
                formik.handleBlur;
              }}
              onChange={formik.handleChange}
              placeholder={t("auth.placeholderMail")}
              type="email"
              value={formik.values.email}
              variant="filled"
            />
          </Stack>

          {isError && (
            <Box>
              {emailErrors?.map((error) => (
                <Typography
                  color={theme.palette.common.white}
                  key={error}
                  marginTop={2}
                  align="left"
                >
                  {error}
                </Typography>
              ))}
            </Box>
          )}

          <LoadingButton
            loading={formik.isSubmitting}
            fullWidth
            size="large"
            sx={{ mt: 2, py: 2 }}
            type="submit"
            variant="contained"
          >
            {buttonText}
          </LoadingButton>
        </form>
      )}
    </Box>
  );
};

export default RequestPasswordForm;
