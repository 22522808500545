import type { FC, ReactNode } from "react";
import { Box } from "@mui/material";

import { useStyles } from "./DefaultLayout.styles";

export interface DefaultLayoutProps {
  children?: ReactNode;
}

export const DefaultLayout: FC<DefaultLayoutProps> = ({ children }) => {
  const { classes, cx } = useStyles();
  return (
    <Box className={cx(classes.root)}>
      <Box className={cx(classes.content)}>{children}</Box>
    </Box>
  );
};

export default DefaultLayout;
