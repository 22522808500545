import { Theme, Typography } from "@mui/material";
import { TFunction } from "i18next";

import { ContractPhase, DistributorContract } from "src/types/contract";
import {
  DistributorPartner,
  DistributorPartnerKeyFigures,
} from "src/types/distributor";
import { getContractStatusColor } from "src/utils/contract";
import { formatCurrencyNumber } from "src/utils/formatTexts";
import {
  CellType,
  Row,
  TableHeader,
} from "src/components/DataTable/DataTable.types";

const getPhaseLabel = (
  contract: DistributorContract,
  t: TFunction<"translation", undefined>,
) =>
  contract.phase in ContractPhase &&
  t(`contracts.contractPhase.${contract.phase}`);

export const getTableHeaders = (
  t: TFunction<"translation", undefined>,
): TableHeader[] => [
  {
    id: "productLabel",
    label: t("contracts.distributorContracts.name"),
    width: 200,
  },
  {
    id: "acquisitionDate",
    label: t("contracts.distributorContracts.acquired"),
    width: 100,
  },
  {
    id: "totalPurchaseAmount",
    label: t("contracts.distributorContracts.currentOrderTotal"),
    width: 150,
  },
  {
    id: "lastDepositValueDate",
    label: t("contracts.distributorContracts.lastPayIn"),
    width: 150,
  },
  {
    id: "totalPaidFees",
    label: t("contracts.distributorContracts.feesPaid"),
    width: 150,
  },
  {
    id: "phase",
    label: t("contracts.distributorContracts.phase"),
    width: 150,
  },
  {
    id: "depositRate",
    label: t("contracts.distributorContracts.depositRate"),
    width: 150,
  },
];

export const getTableRows = ({
  contracts,
  t,
  theme,
}: {
  contracts: DistributorContract[] | undefined;
  t: TFunction<"translation", undefined>;
  theme: Theme;
}): Row[] =>
  contracts?.map((contract: DistributorContract) => ({
    cells: [
      {
        cellType: CellType.TITLE,
        subtitle: contract.customerLabel,
        title: contract.productLabel,
      },
      {
        cellType: CellType.TEXT,
        text: contract.acquisitionDate && contract.acquisitionDate,
      },
      {
        cellType: CellType.MONEY_RATIO,
        currency: contract.currency,
        currentAmount: contract.totalPurchaseAmount,
        targetAmount: contract.orderTotal,
      },
      {
        amount: contract.lastDepositMoneyAmount,
        cellType: CellType.TRANSACTION,
        currency: contract.currency,
        date: contract.lastDepositValueDate && contract.lastDepositValueDate,
      },
      {
        cellType: CellType.MONEY_RATIO,
        currency: contract.currency,
        currentAmount: contract.totalPaidFees,
        targetAmount: contract.contractFees,
      },
      {
        cellType: CellType.CHIP,
        color: getContractStatusColor(contract.status, theme),
        text: getPhaseLabel(contract, t),
      },
      {
        cellType: CellType.PROGRESS,
        progress: contract.depositRate,
      },
    ],
    id: contract.contractNumber,
  })) || [];

export const getDistributorDetailsStructuredData = ({
  data,
  t,
  theme,
}: {
  data: DistributorPartner | undefined;
  t: TFunction<"translation", undefined>;
  theme: Theme;
}) => [
  {
    label: t("common.name"),
    value: data?.distributorLabel || "-",
  },
  {
    label: t("distributors.customers.address"),
    value:
      `${data?.street && data.street + ","} ${data?.zipCode} ${data?.town}` ||
      "-",
  },
  {
    label: t("common.phone"),
    value: (
      <Typography
        color={theme.palette.primary.dark}
        component="a"
        href={`tel:-${data?.mobilePhone || data?.phone}`}
        sx={{ textDecoration: "none" }}
      >
        {data?.mobilePhone || data?.phone || "-"}
      </Typography>
    ),
  },
  {
    label: t("common.email"),
    value: (
      <Typography
        color={theme.palette.primary.dark}
        component="a"
        href={`mailto:${data?.email}`}
        sx={{ textDecoration: "none" }}
      >
        {data?.email || "-"}
      </Typography>
    ),
  },
];

export const getKeyFiguresStructuredData = ({
  data,
  t,
}: {
  data: DistributorPartnerKeyFigures | undefined;
  t: TFunction<"translation", undefined>;
}) => [
  {
    // Anzahl der abgeschlossenen Verträge
    label: t("distributors.details.concludedContracts"),
    value: data?.sumContracts,
  },
  {
    // Abgeschlossene Gebühren
    label: t("distributors.details.concludedFees"),
    value: formatCurrencyNumber(data?.sumContractFees, "EUR"),
  },
  {
    // Abgeschlossene Kaufsumme
    label: t("distributors.details.concludedOrderTotal"),
    value: formatCurrencyNumber(data?.sumOrderTotal, "EUR"),
  },
  {
    // Abgeschlossener Monatsbeitrag
    label: t("distributors.details.concludedContribution"),
    value: formatCurrencyNumber(data?.sumContributionAmount, "EUR"),
  },
];
