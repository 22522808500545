import { useQuery } from "@tanstack/react-query";

import { partnerKeyFiguresForDistributorRequest } from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";

import { distributorPartnerKeys } from "./key";

interface QueryProps extends DefaultQueryProps {
  distributorId?: number;
  partnerId?: number;
  period?: string;
}

export const useDistributorPartnerKeyFiguresQuery = ({
  distributorId,
  partnerId,
  period,
  ...props
}: QueryProps) =>
  useQuery({
    queryFn: () =>
      partnerKeyFiguresForDistributorRequest({
        distributorId,
        partnerId,
        period,
      }),
    queryKey: [
      distributorPartnerKeys.distributorPartnerKeyFigures,
      distributorId,
      partnerId,
      period,
    ],
    refetchIntervalInBackground: true,
    ...props,
  });
