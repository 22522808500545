import i18n from "src/locales/i18nConfig";
import { paths } from "src/paths";

interface NavigationItem {
  externalLink?: boolean;
  icon: string;
  path: string;
  title: string;
}

interface NavigationSection {
  items: NavigationItem[];
  subheader?: string;
}

export const getCustomerNavigationElements = (): NavigationSection[] => [
  {
    items: [
      {
        icon: "dashboard",
        path: paths.index,
        title: i18n.t("dashboard.navigationTitle"),
      },
      {
        icon: "library_books",
        path: paths.contracts,
        title: i18n.t("contracts.navigationTitleCustomers"),
      },
    ],
  },
];

export const getDistributorNavigationElements = (): NavigationSection[] => [
  {
    items: [
      {
        icon: "dashboard",
        path: paths.index,
        title: i18n.t("dashboard.navigationTitle"),
      },
      {
        icon: "groups",
        path: paths.distributors,
        title: i18n.t("distributors.navigationTitle"),
      },
      {
        icon: "library_books",
        path: paths.contracts,
        title: i18n.t("contracts.navigationTitleDistributors"),
      },
      {
        icon: "assignment_ind",
        path: paths.customers,
        title: i18n.t("customers.navigationTitle"),
      },
      {
        icon: "download",
        path: paths.downloads,
        title: i18n.t("downloads.navigationNavTitle"),
      },
    ],
  },
];

export const NAVIGATION_DEFAULT_WIDTH = "280px"; // 280 is the default value from the Devias Kit.
