/* eslint-disable sort-keys-fix/sort-keys-fix */
import type { PaletteOptions } from "@mui/material/styles/createPalette";
import type { ThemeOptions } from "@mui/material/styles/createTheme";

import createCustomerPalette from "../customerConfig/createCustomerPalette";
import createDistributorPalette from "../distributorConfig/createDistributorPalette";
import { PaletteMode } from "..";

import { createComponents } from "./createComponents";
import { createShadows } from "./createShadows";
import { createTypography } from "./createTypography";

interface CreateOptionsProps {
  appTheme: PaletteMode;
  customPalette?: PaletteOptions;
}

export const createOptions = ({
  appTheme,
  customPalette,
}: CreateOptionsProps): ThemeOptions => {
  // If paletteOptions are passed into createOptions, this might be paletteOptions from the backend.
  // It is possible to use them for a custom CD and as WhiteLabel solution.
  const selectedTheme = () => {
    if (customPalette) {
      return customPalette;
    }

    if (appTheme === PaletteMode.distributorBaseTheme) {
      return createDistributorPalette();
    }

    return createCustomerPalette();
  };

  const palette = selectedTheme();
  const components = createComponents({ palette });
  const shadows = createShadows();
  const typography = createTypography();

  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1050, // Adjusted the md size to improve our layouts for large tablets
        lg: 1200,
        xl: 1440,
      },
    },
    borderRadius: {
      sm: 4,
      md: 8,
      lg: 12,
      round: 9999,
    },
    components,
    palette,
    shadows,
    typography,
  };
};
