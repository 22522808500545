import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Tab, Tabs } from "@mui/material";

import type { DistributorKeyFigures as DistributorKeyFiguresType } from "src/types/distributor";
import { TopTenOrdering } from "src/services/api/api";
import { useUser } from "src/services/contexts/userContext";
import { useDistributorKeyFiguresQuery } from "src/services/serverState/distribtor/useDistibutorKeyFigures";
import { useDistributorProvisionQuery } from "src/services/serverState/distribtor/useDistibutorProvision";
import { useDistributorTopTenQuery } from "src/services/serverState/distribtor/useDistibutorTopTen";
import CurrencySelection from "src/components/CurrencySelection/CurrencySelection";
import { KeyFiguresCard } from "src/components/KeyFiguresCard/KeyFiguresCard";
import LayoutContentHeader from "src/components/LayoutContentHeader/LayoutContentHeader";
import { Seo } from "src/components/Seo";
import WidgetWrapper from "src/components/WidgetWrapper/WidgetWrapper";

import DistributorContracts from "./DistributorWidgets/Distributor.Contracts/Distributor.Contracts";
import { DistributorKeyFigures } from "./DistributorWidgets/Distributor.KeyFigures/Distributor.KeyFigures";
import DistributorProvisionCard from "./DistributorWidgets/Distributor.ProvisionsCard/Distributor.ProvisionsCard";
import { DistributorTopTen } from "./DistributorWidgets/Distributor.TopTen/Distributor.TopTen";

interface DistributorDashboardPageProps {}

enum DISTRIBUTOR_DASHBOARD_PERIOD_FILTER {
  MONTH = "month",
  YEAR = "year",
}

export const DistributorDashboardPage: FC<DistributorDashboardPageProps> = ({
  ...props
}) => {
  const { userState } = useUser();
  const { t } = useTranslation();
  const [topTenSwitch, setTopTenSwitch] =
    useState<TopTenOrdering>("sumContractFees");
  const [selectedTab, setSelectedTab] = useState(
    DISTRIBUTOR_DASHBOARD_PERIOD_FILTER.MONTH,
  );

  // Get key figures data for the dashboard
  const { data: keyFigures, isLoading: keyFiguresIsLoading } =
    useDistributorKeyFiguresQuery({
      currency: userState.selectedCurrency,
      distributorId: userState.user?.activeDistributorProfile?.distributorId,
      period: selectedTab,
    });

  // Get provisions for the dashboard
  const { data: provisionData } = useDistributorProvisionQuery({
    currency: userState.selectedCurrency,
    distributorId: userState.user?.activeDistributorProfile?.distributorId,
  });

  // Get top ten data for the dashboard
  const { data: topTenData, isLoading: topTenIsLoading } =
    useDistributorTopTenQuery({
      currency: userState.selectedCurrency,
      distributorId: userState.user?.activeDistributorProfile?.distributorId,
      ordering: topTenSwitch,
      period: selectedTab,
    });

  const formattedKeyFiguresToArray = (keyFigures: DistributorKeyFiguresType) =>
    Object.keys(keyFigures).map((key) => ({
      label: key,
      ...keyFigures[key as keyof DistributorKeyFiguresType],
    }));

  return (
    <>
      <Seo title={t("common.dashboardTitle")} />
      <LayoutContentHeader
        headerTitle={
          userState.user?.firstName
            ? t("dashboard.welcomeHeaderWithName", {
                firstName: userState.user?.firstName,
                lastName: userState.user?.lastName,
              })
            : t("dashboard.welcomeHeader")
        }
        headerSubTitleSmall={new Intl.DateTimeFormat("de-DE").format(
          new Date(),
        )}
        headerWidget={<CurrencySelection />}
      />

      <Grid container {...props} spacing={2}>
        <Grid item xs={12} md={8} lg={8} alignSelf={"flex-end"}>
          <Tabs
            value={selectedTab}
            onChange={(_event, newValue) => setSelectedTab(newValue)}
          >
            <Tab value="month" label={t("dashboard.widgets.month")} />
            <Tab value="year" label={t("dashboard.widgets.year")} />
          </Tabs>
        </Grid>

        <Grid item xs={12} md={4}>
          <DistributorProvisionCard provisions={provisionData} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <KeyFiguresCard
            currencyCode={userState.selectedCurrency}
            isLoading={keyFiguresIsLoading}
            title={t("dashboard.widgets.sumContractFees")}
            value={keyFigures?.sumContractFees.currentPeriod.value}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <KeyFiguresCard
            currencyCode={userState.selectedCurrency}
            isLoading={keyFiguresIsLoading}
            title={t("dashboard.widgets.sumOrderTotal")}
            value={keyFigures?.sumOrderTotal.currentPeriod.value}
          />
        </Grid>

        <Grid item xs={12} md={7} lg={8}>
          <WidgetWrapper
            headerText={t("dashboard.widgets.myKeyFigures")}
            sx={{
              backgroundColor: "white",
              mb: 4,
            }}
          >
            {keyFigures && (
              <DistributorKeyFigures
                keyFiguresData={formattedKeyFiguresToArray(keyFigures)}
                isLoading={keyFiguresIsLoading}
              />
            )}
          </WidgetWrapper>
        </Grid>

        <Grid item xs={12} md={5} lg={4}>
          <WidgetWrapper
            actionTabsChoices={[
              {
                label: t("dashboard.widgets.contractSummary.PaymentFees"),
                value: "sumContractFees",
              },
              {
                label: t("dashboard.widgets.contractSummary.sum"),
                value: "sumOrderTotals",
              },
            ]}
            headerText={t("dashboard.widgets.topTen")}
            sx={{ mb: 4 }}
            onActionSwitch={(tabValue) =>
              setTopTenSwitch(tabValue as TopTenOrdering)
            }
          >
            {topTenData && (
              <DistributorTopTen
                topTenPartners={topTenData}
                isLoading={topTenIsLoading}
                selectedKey={topTenSwitch}
              />
            )}
          </WidgetWrapper>
        </Grid>

        <Grid item xs={12}>
          <WidgetWrapper
            headerText={t("dashboard.widgets.myLastContracts")}
            sx={{
              backgroundColor: "white",
              mb: 4,
            }}
          >
            <DistributorContracts />
          </WidgetWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default DistributorDashboardPage;
