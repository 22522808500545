import { useQuery } from "@tanstack/react-query";

import { customerDetailForDistributorRequest } from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";

import { customerKeys } from "./key";

interface QueryProps extends DefaultQueryProps {
  customerNumber?: string;
}

export const useCustomerDetailQuery = ({
  customerNumber,
  ...props
}: QueryProps) =>
  useQuery({
    queryFn: () =>
      customerDetailForDistributorRequest({
        customerNumber,
      }),
    queryKey: [customerKeys.customerDetail, customerNumber],
    refetchIntervalInBackground: true,
    ...props,
  });
