import type { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  Grid,
  GridProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { DistributorProvision } from "src/types/distributor";
import { formatCurrencyNumber } from "src/utils/formatTexts";
import { useUser } from "src/services/contexts/userContext";

interface DistributorProvisionCardProps extends GridProps {
  provisions?: DistributorProvision;
}

export const DistributorProvisionCard: FC<DistributorProvisionCardProps> = ({
  provisions,
  ...props
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const { userState } = useUser();

  return (
    <Grid
      display="flex"
      sx={{
        backgroundImage: theme.palette.gradients.panel,
        width: smUp ? "auto" : "100%",
      }}
      py={3}
      px={2}
      borderRadius={1}
      {...props}
      justifyContent="space-evenly"
    >
      <Box>
        <Typography mb={1} color="primary" variant="body1" fontWeight="bold">
          {t("dashboard.widgets.provision.commissionClaim")}
        </Typography>

        <Typography color="primary" variant="h2">
          {formatCurrencyNumber(
            provisions?.commissionClaim,
            userState.selectedCurrency,
          )}
        </Typography>
      </Box>

      <Box mx={2}>
        <Divider
          orientation={"vertical"}
          sx={{
            borderColor: theme.palette.common.white,
            width: "2px",
          }}
        />
      </Box>

      <Box>
        <Typography mb={1} color="primary" variant="body1" fontWeight="bold">
          {t("dashboard.widgets.provision.depositRate")}
        </Typography>

        <Typography color="primary" variant="h2">
          {provisions?.depositRate ? `${provisions.depositRate}%` : "-"}
        </Typography>
      </Box>
    </Grid>
  );
};

export default DistributorProvisionCard;
