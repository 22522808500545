import type { FC } from "react";

import { AuthGuard } from "src/guards/AuthGuard/AuthGuard";

export const withAuthGuard = <P extends object>(Component: FC<P>): FC<P> =>
  function WithAuthGuard(props: P) {
    return (
      <AuthGuard>
        <Component {...props} />
      </AuthGuard>
    );
  };
