import { useQuery } from "@tanstack/react-query";

import { partnerDetailsForDistributorRequest } from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";

import { distributorPartnerKeys } from "./key";

interface QueryProps extends DefaultQueryProps {
  distributorId?: number;
  partnerId?: number;
}

export const useDistributorPartnerDetailQuery = ({
  distributorId,
  partnerId,
  ...props
}: QueryProps) =>
  useQuery({
    queryFn: () =>
      partnerDetailsForDistributorRequest({
        distributorId,
        partnerId,
      }),
    queryKey: [
      distributorPartnerKeys.distributorPartnerDetail,
      distributorId,
      partnerId,
    ],
    refetchIntervalInBackground: true,
    ...props,
  });
