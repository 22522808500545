import { TROY_OUNCE_TO_GRAM_FACTOR } from "src/constants";
import { Commodity } from "src/types/contract";
import { StockPrice } from "src/types/stockPrice";

/**
 * Calculates the price value of a commodity.
 * The calculated value needs the unit "gram", so all price values will be in "g".
 * Units must be recalculated in grams.
 */
export const calculateCurrentCommodityStockPriceValue = ({
  commoditySymbol,
  amount,
  stockPriceList,
  unit,
}: {
  commoditySymbol: string;
  amount: number;
  stockPriceList: StockPrice[];
  unit: "g" | "oz";
}): number => {
  const stockPrice = stockPriceList.find(
    (stockPrice) => stockPrice.commodity === commoditySymbol,
  );

  let commodityAmount = amount;
  if (unit === "oz") {
    commodityAmount = amount * TROY_OUNCE_TO_GRAM_FACTOR;
  }

  if (stockPrice) {
    return Number(stockPrice.priceGross) * commodityAmount;
  }

  return 0;
};

/**
 * Calculates the price value of commodity list.
 * The calculated value needs the unit "gram", so all price values will be in "g".
 * Units must be recalculated in grams.
 */
export const calculateCurrentContractStockPriceValue = ({
  commodities,
  stockPriceList,
}: {
  commodities: Commodity[];
  stockPriceList: StockPrice[];
}): number => {
  let contractStockPriceValue = 0;

  commodities.forEach((commodity) => {
    const stockPrice = stockPriceList.find(
      (stockPrice) => stockPrice.commodity === commodity.name,
    );

    let commodityAmount = commodity.totalAmount;
    if (commodity.unit === "oz") {
      commodityAmount = commodity.totalAmount * TROY_OUNCE_TO_GRAM_FACTOR;
    }

    if (stockPrice) {
      const price = Number(stockPrice.priceGross);
      contractStockPriceValue += price * commodityAmount;
    }
  });

  return contractStockPriceValue;
};
