import { type FC } from "react";
import { useTranslation } from "react-i18next";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {
  Box,
  Card,
  CardProps,
  Chip,
  Icon,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { formatCurrencyNumber } from "src/utils/formatTexts";
import InfoTooltip from "src/components/InfoTooltip/InfoTooltip";
import NoData from "src/components/NoData/NoData";

import LinearProgressWithLabel from "../LinearProgressWithLabel/LinearProgressWithLabel";

import DataTableSkeleton from "./DataTable.skeleton";
import {
  Cell,
  CellType,
  ChipCell,
  MoneyRatioCell,
  ProgressCell,
  Row,
  TableHeader,
  TableOrder,
  TextCell,
  TitleCell,
  TransactionCell,
  TrendCell,
} from "./DataTable.types";

import { useStyles } from "./DataTable.styles";
interface TableProps extends CardProps {
  currencyCode?: string;
  currentPage?: number;
  dataTableHeight?: number | "0px" | "auto";
  dataTableMaxHeight?: number | "auto";
  renderPagination?: boolean;
  headers: TableHeader[];
  loading?: boolean;
  onOrderChange?: (headerId: string) => void;
  onPageChange?: (newPage: number) => void;
  onSearchValueChange?: (value: string) => void;
  OnTableRowClick?: (row: number | string) => void;
  order?: TableOrder;
  rows: Row[];
  rowsPerPage?: number;
  searchLabel?: string;
  searchValue?: string;
  totalResults?: number;
}

/**
 * This React component is a data table that displays information in a tabular format.
 * It is designed to be flexible and customizable, allowing you to specify the headers,
 * rows, and other options.
 *
 * @param currencyCode: The currency used for all money data displayed in the table.
 * @param currentPage: The current page number (used for pagination).
 * @param dataTableHeight: The height of the data table.
 * @param dataTableMaxHeight: The maximum height of the data table.
 * @param renderPagination: Disables pagination.
 * @param headers: An array of objects defining the table headers (including id, label, width, and optional tooltip).
 * @param loading: Whether the data is still loading.
 * @param onOrderChange: A function to handle a header click for sorting.
 * @param onPageChange: A function to handle page changes.
 * @param onSearchValueChange: A function to handle changes in the search input.
 * @param OnTableRowClick: A function to handle clicks on table rows.
 * @param order: The current sorting order.
 * @param rows: An array of objects representing the table rows (including id and an array of cells).
 * @param rowsPerPage: The number of rows to display per page.
 * @param searchLabel: The label for the search input.
 * @param searchValue: The current search value.
 * @param totalResults: The total number of results (used for pagination).
 *
 * @returns different types of cells based on their cellType property:
 * renderTitleCell: Renders a title cell with an optional subtitle.
 * renderTextCell: Renders a simple text cell.
 * renderMoneyRatioCell: Renders a cell displaying two monetary amounts.
 * renderTransactionCell: Renders a cell displaying a monetary amount with an optional date.
 * renderChipCell: Renders a text cell within a colored chip.
 * renderProgressBarCell: Renders a progress bar.
 * renderTrendCell: Renders a trend icon (up, down, or equal).
 */
export const DataTable: FC<TableProps> = ({
  currencyCode = "EUR",
  currentPage,
  dataTableHeight: dataTableHeight = "0px",
  dataTableMaxHeight: dataTableMaxHeight = "auto",
  renderPagination = true,
  OnTableRowClick,
  headers,
  loading,
  onOrderChange,
  onPageChange,
  onSearchValueChange,
  order,
  rows,
  rowsPerPage = 50,
  searchLabel,
  searchValue,
  totalResults,
  ...props
}) => {
  const theme = useTheme();
  const { classes, cx } = useStyles({ rowIsClickable: !!OnTableRowClick });
  const { t } = useTranslation();
  const { orderBy, orderDirection } = order || {};
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  // Display text cell with title and subtitle
  const renderTitleCell = ({ title, subtitle }: TitleCell) => (
    <>
      <Typography
        variant="h3"
        component="p"
        color={theme.palette.textColorDark}
        mb={0.5}
        className={classes.overflow}
      >
        {title}
      </Typography>

      {subtitle && (
        <Typography variant="body1" className={classes.overflow}>
          {subtitle}
        </Typography>
      )}
    </>
  );

  // Display simple text cell
  const renderTextCell = ({ text }: TextCell) => (
    <Typography
      color={theme.palette.textColorDark}
      className={classes.overflow}
    >
      {text || "-"}
    </Typography>
  );

  // Display money amount of total amount (e.g. 250 € / 900 €)
  const renderMoneyRatioCell = ({
    currentAmount,
    currency,
    targetAmount,
  }: MoneyRatioCell) => (
    <>
      <Typography component="span" color={theme.palette.textColorDark}>
        {formatCurrencyNumber(currentAmount, currency || currencyCode)} /
      </Typography>
      <Typography component="span">
        {formatCurrencyNumber(targetAmount, currency || currencyCode)}
      </Typography>
    </>
  );

  // Display a money amount with a date (e.g. 22 € (28.09.2023))
  const renderTransactionCell = ({
    amount,
    currency,
    date,
  }: TransactionCell) => (
    <>
      {amount ? (
        <Typography component="span" color={theme.palette.textColorDark}>
          {formatCurrencyNumber(amount, currency || currencyCode)}
        </Typography>
      ) : (
        "-"
      )}

      {date && <Typography component="span"> ({date})</Typography>}
    </>
  );

  // Display text in a colored chip (e.g. NEU)
  const renderChipCell = ({ color, text }: ChipCell) =>
    text && (
      <Chip
        label={text}
        size="small"
        sx={{
          backgroundColor: color[50],
          color: color[500],
          fontSize: "1em",
        }}
      />
    );

  // Display progress bar (e.g. 50%)
  const renderProgressBarCell = ({ progress }: ProgressCell) => (
    <LinearProgressWithLabel value={progress} />
  );

  // Display Trend icon ("up" | "down" | "equal")
  const renderTrendCell = ({ trendIndicator }: TrendCell) => (
    <Box
      className={cx(classes.trendArrow, {
        [classes.trendArrowGreen]: (trendIndicator as string) === "up",
        [classes.trendArrowRed]: (trendIndicator as string) === "down",
        [classes.trendArrowGray]: (trendIndicator as string) === "equal",
      })}
    >
      <SvgIcon>
        {trendIndicator === "up" && <TrendingUpIcon />}
        {trendIndicator === "down" && <TrendingDownIcon />}
        {trendIndicator === "equal" && <TrendingFlatIcon />}
      </SvgIcon>
    </Box>
  );

  const renderCell = (cell: Cell) => {
    switch (cell.cellType) {
      case CellType.TEXT:
        return renderTextCell(cell);
      case CellType.TITLE:
        return renderTitleCell(cell);
      case CellType.MONEY_RATIO:
        return renderMoneyRatioCell(cell);
      case CellType.CHIP:
        return renderChipCell(cell);
      case CellType.TRANSACTION:
        return renderTransactionCell(cell);
      case CellType.PROGRESS:
        return renderProgressBarCell(cell);
      case CellType.TREND:
        return renderTrendCell(cell);
      default:
        return null;
    }
  };

  return (
    <Card
      className={classes.cardContainer}
      sx={{ marginBottom: theme.spacing(2) }}
      {...props}
    >
      {onSearchValueChange && (
        <Box p={2}>
          <TextField
            size={mdUp ? "medium" : "small"}
            placeholder={searchLabel}
            sx={{ background: theme.palette.common.white }}
            variant="outlined"
            fullWidth
            onChange={(event) =>
              onSearchValueChange && onSearchValueChange(event.target.value)
            }
            value={searchValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <Icon
                      children="search"
                      sx={{ color: theme.palette.textColorLight }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}

      <TableContainer
        style={{
          flexGrow: 1,
          height: dataTableHeight,
          maxHeight: dataTableMaxHeight,
        }}
      >
        <Table stickyHeader>
          <TableHead
            sx={{
              backgroundColor: theme.palette.grey[50],
              textTransform: "capitalize",
            }}
          >
            <TableRow sx={{ textTransform: "uppercase" }}>
              {headers.map(({ id, label, width = "auto", tooltip }) => (
                <TableCell
                  size="small"
                  key={id}
                  sx={{
                    fontWeight: "bold",
                    maxWidth: `${width}px`,
                    minWidth: `${width}px`,
                  }}
                >
                  <Box display="flex" alignItems="center">
                    {onOrderChange && (
                      <TableSortLabel
                        active={orderBy === id}
                        direction={orderBy === id ? orderDirection : "asc"}
                        onClick={() => onOrderChange && onOrderChange(id)}
                      >
                        {label}
                      </TableSortLabel>
                    )}

                    {!onOrderChange && label}

                    {tooltip && <InfoTooltip infoText={tooltip} />}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ backgroundColor: theme.palette.common.white }}>
            {loading && (
              <DataTableSkeleton
                rows={rows.length || 20}
                cells={headers.length}
                className={classes.skeleton}
              />
            )}

            {!loading && rows.length < 1 && (
              <tr>
                <td>
                  <NoData />
                </td>
              </tr>
            )}

            {!loading &&
              rows?.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  className={classes.tableRow}
                  onClick={() => OnTableRowClick && OnTableRowClick(row.id)}
                >
                  {row.cells.map((cell, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        maxWidth: `${headers[index].width}px`,
                        whiteSpace: "nowrap",
                      }}
                      className={classes.overflow}
                    >
                      {renderCell(cell)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {onPageChange && renderPagination && (
        <TablePagination
          component="div"
          count={totalResults || 0}
          page={currentPage || 0}
          onPageChange={(_, page) => onPageChange(page)}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) =>
            count === 0 ? "" : t("common.table.entries", { count, from, to })
          }
        />
      )}
    </Card>
  );
};

export default DataTable;
