import { useInfiniteQuery } from "@tanstack/react-query";

import { ContractBooking } from "src/types/contract";
import {
  contractsBookingsRequest,
  CursorPaginatedResponse,
} from "src/services/api/api";
import { DefaultQueryProps } from "src/services/serverState/queryClient";

import { contractBookingKeys } from "./key";

interface ContractsBookingListQueryProps extends DefaultQueryProps {
  customerNumber?: string;
  distributorId?: number;
  filterContractBookingsBy: string;
  includeNested: boolean;
}

export const useInfiniteContractsBookingListForDistributorQuery = ({
  customerNumber,
  distributorId,
  includeNested,
  filterContractBookingsBy,
  ...props
}: ContractsBookingListQueryProps) =>
  useInfiniteQuery<CursorPaginatedResponse<ContractBooking>, Error>({
    queryFn: ({ pageParam }) =>
      contractsBookingsRequest({
        customerNumber,
        distributorId,
        filterContractBookingsBy,
        includeNested,
        pageParam: pageParam as string,
      }),
    queryKey: [
      contractBookingKeys.distributorsContractsBookingList,
      filterContractBookingsBy,
      customerNumber,
      includeNested,
      distributorId,
    ],
    refetchIntervalInBackground: true,
    ...props,
    getNextPageParam: (lastPage) => lastPage.next ?? undefined,
    getPreviousPageParam: (firstPage) => firstPage.next ?? undefined,
    initialPageParam: 0,
  });
