import { keyframes } from "tss-react";
import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.quartaer.main,
    fontSize: "0.8rem",
    fontWeight: "bold",
    height: theme.spacing(5),
    width: theme.spacing(5),

    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
  },
  divider: {
    position: "absolute",
    top: 0,
    width: 0,
  },

  nameCell: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },

  overflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  skeleton: {
    animation: `${keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100;
    }
    `} 0.5s`,
  },

  tableRow: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      cursor: "pointer",
    },
  },

  title: {
    color: theme.palette.textColorDark,
  },
}));
