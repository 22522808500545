import { type FC, useState } from "react";
import { Tab, Tabs, TabsProps } from "@mui/material";

interface ActionTabsProps extends TabsProps {
  onTabSwitch: (tabValue: string) => void;
  tabs: { value: string; label: string }[];
}

export const ActionTabs: FC<ActionTabsProps> = ({
  onTabSwitch,
  tabs,
  ...props
}) => {
  const [tabValue, setTabValue] = useState(undefined);
  const initialTabValue = tabs.length > 0 ? tabs[0].value : "";

  return (
    <Tabs
      allowScrollButtonsMobile
      scrollButtons="auto"
      value={!tabValue ? initialTabValue : tabValue}
      variant="scrollable"
      onChange={(_, newTabValue) => {
        setTabValue(newTabValue);
        onTabSwitch(newTabValue);
      }}
      {...props}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.value}
          label={tab.label}
          sx={{ minWidth: "auto", padding: 1, textTransform: "none" }}
          value={tab.value}
        />
      ))}
    </Tabs>
  );
};

export default ActionTabs;
