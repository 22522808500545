import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { useUser } from "src/services/contexts/userContext";
import DataTable from "src/components/DataTable/DataTable";
import {
  CellType,
  Row,
  TableHeader,
} from "src/components/DataTable/DataTable.types";

type FormattedKeyFigureArray = Array<{
  label: string;
  currentPeriod: {
    period: string | number;
    value: number;
  };
  previousPeriod: {
    period: string | number;
    value: number;
  };
  trend: "up" | "down" | "equal";
}>;

interface DistributorKeyFiguresProps {
  keyFiguresData: FormattedKeyFigureArray;
  isLoading: boolean;
}

export const DistributorKeyFigures: FC<DistributorKeyFiguresProps> = ({
  keyFiguresData,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation();
  const { userState } = useUser();

  const previousPeriod = keyFiguresData[0].previousPeriod.period;
  const currentPeriod = keyFiguresData[0].currentPeriod.period;

  const tableHeaders: TableHeader[] = [
    {
      id: "name",
      label: "Name",
      width: 200,
    },
    {
      id: "PreviousPeriod",
      label:
        typeof previousPeriod === "number"
          ? previousPeriod.toString()
          : t(`common.monthNames.${previousPeriod}`),
      width: 200,
    },
    {
      id: "period",
      label:
        typeof currentPeriod === "number"
          ? currentPeriod.toString()
          : t(`common.monthNames.${currentPeriod}`),
      width: 200,
    },
    {
      id: "trend",
      label: "Trend",
      tooltip: t("dashboard.widgets.keyFigures.trendTooltip"),
      width: 120,
    },
  ];

  const rows: Row[] =
    keyFiguresData?.map((keyFigure) => ({
      cells: [
        {
          cellType: CellType.TITLE,
          title: t(`dashboard.widgets.${keyFigure.label}`),
        },
        {
          amount: keyFigure.previousPeriod.value,
          cellType: CellType.TRANSACTION,
        },
        {
          amount: keyFigure.currentPeriod.value,
          cellType: CellType.TRANSACTION,
        },
        {
          cellType: CellType.TREND,
          trendIndicator: keyFigure.trend,
        },
      ],
      id: keyFigure.label,
    })) || [];

  return (
    <DataTable
      currencyCode={userState.selectedCurrency}
      dataTableHeight="auto"
      headers={tableHeaders}
      loading={isLoading}
      rows={rows}
      sx={{ marginBottom: "0!important" }} // Have to use important
      {...props}
    />
  );
};
