import type { FC } from "react";

import IconOther from "/assets/icons//icon-other.svg";
import IconJpg from "/assets/icons/icon-jpg.svg";
import IconMp4 from "/assets/icons/icon-mp4.svg";
import IconPdf from "/assets/icons/icon-pdf.svg";
import IconPng from "/assets/icons/icon-png.svg";
import IconSvg from "/assets/icons/icon-svg.svg";

export type FileTypes =
  | "image/jpeg"
  | "image/png"
  | "video/mp4"
  | "application/pdf"
  | string;

const icons: Record<FileTypes, string> = {
  "application/pdf": IconPdf,
  "image/jpeg": IconJpg,
  "image/png": IconPng,
  "image/svg": IconSvg,
  "video/mp4": IconMp4,
};

interface FileIconProps {
  fileType?: FileTypes | null;
}

export const FileIcon: FC<FileIconProps> = ({ fileType, ...props }) => {
  let icon = IconOther;

  if (fileType) {
    icon = icons[fileType] || IconOther;
  }

  return <img src={icon} {...props} />;
};

export default FileIcon;
