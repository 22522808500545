/* eslint-disable sort-keys-fix/sort-keys-fix */

import { tss } from "tss-react/mui";

import Background from "/assets/login/gg_bg_login_large_screens.svg";

export const useStyles = tss.create(({ theme }) => ({
  box: {
    textAlign: "center",
  },

  card: {
    backgroundImage: theme.palette.gradients.dark,
    padding: theme.spacing(2),
  },

  wrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    minHeight: "100vh",

    // Background Image
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));
