import { tss } from "tss-react/mui";

export const useStyles = tss.create(() => ({
  loadingAndError: {
    alignItems: "center",
    display: "flex",
    flexGrow: "inherit",
    justifyContent: "center",
  },
}));
