import {
  Contract,
  ContractBooking,
  Customer,
  DistributorContract,
} from "src/types/contract";
import { Commodity } from "src/types/contract";
import {
  DistributionNumberWithProfile,
  DistributorKeyFigures,
  DistributorPartnerKeyFigures,
  DistributorProvision,
  DistributorTopTen,
} from "src/types/distributor";
import { DistributorPartner } from "src/types/distributor";
import { DistributorDownloadItems } from "src/types/downloads";
import { StockPrice } from "src/types/stockPrice";
import { TableOrder } from "src/components/DataTable/DataTable.types";

import { AXI } from "./axi";

export interface CursorPaginatedResponse<T> {
  next: string | null;
  previous: string | null;
  results: T[];
}

interface NumberPaginatedResponse<T> {
  totalCount: number;
  results: T[];
}

// Fetch a single contract of the customer with given contract number for his customerProfile
export const contractForCustomerRequest = (
  contractNumber: string,
): Promise<Contract> =>
  AXI.get(`/customers/contracts/${contractNumber}/`).then(
    (response) => response.data,
  );

// Fetch all contracts of the user for his customerProfile
export const contractsForCustomerRequest = (
  customerNumber?: number,
): Promise<Contract[]> =>
  AXI.get(`/customers/${customerNumber}/contracts/`).then(
    (response) => response.data.results,
  );

// Fetch a single contract of the distributor with given contract number for his contractDetailView
export const contractForDistributorRequest = (
  contractNumber: string,
): Promise<DistributorContract> =>
  AXI.get(`distributors/contracts/${contractNumber}/`).then(
    (response) => response.data,
  );

export const contractsForDistributorFromCustomerRequest = ({
  customerNumber,
  distributorId,
  includeNested,
  order,
  page,
}: {
  customerNumber: string | number;
  distributorId: string | number;
  includeNested: boolean; // True if distributor is distribution company
  order?: TableOrder;
  page?: number;
}): Promise<NumberPaginatedResponse<DistributorContract>> =>
  AXI.get(`/distributors/${distributorId}/contracts/`, {
    params: {
      customerNumber: customerNumber,
      includeNested,
      ordering: order?.orderBy
        ? `${order.orderDirection === "desc" ? "-" : ""}${order.orderBy}`
        : undefined,
      page: (page || 0) + 1,
    },
  }).then((response) => ({
    results: response.data.results,
    totalCount: response.data.count,
  }));

// Fetch all contracts of the user that are connected to his distributorProfile
export const contractsForDistributorRequest = ({
  distributorId,
  includeNested,
  order,
  page,
  searchValue,
}: {
  distributorId?: number;
  includeNested: boolean; // True if distributor is distribution company
  order?: TableOrder;
  page?: number;
  searchValue?: string;
}): Promise<NumberPaginatedResponse<DistributorContract>> =>
  AXI.get(`/distributors/${distributorId}/contracts/`, {
    params: {
      includeNested,
      ordering: order?.orderBy
        ? `${order.orderDirection === "desc" ? "-" : ""}${order.orderBy}`
        : undefined,
      page: (page || 0) + 1,
      search: searchValue || undefined,
    },
  }).then((response) => ({
    results: response.data.results,
    totalCount: response.data.count,
  }));

// Fetch all contracts of the user that are connected to his distributorProfile
export const contractsForDistributorPartnerRequest = ({
  distributorId,
  includeNested,
  order,
  page,
  partnerId,
  searchValue,
}: {
  distributorId?: number;
  includeNested: boolean; // True if distributor is distribution company
  order?: TableOrder;
  page?: number;
  partnerId?: number;
  searchValue?: string;
}): Promise<NumberPaginatedResponse<DistributorContract>> =>
  AXI.get(`/distributors/${distributorId}/partner-contracts/`, {
    params: {
      includeNested,
      ordering: order?.orderBy
        ? `${order.orderDirection === "desc" ? "-" : ""}${order.orderBy}`
        : undefined,
      page: (page || 0) + 1,
      partnerId,
      search: searchValue || undefined,
    },
  }).then((response) => ({
    results: response.data.results,
    totalCount: response.data.count,
  }));

interface ContractBookingsRequestProps {
  contractNumber: string;
  customerNumber?: number | undefined;
  distributorId?: number | undefined;
  includeNested: boolean; // True if distributor is distribution company
  filterContractBookingsBy: string;
  pageParam: string;
}

// Fetch all customers of the user that are connected to his distributorProfile
export const customersForDistributorRequest = ({
  distributorId,
  includeNested,
  order,
  page,
  searchValue,
}: {
  distributorId?: number;
  includeNested: boolean; // True if distributor is distribution company
  order?: TableOrder;
  page?: number;
  searchValue?: string;
}): Promise<NumberPaginatedResponse<Customer>> =>
  AXI.get(`/distributors/${distributorId}/customers/`, {
    params: {
      includeNested,
      ordering: order?.orderBy
        ? `${order.orderDirection === "desc" ? "-" : ""}${order.orderBy}`
        : undefined,
      page: (page || 0) + 1,
      search: searchValue || undefined,
    },
  }).then((response) => ({
    results: response.data.results,
    totalCount: response.data.count,
  }));

// Fetch one customer of the user that is connected to his distributorProfile
export const customerDetailForDistributorRequest = ({
  customerNumber,
}: {
  customerNumber: string | undefined;
}) =>
  AXI.get<Customer>(`/customers/${customerNumber}/`).then(
    (response) => response.data,
  );

// Fetch all contract bookings of the user for one contract with given contract number
export const contractsBookingsForContractRequest = async ({
  contractNumber,
  customerNumber,
  distributorId,
  includeNested,
  filterContractBookingsBy,
  pageParam,
}: ContractBookingsRequestProps) => {
  const customerUrl = `customers/${customerNumber}/bookings/`;
  const distributorUrl = `distributors/${distributorId}/bookings/`;

  const url = customerNumber ? customerUrl : distributorUrl;

  const payload = {
    bookingType: filterContractBookingsBy,
    contractNumber: contractNumber,
    count: 10,
    cursor: pageParam !== "0" ? pageParam : "",
    includeNested,
  };

  const { data: paginatedContractBookings } = await AXI.get<
    CursorPaginatedResponse<ContractBooking>
  >(url, { params: { ...payload } });

  return paginatedContractBookings;
};

interface ContractListBookingsRequestProps {
  customerNumber?: number | string;
  distributorId?: number;
  filterContractBookingsBy?: string;
  includeNested: boolean;
  pageParam: string | number;
}

// Fetch all contract bookings of the user for all his/her contracts.
export const contractsBookingsRequest = async ({
  customerNumber,
  distributorId,
  filterContractBookingsBy,
  includeNested, // True if distributor is distribution company
  pageParam,
}: ContractListBookingsRequestProps) => {
  const customerUrl = `/customers/${customerNumber}/bookings/`;
  const distributorUrl = `/distributors/${distributorId}/bookings/`;

  const url = distributorId ? distributorUrl : customerUrl;

  const payload = {
    bookingType: filterContractBookingsBy,
    count: 15,
    cursor: pageParam !== 0 ? pageParam : "",
    customerNumber: distributorId ? customerNumber : undefined,
    includeNested,
  };

  const { data: paginatedContractBookings } = await AXI.get<
    CursorPaginatedResponse<ContractBooking>
  >(url, { params: { ...payload } });

  return paginatedContractBookings;
};

interface CommodityAveragePriceResult {
  name: string; // e.g. AU, PD...
  currency: string; // e.g. EUR, USD
  unit: string; // g or oz
  avgPrice: number; // e.g. 1.5
}

interface CommodityStockResult {
  name: string; // e.g. AU, PD...
  unit: string; // g or oz
  totalAmount: number; // eg. 74
}

interface CommoditiesRequestResult {
  results: {
    stocks: CommodityStockResult[];
    averagePrices: CommodityAveragePriceResult[];
  };
}

interface CommoditiesForContractRequestProps {
  contractNumber: string;
  customerNumber?: number;
}

// Fetch commodities (Warenbestände) for one contract of the user
export const commoditiesForContractRequest = ({
  contractNumber,
}: CommoditiesForContractRequestProps): Promise<Commodity[]> =>
  AXI.get<CommoditiesRequestResult>(
    `/customers/contracts/${contractNumber}/commodities/`,
  ).then((response) =>
    response.data.results.stocks.map((stock) => {
      const priceInfo = response.data.results.averagePrices.find(
        (item) => !!(item.name === stock.name && item.unit === stock.unit),
      );

      return {
        ...stock,
        // We find the average price for a match of commodity name (e.g. AU)
        // and unit (e.g. g)
        // For one contract we only have one currency so we can just take
        averageBuyingPrice: priceInfo?.avgPrice,
        currency: priceInfo?.currency,
      };
    }),
  );

interface CommodityBookingForContractRequestProps {
  contractNumber: string;
  contractCommodity: string;
  contractCommodityUnit: string;
  pageParam: string | null | undefined;
}

// Fetch commodity transactions for one contract and one stock of the user
export const commodityBookingsForContractRequest = async ({
  contractCommodity,
  contractCommodityUnit,
  contractNumber,
  pageParam,
}: CommodityBookingForContractRequestProps) => {
  const payload = {
    count: 5,
    cursor: pageParam,
    unit: contractCommodityUnit,
  };

  const url = `/customers/contracts/${contractNumber}/commodities/${contractCommodity}/`;

  const { data: paginatedStockBookings } = await AXI.get<
    CursorPaginatedResponse<ContractBooking>
  >(url, { params: { ...payload } });

  return paginatedStockBookings;
};

interface CommoditiesRequestProps {
  customerNumber?: number | string;
  distributorId?: number;
}

// Fetch all commodities (Warenbestände) of the user (without price info for dashboard)
export const commoditiesRequest = ({
  customerNumber,
  distributorId,
}: CommoditiesRequestProps): Promise<Commodity[]> => {
  const isCustomerPage = customerNumber ? "customers" : "distributors";

  return AXI.get<CommoditiesRequestResult>(
    `/${isCustomerPage}/${customerNumber || distributorId}/commodities/`,
  ).then((response) =>
    response.data.results.stocks.map((stock) => {
      const priceInfo = response.data.results.averagePrices.find(
        (item) => !!(item.name === stock.name && item.unit === stock.unit),
      );

      return {
        ...stock,
        // We find the average price for a match of commodity name (e.g. AU)
        // and unit (e.g. g)
        // For one contract we only have one currency so we can just take
        averageBuyingPrice: priceInfo?.avgPrice,
        currency: priceInfo?.currency,
      };
    }),
  );
};

interface DistributorCommoditiesRequestProps {
  contractNumber?: number | string;
  customerNumber?: number | string;
  distributorId?: number;
  includeNested: boolean;
}

// Fetch all commodities (Warenbestände) as distributor. Can be filtered to retrieve commodities for one customer or one contract
export const distributorCommoditiesRequest = ({
  contractNumber,
  customerNumber,
  includeNested, // True if distributor is distribution company
  distributorId,
}: DistributorCommoditiesRequestProps): Promise<Commodity[]> =>
  AXI.get<CommoditiesRequestResult>(
    `/distributors/${distributorId}/commodities/`,
    {
      params: {
        contractNumber,
        customerNumber,
        includeNested,
      },
    },
  ).then((response) =>
    response.data.results.stocks.map((stock) => {
      const priceInfo = response.data.results.averagePrices.find(
        (item) => !!(item.name === stock.name && item.unit === stock.unit),
      );

      return {
        ...stock,
        currency: priceInfo?.currency,
      };
    }),
  );

// Fetch all partners of the distributor user that are connected to his distributorProfile
export const partnersForDistributorRequest = ({
  currency,
  distributorId,
  order,
  page,
  searchValue,
}: {
  currency: string;
  distributorId?: number;
  order?: TableOrder;
  page?: number;
  searchValue?: string;
}): Promise<NumberPaginatedResponse<DistributorPartner>> =>
  AXI.get(`/distributors/${distributorId}/partners/`, {
    params: {
      currency,
      ordering: order?.orderBy
        ? `${order.orderDirection === "desc" ? "-" : ""}${order.orderBy}`
        : undefined,
      page: (page || 0) + 1,
      search: searchValue || undefined,
    },
  }).then((response) => ({
    results: response.data.results,
    totalCount: response.data.count,
  }));

export const partnerDetailsForDistributorRequest = ({
  distributorId,
  partnerId,
}: {
  distributorId?: number;
  partnerId?: number;
}): Promise<DistributorPartner> =>
  AXI.get(`/distributors/${distributorId}/partners/${partnerId}/`).then(
    (response) => response.data,
  );

export const partnerKeyFiguresForDistributorRequest = ({
  distributorId,
  partnerId,
  period,
}: {
  distributorId?: number;
  partnerId?: number;
  period?: string;
}): Promise<DistributorPartnerKeyFigures> =>
  AXI.get(`/distributors/${distributorId}/partners/${partnerId}/keyFigures/`, {
    params: {
      period,
    },
  }).then((response) => response.data);

export const distributionNumbersForProfileRequest = ({
  currency,
  cursor,
  distributorId,
}: {
  currency: string;
  cursor?: string;
  distributorId: number;
}): Promise<CursorPaginatedResponse<DistributionNumberWithProfile>> =>
  AXI.get(`/distributors/${distributorId}/distributionNumberDetails/`, {
    params: { count: 50, currency, cursor },
  }).then((response) => response.data);

export const distributionNumbersChildrenRequest = ({
  currency,
  cursor,
  distributionNumber,
}: {
  currency: string;
  cursor?: string;
  distributionNumber: string;
}): Promise<CursorPaginatedResponse<DistributionNumberWithProfile>> => {
  const data = AXI.get(
    `/distributors/distributionNumbers/${distributionNumber}/`,
    { params: { count: 50, currency, cursor } },
  ).then((response) => response.data);

  return data;
};

export const distributorKeyFiguresRequest = ({
  currency,
  distributorId,
  period,
}: {
  currency: string;
  distributorId: number | undefined;
  period: string;
}): Promise<DistributorKeyFigures> =>
  AXI.get(`/distributors/${distributorId}/keyFigures/`, {
    params: { currency, period },
  }).then((response) => response.data);

export type TopTenOrdering = "sumContractFees" | "sumOrderTotals";

export const distributorTopTenRequest = ({
  currency,
  distributorId,
  ordering,
  period,
}: {
  currency: string;
  distributorId: number | undefined;
  ordering: TopTenOrdering;
  period: string;
}) =>
  AXI.get<CursorPaginatedResponse<DistributorTopTen>>(
    `/distributors/${distributorId}/partners/topten/`,
    {
      params: { currency, ordering, period },
    },
  ).then((response) => response.data.results);

export const distributorProvisionRequest = ({
  currency,
  distributorId,
}: {
  currency: string;
  distributorId: number | undefined;
}) =>
  AXI.get<DistributorProvision>(`/distributors/${distributorId}/provisions/`, {
    params: { currency },
  }).then((response) => response.data);

export const distributorDownloadsRequest = () =>
  AXI.get<DistributorDownloadItems>("/files/distributor-downloads/").then(
    (response) => response.data,
  );

export const distributorCurrenciesRequest = (
  distributorId: number | undefined,
) =>
  AXI.get<string[]>(`/distributors/${distributorId}/currencies/`).then(
    (response) => response.data,
  );

export const stockPurchasePriceRequest = () =>
  AXI.get<StockPrice[]>("/purchase-rule-prices/").then(
    (response) => response.data,
  );
