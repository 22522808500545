/* eslint-disable sort-keys-fix/sort-keys-fix */
import type { FC } from "react";
import Box from "@mui/material/Box";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

interface LinearProgressWithLabelProps extends LinearProgressProps {}

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 8,
  backgroundColor: theme.palette.secondary.main,
  "& .MuiLinearProgress-bar": {
    borderRadius: 10,
    backgroundColor: theme.palette.textColorDark,
  },
}));

const LinearProgressWithLabel: FC<LinearProgressWithLabelProps> = (props) => (
  <Box sx={{ display: "flex", alignItems: "center", width: "100%" }} {...props}>
    {props.value === null && "-"}

    {props.value !== null && (
      <>
        <StyledLinearProgress
          variant="determinate"
          {...props}
          sx={{ minWidth: 35, mr: 2 }}
        />

        <Box>
          <Typography component="span">{`${props.value}%`}</Typography>
        </Box>
      </>
    )}
  </Box>
);
export default LinearProgressWithLabel;
