import type { FC } from "react";
import InfoIcon from "@mui/icons-material/Info";
import type { BoxProps } from "@mui/material";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";

interface InfoTooltipProps extends BoxProps {
  infoText: string;
}

export const InfoTooltip: FC<InfoTooltipProps> = ({ infoText, ...props }) => {
  const theme = useTheme();

  return (
    <Box component="span" {...props}>
      <Tooltip title={<Typography variant="body1">{infoText}</Typography>}>
        <IconButton sx={{ marginLeft: 1, p: 0 }}>
          <InfoIcon
            fontSize="small"
            sx={{ color: theme.palette.neutral[200] }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default InfoTooltip;
