import type { FC } from "react";
import { Box, Card, CardProps, Typography, useTheme } from "@mui/material";

import { formatCurrencyNumber } from "src/utils/formatTexts";

import KeyFiguresCardSkeleton from "./KeyFiguresCard.skeleton";

import { useStyles } from "./KeyFiguresCard.styles";

interface KeyFiguresCardProps extends CardProps {
  value?: number;
  currencyCode?: string;
  title: string;
  isLoading: boolean;
}

export const KeyFiguresCard: FC<KeyFiguresCardProps> = ({
  value,
  currencyCode,
  title,
  isLoading,
  ...props
}) => {
  const { classes } = useStyles();
  const theme = useTheme();

  if (isLoading) {
    return <KeyFiguresCardSkeleton />;
  }

  return (
    <Card sx={{ padding: theme.spacing(2) }} {...props}>
      <Box display="flex" flexDirection="row">
        <Box className={classes.top}>
          <Box>
            <Typography mb={1} variant="h3" color="primary">
              {title}
            </Typography>
          </Box>

          <Box>
            <Typography
              variant="h2"
              component="p"
              color="textColorDark"
              className={classes.value}
            >
              {formatCurrencyNumber(value, currencyCode)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
